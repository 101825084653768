import { Main } from 'Main';
import { Provider } from 'react-redux';
import store from 'core/store';
import AuthProvider from 'core/auth/AuthProvider';
import userManager from 'core/auth/userService';
import ErrorBoundary from 'core/ErrorBoundary';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'api/client';
import EmotionTheme from 'core/Theming/EmotionTheme';
import { IdleTimerWrapper } from 'core/IdleTimer';
import { MainContainer } from 'components/Layout';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ScrollContextProvider from 'core/ScrollContext';
import ScrollToTop from 'core/ScrollToTop';
import './App.css';
import { PageContextProvider } from 'core/PageData';
import { reactPlugin } from 'core/logger/ApplicationInsightsService';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

const App = () => {
  return (
    <EmotionTheme>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <AuthProvider userManager={userManager} store={store}>
            <IdleTimerWrapper>
              <AppInsightsContext.Provider value={reactPlugin}>
                <MainContainer>
                  <ErrorBoundary>
                    <ToastContainer />
                    <ScrollToTop />
                    <ScrollContextProvider>
                      <PageContextProvider>
                        <Main />
                      </PageContextProvider>
                    </ScrollContextProvider>
                  </ErrorBoundary>
                </MainContainer>
              </AppInsightsContext.Provider>
            </IdleTimerWrapper>
          </AuthProvider>
        </Provider>
      </QueryClientProvider>
    </EmotionTheme>
  );
};

export default App;
