import styled from '@emotion/styled';
import { Container, GridItem } from 'components/Layout';
import {
  Header,
  InputContainer,
  InputFirst,
  InputSecond,
  RequiredLabel,
  SaveButton,
  SaveIcon,
} from 'components/PageLayout';
import { FormikProps, getIn, useFormik } from 'formik';
import * as Yup from 'yup';
import { Text } from 'components/Typography';
import { TextArea } from 'components/TextArea';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleRounded';
import RemoveIcon from '@mui/icons-material/RemoveCircleRounded';
import { TextField } from 'components/TextField';
import { useTheme } from '@emotion/react';
import { GrayBackdrop, Loader } from 'components/Loader';
import PrimaryToast from 'components/PrimaryToast';
import { useState } from 'react';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { CustomAdminJobStatusUpdateModel } from './types';
import {
  AdminJobStatusUpdateResponse,
  AdminJobUpdateStatus,
} from 'api/resources/models/AutoGenerated';
import { useJobStatusUpdate } from 'pages/hooks';
import { formErrorMessages } from 'api/resources/responseMessages/formErrors';

const validation = Yup.object({
  statusUpdate: Yup.array()
    .of(
      Yup.object().shape({
        activeWebID: Yup.string()
          .nullable()
          .required(formErrorMessages.required),
        comment: Yup.string().nullable().required(formErrorMessages.required),
        ticketNumber: Yup.string()
          .nullable()
          .required(formErrorMessages.required),
      })
    )
    .min(1)
    .max(10),
});

export const ForceJobStatusUpdateDetails = () => {
  const theme = useTheme();
  const { isUpdating, jobStatus } = useJobStatusUpdate();
  const [response, setResponse] = useState<
    AdminJobStatusUpdateResponse[] | undefined
  >();
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  const isBusy = isUpdating;

  const {
    values: { statusUpdate },
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldError,
    setFieldValue,
    setFieldTouched,
  }: FormikProps<CustomAdminJobStatusUpdateModel> = useFormik<CustomAdminJobStatusUpdateModel>(
    {
      enableReinitialize: true,
      initialValues: {
        statusUpdate: [
          {
            activeWebID: '',
            status: AdminJobUpdateStatus['Cancelled'],
            comment: '',
            ticketNumber: '',
          },
        ],
      },
      validateOnMount: true,
      validationSchema: validation,
      onSubmit: (values) =>
        jobStatus(values.statusUpdate).then((data) => setResponse(data)),
    }
  );

  const addDuplicateStatus = () => {
    if (statusUpdate && statusUpdate?.length < 10)
      setFieldValue('statusUpdate', [
        ...statusUpdate,
        {
          activeWebID: '',
          status: AdminJobUpdateStatus['Cancelled'],
          comment: '',
        },
      ]);
    else
      setToastState({
        message: errorMessages.reportStatusUnableToAddDuplicateStatus,
        isOpen: true,
        severity: 'error',
      });
  };

  const removeStatus = (id: number) => {
    if (statusUpdate && statusUpdate?.length > 1) {
      const prevData = statusUpdate;
      prevData?.splice(id, 1);
      setFieldValue('statusUpdate', prevData);

      const prevErrors = getIn(errors, 'statusUpdate');
      if (prevErrors) {
        prevErrors?.splice(id, 1);
        setFieldError('statusUpdate', prevErrors);
      }

      const prevTouched = getIn(touched, 'statusUpdate');
      if (prevTouched) {
        prevTouched?.splice(id, 1);
        setFieldTouched('statusUpdate', prevTouched);
      }
    } else
      setToastState({
        message: errorMessages.reportStatusUnableToRemoveStatus,
        isOpen: true,
        severity: 'error',
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <Container direction="column">
        <GridItem>
          <SaveButton type="submit">
            <SaveIcon />
            Save Changes
          </SaveButton>
        </GridItem>
        {statusUpdate?.map((_e, i) => {
          const formikErrors = getIn(errors, `statusUpdate[${i}]`);
          const formikTouched = getIn(touched, `statusUpdate[${i}]`);
          const currentResponse = response?.find(
            (data) => data.activeWebID == statusUpdate?.[i]?.activeWebID
          );
          return (
            <CustomContainer direction="row" key={i}>
              <StyledContainer lg={6} md={8} sm={10} xs={12}>
                <Header>Enter Details of the job you wish to update</Header>
                <InputContainer direction="row">
                  <InputFirst md={6} sm={6} xs={12}>
                    <RequiredLabel>ActiveWeb ID</RequiredLabel>
                    <CustomTextField
                      name={`statusUpdate.${i}.activeWebID`}
                      value={statusUpdate?.[i]?.activeWebID}
                      onChange={handleChange}
                    />
                    {formikErrors?.activeWebID &&
                      formikTouched?.activeWebID && (
                        <Text color={theme.palette.primary.error}>
                          {formikErrors?.activeWebID}
                        </Text>
                      )}
                  </InputFirst>
                  <InputSecond md={6} sm={6} xs={12}>
                    <RequiredLabel>Ticket Number</RequiredLabel>
                    <CustomTextField
                      name={`statusUpdate.${i}.ticketNumber`}
                      value={statusUpdate?.[i]?.ticketNumber}
                      onChange={handleChange}
                    />
                    {formikErrors?.ticketNumber &&
                      formikTouched?.ticketNumber && (
                        <Text color={theme.palette.primary.error}>
                          {formikErrors?.ticketNumber}
                        </Text>
                      )}
                  </InputSecond>
                </InputContainer>
                <InputContainer direction="column">
                  <RequiredLabel>Comment</RequiredLabel>
                  <TextArea
                    name={`statusUpdate.${i}.comment`}
                    value={statusUpdate?.[i]?.comment || ''}
                    onChange={handleChange}
                  />
                  {formikErrors?.comment && formikTouched?.comment && (
                    <Text color={theme.palette.primary.error}>
                      {formikErrors?.comment}
                    </Text>
                  )}
                </InputContainer>
                {currentResponse ? (
                  currentResponse.isUpdated ? (
                    <Text fontSize="m" color={theme.palette.messages.success}>
                      Updated Successfully
                    </Text>
                  ) : (
                    <Text fontSize="m" color={theme.palette.messages.error}>
                      Updating failed
                    </Text>
                  )
                ) : null}
              </StyledContainer>
              <Container lg={6} md={4} sm={2} xs={12} alignItems="center">
                <CustomGrid>
                  <IconButton onClick={addDuplicateStatus}>
                    <AddIcon fontSize="large" color="primary" />
                  </IconButton>
                  <IconButton onClick={() => removeStatus(i)}>
                    <RemoveIcon fontSize="large" color="primary" />
                  </IconButton>
                </CustomGrid>
              </Container>
            </CustomContainer>
          );
        })}
      </Container>
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
    </form>
  );
};

const CustomContainer = styled(Container)`
  margin: 10px 0;
`;

const StyledContainer = styled(Container)`
  border: 1px solid black;
  padding: 20px;
  border-radius: 10px;
`;

const CustomGrid = styled(GridItem)`
  padding-left: 20px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 20px 0;
  }
`;

const CustomTextField = styled(TextField)`
  width: 100%;
`;
