import styled from '@emotion/styled';
import {
  MainPageContainer,
  pagePaddingLeft,
  UpperInfoContainer,
} from 'components/PageLayout';
import { BackButton } from 'components/Button';
import { Heading } from 'components/Typography';
import { Container, GridItem } from 'components/Layout';
import { ForceWPNameUpdateDetails } from './WPChange';
import { ForceJobStatusUpdateDetails } from './JobStatus';

export function ForceUpdateWPChange() {
  return <UpdateDetails />;
}

export function ForceUpdateJobStatus() {
  return <UpdateDetails isJobStatus />;
}

interface Props {
  isJobStatus?: boolean;
}

export function UpdateDetails({ isJobStatus }: Props) {
  return (
    <MainPageContainer direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <BackButton text={`Back to Repair Updates`} />
        <Heading>
          {isJobStatus
            ? 'Force Update Job Status'
            : 'Force Update Work Provider Name'}
        </Heading>
      </UpperInfoContainer>

      <CustomContainer direction="column">
        <Container direction="row">
          <GridItem md={12} sm={12} xs={12}>
            {isJobStatus ? (
              <ForceJobStatusUpdateDetails />
            ) : (
              <ForceWPNameUpdateDetails />
            )}
          </GridItem>
        </Container>
      </CustomContainer>
    </MainPageContainer>
  );
}

const CustomContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.margin.l};
  background: ${({ theme }) => theme.palette.background.default};
  padding-left: ${({ theme }) => pagePaddingLeft(theme)};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;
