export const MaintenanceMessagesMock = [
  {
    maintenanceMessageId: 0,
    messageType: 'Scheduled',
    ticketNumber: 123123,
    validFrom: 'Mon Dec 12 2018 14:32:45 GMT+0530 (India Standard Time)',
    validTo: 'Mon Dec 12 2022 14:32:45 GMT+0530 (India Standard Time)',
    message: 'Show Performance',
  },
  {
    maintenanceMessageId: 1,
    messageType: 'Scheduled',
    ticketNumber: 123123,
    validFrom: 'Mon Dec 12 2018 14:32:45 GMT+0530 (India Standard Time)',
    validTo: 'Mon Dec 12 2022 14:32:45 GMT+0530 (India Standard Time)',
    message: 'Scheduled Maintenance',
  },
  {
    maintenanceMessageId: 2,
    messageType: 'Scheduled',
    ticketNumber: 123123,
    validFrom: 'Mon Dec 12 2018 14:32:45 GMT+0530 (India Standard Time)',
    validTo: 'Mon Dec 12 2022 14:32:45 GMT+0530 (India Standard Time)',
    message: 'System Reboot',
  },
];

export const repairerInfo = {
  quickDetails: {
    repairerId: 694,
    siteCode: 'ALT_BA',
    repairerGroupName: 'INDEPENDENT',
    systemType: 'ADVANCE7',
  },
  details: {
    address: 'Testing address',
    email: 'test@test.com',
    repairerGroup: 'test group',
    name: 'test name',
    postcode: 10001,
    telephone: 1234567890,
    website: 'https://www.test.com',
    systemType: 'Test Type',
    siteCode: 'Test Code',
    vatRegistrationNo: 121212,
  },
  status: {
    status: 'live',
    updateCounts: [
      { date: '2022-01-01T00:00:00', count: 0 },
      { date: '2022-01-02T00:00:00', count: 90 },
      { date: '2022-01-03T00:00:00', count: 0 },
      { date: '2022-01-04T00:00:00', count: 5 },
      { date: '2022-01-05T00:00:00', count: 0 },
      { date: '2022-01-06T00:00:00', count: 35 },
      { date: '2022-01-07T00:00:00', count: 0 },
      { date: '2022-01-08T00:00:00', count: 35 },
      { date: '2022-02-01T00:00:00', count: 30 },
      { date: '2022-02-02T00:00:00', count: 10 },
      { date: '2022-02-03T00:00:00', count: 50 },
      { date: '2022-02-04T00:00:00', count: 100 },
    ],
  },
  externalCodes: [
    {
      externalSystem: 'FIX Cloud',
      externalCode: 'FXC',
      dtgCreated: '2022-06-09T00:00:00',
      enabled: true,
    },
    {
      externalSystem: 'LV OWN',
      externalCode: 'LVO',
      dtgCreated: '2022-06-22T00:00:00',
      enabled: false,
    },
  ],
  history: [
    {
      wpName: 'Work Provider Name 1',
      username: 'username 1',
      description: 'description 1',
      connectionStatus: 'Connection Added',
      dtgUpdated: '2022-06-22T13:50:00',
      ticketNumber: 1212123,
    },
    {
      wpName: 'Work Provider Name 2',
      username: 'username 2',
      description: 'description 2',
      connectionStatus: 'Repairer Locked',
      dtgUpdated: '2022-06-30T22:50:00',
      ticketNumber: 2121213,
    },
    {
      wpName: 'Work Provider Name 2',
      username: 'username 2',
      description: 'description 2',
      connectionStatus: 'Repairer Updated',
      dtgUpdated: '2022-06-30T22:50:00',
      ticketNumber: 2121213,
    },
  ],
  connections: [
    {
      workProvider: 'Work Provider 1',
      userPermits: 12,
      fnol: 10,
      b2bSettings: 4,
      sms: 6,
    },
    {
      workProvider: 'Work Provider 2',
      userPermits: 488,
      fnol: 5,
      b2bSettings: 3,
      sms: 0,
    },
  ],
};

export const WorkProviders = [
  {
    workProviderID: 1,
    workProviderName: 'Allianz',
    requireExternalCode: true,
  },
  {
    workProviderID: 61,
    workProviderName: 'Covea',
    requireExternalCode: false,
  },
  {
    workProviderID: 67,
    workProviderName: 'DLG2',
    requireExternalCode: true,
  },
  {
    workProviderID: 178,
    workProviderName: 'Caps_IMS',
    requireExternalCode: true,
  },
  {
    workProviderID: 74,
    workProviderName: 'LV',
    requireExternalCode: true,
  },
  {
    workProviderID: 69,
    workProviderName: 'RSA',
    requireExternalCode: true,
  },
  {
    workProviderID: 156,
    workProviderName: 'Kindertons',
    requireExternalCode: true,
  },
  {
    workProviderID: 193,
    workProviderName: 'FixCloud',
    requireExternalCode: true,
  },
  {
    workProviderID: 9,
    workProviderName: 'CircleLeasing',
    requireExternalCode: true,
  },
  {
    workProviderID: 10,
    workProviderName: 'ITAS_V2',
    requireExternalCode: true,
  },
  {
    workProviderID: 292,
    workProviderName: 'Caps_Enterprise_ITB',
    requireExternalCode: true,
  },
  {
    workProviderID: 150,
    workProviderName: 'ExternalSourceConfiguration',
    requireExternalCode: true,
  },
];
