export const selectOptionsConnectionType = [
    {
      id: 9,
      value: 9,
      text: 'CAPS',
    },
    {
      id: 5,
      value: 5,
      text: 'Advance',
    },
    {
      id: 6,
      value: 6,
      text: 'Voyager',
    },
    {
      id: 13,
      value: 13,
      text: 'Vizion',
    },
  ];
  