import { Permit, SortOrder } from 'api/resources/models/User';
import { DataGrid, getSortObject } from 'components/DataGrid';
import InfoText from 'components/InfoText';
import {
  MainPageContainer,
  UpperInfoContainer,
  StyledGridItem,
} from 'components/PageLayout';
import { SortObject } from 'components/types';
import { Heading, Text } from 'components/Typography';
import { createMaintenanceMessagesURL, ROUTES } from 'core/routes';
import { orderBy } from 'lodash';
import {
  useMaintenanceMessages,
  useRemoveMaintenanceMessage,
  useUser,
} from 'pages/hooks';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { dateTimeToString, formatDateTime, stringToDate } from 'utils/helpers';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import MobileBottomButton, {
  CreateButton,
  PrimaryButton,
} from 'components/Button';
import { Search } from '@mui/icons-material';
import { Container, GridItem } from 'components/Layout';
import { DatePickerField } from 'components/DatePickerFields';
import { DesktopContainer, useInMobile } from 'core/Theming/Device';
import {
  convertToDeleteButton,
  MaintenanceMessagesWithDelete,
  maintenanceMessageTableHeader,
  useMaintenanceMessagesParams,
} from './types';
import PrimaryToast from 'components/PrimaryToast';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/failure';
import DeleteIcon from '@mui/icons-material/Delete';
import { successMessages } from 'api/resources/responseMessages/success';
import dayjs from 'dayjs';
import { Checkbox } from 'components/Checkbox';
import { useRowCountPerPage } from 'components/Pagination/hooks';

const CREATE_MAINTENANCE_MESSAGE_PERMISSION =
  Permit.CA_MAINTENANCE_MESSAGES_MANAGE;

export function MaintainanceMessages() {
  const isMobile = useInMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = useMaintenanceMessagesParams(location.search);

  const { user, isLoading, isFetching } = useUser();
  const { isMaintenanceMessagesLoading, maintenanceMessages, refetch } =
    useMaintenanceMessages(
      urlParams.startDate,
      urlParams.endDate,
      urlParams.includeDeleted,
      handleError
    );

  const { isRemoving, removeMaintenanceMessage } =
    useRemoveMaintenanceMessage();
  const maintenanceMessagesWithDelete = maintenanceMessages?.map((data) =>
    convertToDeleteButton(data)
  );
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  const current_date = new Date();
  const currentMonthDate = new Date(
    current_date.getFullYear(),
    current_date.getMonth() == 10
      ? 0
      : current_date.getMonth() == 11
      ? 1
      : current_date.getMonth() + 2,
    0
  );
  const previousMonthDate = useMemo(() => {
    const previousMonth =
      current_date.getMonth() == 0 ? 12 : current_date.getMonth();
    const previousYear =
      current_date.getMonth() == 0
        ? current_date.getFullYear() - 1
        : current_date.getFullYear();
    const previousMonthLastDate = new Date(previousYear, previousMonth, 1);
    return new Date(
      `${previousMonth}-${previousMonthLastDate.getDate()}-${previousYear}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CREATEMAINTENANCEMESSAGEPERMISSION = useMemo(
    () =>
      user?.userPermits?.find(
        (permit) => permit.code == CREATE_MAINTENANCE_MESSAGE_PERMISSION
      ),
    [user]
  );

  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });
  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sortString ?? 'maintenanceMessageId',
    sortOrder: urlParams.sortOrder ?? SortOrder.Descending,
  });
  const [sortedMaintenanceMessages, setSortedMaintenanceMessages] = useState(
    maintenanceMessagesWithDelete
  );
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    setSortedMaintenanceMessages(sortMessagesQueue(sortObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceMessagesWithDelete, currentPage, itemsPerPage]);

  useEffect(() => {
    navigateToMaintenanceMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject]);

  const {
    values: { startDate, endDate, includeDeleted },
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      startDate: stringToDate(urlParams.startDate) || previousMonthDate,
      endDate: stringToDate(urlParams.endDate) || currentMonthDate,
      includeDeleted: Boolean(urlParams.includeDeleted == 'true'),
    },
    onSubmit: () => {
      navigateToMaintenanceMessage();
    },
  });

  const isEnabled = !!startDate && !!endDate;
  const isBusy =
    isLoading || isFetching || isMaintenanceMessagesLoading || isRemoving;
  const customEndTime = dayjs(endDate)
    .add(23, 'hour')
    .add(59, 'minute')
    .toDate();

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <StyledGridItem>
          <Heading>Maintenance Messages</Heading>
        </StyledGridItem>
        <form onSubmit={handleSubmit}>
          <CustomContainer direction="row">
            <InputButton md={3} sm={6} xs={12}>
              <TextItem>
                <Text fontSize="s">Start Date</Text>
              </TextItem>
              <DatePickerField
                value={startDate}
                maxDate={endDate}
                onChange={(date) => setFieldValue('startDate', date)}
              />
            </InputButton>
            <InputButton md={3} sm={6} xs={12}>
              <TextItem>
                <Text fontSize="s">End Date</Text>
              </TextItem>
              <DatePickerField
                name="endDate"
                value={endDate}
                minDate={startDate}
                onChange={(date) => setFieldValue('endDate', date)}
              />
            </InputButton>
            <GridItem md={2} sm={6} xs={12}>
              <CheckBoxButton>
                <Checkbox
                  checked={includeDeleted}
                  onChange={(e) =>
                    setFieldValue('includeDeleted', Boolean(e.target.checked))
                  }
                  label="Include Deleted Messages"
                  color="primary"
                />
              </CheckBoxButton>
            </GridItem>
            <GridItem md={1} sm={1} xs={2}>
              <Button type="submit" disabled={!isEnabled}>
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
            <DesktopContainer>
              <GridItem md={3}>
                {CREATEMAINTENANCEMESSAGEPERMISSION && renderCreateButton()}
              </GridItem>
            </DesktopContainer>
          </CustomContainer>
        </form>
      </UpperInfoContainer>
      {!isBusy && maintenanceMessages?.length === 0 && (
        <InfoText>{errorMessages.noMessagesFound}</InfoText>
      )}
      <DataGrid
        onSort={sort}
        isLoading={isBusy}
        sortObject={sortObject}
        onRowClick={handleRowClick}
        data={sortedMaintenanceMessages}
        customCellRender={renderCustomCell}
        headers={maintenanceMessageTableHeader}
        currentPage={currentPage}
        totalCount={maintenanceMessages?.length}
        onPageChange={(page) => setCurrentPage(page)}
        onItemsPerPageChange={setItemsPerPage}
      />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
      <MobileBottomButton>{renderCreateButton()}</MobileBottomButton>
    </MainPageContainer>
  );

  function renderCreateButton() {
    return (
      <CreateButton
        text="Create Maintenance Messages"
        route={ROUTES.createMaintainanceMessages}
      />
    );
  }

  function handleRowClick(row: MaintenanceMessagesWithDelete) {
    const route = generatePath(ROUTES.updateMaintenanceMessage, {
      messageId: row.maintenanceMessageId,
    });
    navigate(route);
  }

  function renderCustomCell({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof MaintenanceMessagesWithDelete;
    row: MaintenanceMessagesWithDelete;
  }) {
    if (headerId == 'delete' && row.delete)
      return CREATEMAINTENANCEMESSAGEPERMISSION ? (
        <CustomRemoveButton
          type="button"
          color="primary"
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            removeMaintenanceMessage({
              messageId: row.maintenanceMessageId,
              startDate: urlParams.startDate,
              endDate: urlParams.endDate,
              includeDeleted: urlParams.includeDeleted,
            })
              .then(() => {
                setToastState({
                  message: successMessages.deleteMaintenanceMessage,
                  isOpen: true,
                  severity: 'success',
                });
              })
              .catch((error: AxiosError) => handleError(error, true));
          }}
        >
          <DeleteIcon />
        </CustomRemoveButton>
      ) : (
        <></>
      );
    else if (headerId == 'startDTG' && row.startDTG)
      return <>{formatDateTime(row.startDTG)}</>;
    else if (headerId == 'endDTG' && row.endDTG)
      return <>{formatDateTime(row.endDTG)}</>;
    else return baseRender();
  }

  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'Search';
    return null;
  }

  function sortMessagesQueue(sortObj: SortObject) {
    const currentListed = currentPage * itemsPerPage;
    const orderedList = orderBy(
      maintenanceMessagesWithDelete,
      sortObj.sortString,
      sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
    );
    return orderedList?.slice(currentListed, currentListed + itemsPerPage);
  }

  function sort(sortValue: keyof MaintenanceMessagesWithDelete) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedMaintenanceMessages(sortMessagesQueue(sortObj));
      return sortObj;
    });
  }

  function navigateToMaintenanceMessage() {
    if (!!startDate && !!endDate)
      navigate(
        createMaintenanceMessagesURL(
          sortObject.sortString ?? undefined,
          sortObject.sortOrder,
          dateTimeToString(startDate),
          dateTimeToString(customEndTime),
          includeDeleted?.toString()
        ),
        { replace: true }
      );
    if (
      urlParams.startDate &&
      urlParams.endDate &&
      urlParams.startDate == dateTimeToString(startDate) &&
      urlParams.endDate == dateTimeToString(customEndTime)
    )
      refetch();
  }

  function handleError(_error: AxiosError, isRemove?: boolean) {
    setToastState({
      message: isRemove
        ? errorMessages.deleteMaintenanceMessages
        : errorMessages.maintenanceMessages,
      isOpen: true,
      severity: 'error',
    });
  }
}

const CheckBoxButton = styled('div')`
  margin-top: 20px;
`;

const CustomContainer = styled(Container)`
  display: flex;
  align-items: flex-end;
  margin-top: 12px;
`;

const InputButton = styled(GridItem)`
    padding-right: ${({ theme }) => theme.margin.s};
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        margin - bottom: 6px;
    }
    & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
    }
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const TextItem = styled(GridItem)`
  padding-bottom: 12px;
`;

const CustomRemoveButton = styled(PrimaryButton)`
  padding: 0;
  height: 40px;
  min-width: 40px;
`;
