import { errorMessages } from 'api/resources/responseMessages/failure';
import { successMessages } from 'api/resources/responseMessages/success';
import { AxiosError } from 'axios';
import PrimaryToast from 'components/PrimaryToast';
import { useUpdateMaintenanceMessages } from 'pages/hooks';
import React, { useState } from 'react';
import { DetailsMaintainanceMessages } from './detailsMaintenanceMessages';
import { convertToAPIData, CustomMaintenanceMessages } from './form';

interface Props {
  maintenanceMessage: CustomMaintenanceMessages;
}

export default function EditMaintenanceMessages({ maintenanceMessage }: Props) {
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  const { updateMaintenanceMessage, isUpdating } =
    useUpdateMaintenanceMessages();

  const isBusy = isUpdating;

  return (
    <React.Fragment>
      <DetailsMaintainanceMessages
        maintenanceMessage={maintenanceMessage}
        onSubmit={onSubmit}
        isBusy={isBusy}
      />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
    </React.Fragment>
  );

  function onSubmit(data: CustomMaintenanceMessages) {
    updateMaintenanceMessage(
      convertToAPIData({ ...maintenanceMessage, ...data })
    )
      .then(() => {
        setToastState({
          message: successMessages.editMaintenanceMessage,
          isOpen: true,
          severity: 'success',
        });
      })
      .catch((error: AxiosError) => handleError(error, false));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(error: AxiosError, isDeleted: boolean) {
    setToastState({
      message: isDeleted
        ? errorMessages.deleteMaintenanceMessages
        : errorMessages.updateMaintenanceMessages,
      isOpen: true,
      severity: 'error',
    });
  }
}
