import { silentSigninCallback } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function SilentSigninOidc() {
  const navigate = useNavigate();

  useEffect(() => {
    async function silentSignInAsync() {
      await silentSigninCallback();
      navigate(ROUTES.redirectHandler);
    }
    silentSignInAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
