import { useState, ChangeEvent } from 'react';
import { Modal } from 'components/Modal';
import { GridItem } from 'components/Layout';
import styled from '@emotion/styled';
import { UserProfile } from 'api/resources/models/User';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { getUserCashKey } from 'pages/hooks';
import { TextField } from '@mui/material';
import { switchUserProfile } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import {
  MainModalContainer,
  ModalButton,
  ModalInputContainer,
} from 'components/ModalLayout';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onProfileSwitch: (selectedProfile: UserProfile) => void;
  profiles: UserProfile[];
}
export function ProfileModal({
  isOpen,
  onClose,
  onProfileSwitch,
  profiles,
}: Props) {
  const [profileId, setProfileId] = useState<number>();
  const [showError, setShowError] = useState<boolean>(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <>
      {profiles ? (
        <Modal isOpen={isOpen} title="Switch Profile" onClose={onClose}>
          <MainModalContainer direction="column">
            <ModalInputContainer>
              <Form variant="outlined">
                <StyledTextField
                  select
                  error={showError}
                  size="small"
                  label="Profile"
                  variant="outlined"
                  id="simple-select-outlined"
                  value={profileId}
                  onChange={onSelectChange}
                >
                  {profiles.map((profile, i) => (
                    <SelectItem key={i} value={profile.userAccountProfileId}>
                      {profile.name}
                    </SelectItem>
                  ))}
                </StyledTextField>
              </Form>
            </ModalInputContainer>
            <GridItem>
              <ModalButton className="continue-button" onClick={onSwitchClick}>
                Switch
              </ModalButton>
            </GridItem>
          </MainModalContainer>
        </Modal>
      ) : null}
    </>
  );

  function onSelectChange(event: ChangeEvent<{ value: unknown }>) {
    if (typeof event.target.value === 'number') {
      setProfileId(event.target.value);
      setShowError(false);
    }
  }

  function onSwitchClick() {
    const selectedProfile = profiles.find(
      (p) => p.userAccountProfileId === profileId
    );
    if (!selectedProfile) {
      setShowError(true);
      return;
    }
    switchUserProfile(profileId);
    onProfileSwitch(selectedProfile);
    queryClient.invalidateQueries(getUserCashKey());
    navigate(ROUTES.redirectHandler);
  }
}

const StyledTextField = styled(TextField)`
  > label {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
  > div {
    font-size: ${({ theme }) => theme.fontSize.s};
    height: 40px;
  }
`;

const Form = styled(FormControl)`
  width: 100%;
  .MuiSelect-select:focus {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
`;

const SelectItem = styled(MenuItem)`
  font-size: ${({ theme }) => theme.fontSize.s};
`;
