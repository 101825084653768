import {
  AdminMaintenanceMessageModel,
  MaintenanceMessageType,
} from 'api/resources/models/AutoGenerated';
import { dateTimeToString, stringToDate } from 'utils/helpers';
import { selectOptions } from './types';
import { SelectOption } from 'components/Multiselect';

export const getMessageType = (value: string) => {
  if (value == 'Scheduled') return MaintenanceMessageType.Scheduled;
  else return MaintenanceMessageType.Completed;
};

export const convertToFormData = (
  data: AdminMaintenanceMessageModel
): CustomMaintenanceMessages => ({
  ...data,
  startDTG: data.startDTG ? stringToDate(data.startDTG) : null,
  endDTG: data.endDTG ? stringToDate(data.endDTG) : null,
  messageType: data.messageType
    ? selectOptions.find((e) => e.value == data.messageType?.toString())
    : null,
  companies:
    data.companies?.map((e) => ({
      id: e.companyId,
      text: e.companyName || '',
      value: e.companyName || '',
    })) || null,
});

export const convertToAPIData = (
  data: CustomMaintenanceMessages
): AdminMaintenanceMessageModel => ({
  ...data,
  startDTG: data.startDTG ? dateTimeToString(data.startDTG) : null,
  endDTG: data.endDTG ? dateTimeToString(data.endDTG) : null,
  messageType: data.messageType ? getMessageType(data.messageType.value) : null,
  companies:
    data.companies?.map((e) => ({
      companyId: e.id,
      companyName: e.text,
    })) || null,
});

export interface CustomMaintenanceMessages {
  maintenanceMessageId: number;
  startDTG: Date | null;
  endDTG: Date | null;
  message: string | null;
  ticketNumber: string | null;
  messageType?: {
    id: number;
    text: string;
    value: string;
  } | null;
  companies: SelectOption[] | null;
}
