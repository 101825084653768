import { signinRedirectCallback } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function SigninOidc() {
  const navigate = useNavigate();

  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback();
      navigate(ROUTES.redirectHandler);
    }
    signinAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
}
