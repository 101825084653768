/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { SvgIconComponent } from '@mui/icons-material';
import { UserPermit } from 'api/resources/models/User';
import { useState } from 'react';
import { HelpInformationModal } from '../HelpInformationModal';
import { mapMenuItems } from './helpers';
import { bottomMenuItems, MenuItem, Mode } from './types';
import { CustomNavLink } from './CustomNavLink';

interface Props {
  userPermits: UserPermit[];
  topMenuItems: MenuItem[];
}

export function DesktopMenu({ userPermits, topMenuItems }: Props) {
  return (
    <ListContainer>
      <div>
        {mapMenuItems(topMenuItems, userPermits).map((item, index) => {
          return (
            <ListItem
              className="container"
              css={ListItemStyles}
              component={CustomNavLink}
              to={item.route}
              button
              key={index}
              activeClassName="active-menu-item"
            >
              <div>
                <Icon>{renderIcon(item.icon)}</Icon>
                <IconText isActive={false}>{item.name}</IconText>
              </div>
            </ListItem>
          );
        })}
      </div>
      <div>
        <BottomMenuItems userPermits={userPermits} />
      </div>
    </ListContainer>
  );
}

function renderIcon(
  Icon:
    | SvgIconComponent
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
) {
  return <Icon />;
}

function BottomMenuItems({ userPermits }: { userPermits: UserPermit[] }) {
  const [showModal, setShowModal] = useState<Mode>(null);

  return (
    <>
      {bottomMenuItems.map((item, index) => {
        const permitAllowed = userPermits.some(
          (userPermit) => userPermit.code == item.permitCode
        );

        if (permitAllowed)
          if (item.route)
            return (
              <ListItem
                className="container"
                css={ListItemStyles}
                component={CustomNavLink}
                to={item.route}
                button
                key={index}
                activeClassName="active-menu-item"
              >
                <div>
                  <Icon>{renderIcon(item.icon)}</Icon>
                  <IconText isActive={false}>{item.name}</IconText>
                </div>
              </ListItem>
            );
          else
            return (
              <ListItem
                className="container"
                button
                key={index}
                css={ListItemStyles}
                onClick={() => setShowModal(item.name)}
              >
                <Container>
                  <Icon>{renderIcon(item.icon)}</Icon>
                  <IconText isActive={false}>{item.name}</IconText>
                </Container>
              </ListItem>
            );
      })}
      {showModal === 'Help' && (
        <HelpInformationModal isOpen onCloseClick={() => setShowModal(null)} />
      )}
    </>
  );
}

const ListItemStyles = (theme: Theme) => css`
  background-color: ${theme.palette.background.dark};

  &:hover {
    background-color: ${theme.palette.drawer.hoverColor};
  }
`;

const IconText = styled('p')<{ isActive: boolean }>`
  color: ${({ theme }) => theme.palette.menuIcons.default};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  margin-top: 0px;
  text-align: center;

  &.MuiListItemIcon-root {
    min-width: unset;
  }
`;

const Container = styled('div')`
  height: ${({ theme }) => theme.palette.drawer.height}px;
  overflow-wrap: break-word;
`;

const Icon = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.menuIcons.default};
  margin-top: ${({ theme }) => theme.margin.xs};
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);

  &.MuiListItemIcon-root {
    min-width: unset;
  }
`;

const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .active-menu-item {
    background-color: ${({ theme }) => theme.palette.drawer.hoverColor};
    ${IconText} {
      color: ${({ theme }) => theme.palette.menuIcons.active};
    }

    ${Icon} {
      color: ${({ theme }) => theme.palette.menuIcons.active};
      path {
        fill: ${({ theme }) => theme.palette.menuIcons.active};
      }
    }
  }

  .container {
    padding-left: ${({ theme }) => theme.padding.s};
    padding-right: ${({ theme }) => theme.padding.s};
    padding-top: ${({ theme }) => theme.padding.xs};
    padding-bottom: ${({ theme }) => theme.padding.xs};
    justify-content: center;
  }
`;
