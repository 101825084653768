export const guidenceTextDetails =
  'This will save changes to the repairer details. Please ensure any applicable changes are also communicated to the Billing Team to ensure their subscription accounts are aligned ';
export const guidenceTextLock =
  'Locking a repairer temporarily turns off Fnol,  and user data access. Any Fnols blocked whilst the repairer is locked will NOT be processed later when unlocking. Updates from Repairers will still be received but not visible in the system until unlocked';
export const guidenceTextUnlock =
  'Unlocking a repairer will make the repair data within the ActiveWeb portal visible to the Repairer and Work Provider(s). ';
export const guidenceTextTerminate =
  'Terminating a repairer will close all active connections and mark the repairer as suspended. If connected to DLG the connection will stay visible to DLG with the word (CLOSED) appended to the repairer name.';
export const guidanceTextConnections =
  'This will remove the connection between the repairer and the Work Provider. Please remember to terminate any associated CAPS entries. ';
export const guidenceTextReactivate =
  'Reactivating a repairer will make the repairer record live again. This action will not reinstate any historical connections. These must be reconnected in line with current authorisations.';
