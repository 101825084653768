import { api } from 'api';
import { config } from 'core/config';
import { ChangeLog } from './models/AutoGenerated';

const adminBaseUrl = '/api/changeLog';
const applicationId = 'ActivewebConnectionsAdminUI';
export const getChangelog = (): Promise<ChangeLog[]> =>
  api
    .get(`${config.connectionsAdminApiUrl}${adminBaseUrl}/changeLogs`, {
      params: { applicationId: applicationId },
    })
    .then((result) => result.data);
