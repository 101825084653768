import { api } from 'api';
import { config } from 'core/config';
import { HelpContext } from './models/AutoGenerated';
import { User } from './models/User';

const userBaseUrl = '/api/currentUser';

export const getUser = (): Promise<User> =>
  api.get(`${config.clientApiUrl}${userBaseUrl}`).then((result) => result.data);

export const getHelpContext = (): Promise<HelpContext> =>
  api
    .get(`${config.connectionsAdminApiUrl}${userBaseUrl}/help`)
    .then((response) => response.data);
