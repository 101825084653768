export const errorMessages = {
  archives: "Sorry, there was an error while fetching Archive.",
  fnol: 'Unable to fetch FNOLs, please try again!',
  fnolDetails: 'Unable to fetch FNOL Details, please try again!',
  reportJobStatusUpdate: 'Unable to update Job Status, please try again!',
  reportWPChangeUpdate:
    'Unable to update Work Provider Name, please try again!',
  reportStatusUnableToAddDuplicateStatus: 'You can add upto 10 entries',
  reportStatusUnableToRemoveStatus: 'You need to have atleast 1 entry',
  EditInvalidId: (title: string) => `${title} not found`,
  invalidId: (id: string) => `${id} must be a number`,
  maintenanceMessages:
    'Unable to fetch Maintenance Messages, please try again!',
  createMaintenanceMessages:
    'Unable to create Maintenance Message, please try again!',
  updateMaintenanceMessages:
    'Unable to update Maintenance Message, please try again!',
  deleteMaintenanceMessages:
    'Unable to delete Maintenance Message, please try again!',
  messageQueues: 'Unable to fetch Message Queues, please try again!',
  noMessagesFound:
    "Sorry, we couldn't find any messages with these search criteria.",
  noFnolRepairersFound: "Sorry, we couldn't find any repairs for this FNOL",
  noFnolSearchesFound:
    "Sorry, we couldn't find any FNOL with these search criteria.",
  noFnolsFound: "Sorry, we couldn't find any FNOLs with these search criteria",
  noFnolMessagesFound: "Sorry, we couldn't find any messages for this FNOL",
  repairers: 'Unable to fetch repairers, please try again!',
  noRepairersFound: (searchValue?: string | null) =>
    `No matching repairers found with the term ${searchValue} in fields repairer name, repair id, sitecode or repairer group name`,
  createRepairers: 'Unable to create Repairer, please try again!',
  updateRepairers: 'Unable to update Repairer, please try again!',
  removeConnection: 'Unable to remove Connection, please try again!',
  lockRepairers: 'Unable to lock Repairer, please try again!',
  unlockRepairers: 'Unable to unlock Repairer, please try again!',
  addConnection: 'Unable to add connection, please try again!',
  addFNOL: 'Unable to add FNOL, please try again!',
  terminatedRepairers: 'Unable to terminate Repairer, please try again!',
  reactivateRepairer: 'Unable to reactivate Repairer, please try again!',
  default:
    'Oops... Something went wrong. Please try again in a couple of minutes',
};
