import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { CheckCircle, Error, Lock } from '@mui/icons-material';
import { AdminRepairerUpdateCounts } from 'api/resources/models/AutoGenerated';
import { Container } from 'components/Layout';
import { Text } from 'components/Typography';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { formatDateMonth, formatXAxis } from './types';

export const Status = ({
  updateCounts,
  repairerStatus,
}: {
  updateCounts: AdminRepairerUpdateCounts[];
  repairerStatus?: string | null;
}) => {
  const theme = useTheme();
  const chartData = formatDateMonth(updateCounts);

  return (
    <Container direction="column" alignItems="flex-start">
      <ChipContainer
        status={
          repairerStatus == 'Live'
            ? 'live'
            : repairerStatus == 'Terminated'
            ? 'terminated'
            : 'locked'
        }
      >
        {repairerStatus == 'Live' ? (
          <CheckCircle fontSize="small" />
        ) : repairerStatus == 'Terminated' ? (
          <Error fontSize="small" />
        ) : repairerStatus == 'Locked' ? (
          <Lock fontSize="small" />
        ) : null}
        <CustomText>{repairerStatus}</CustomText>
      </ChipContainer>

      <ResponsiveContainer width={'100%'} height={300}>
        <AreaChart data={chartData}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis
            interval={30}
            dataKey="dtgUpdate"
            tickFormatter={formatXAxis}
            allowDuplicatedCategory={true}
          />
          <YAxis />
          <Tooltip formatter={(data) => [data, 'Updates']} />
          <Area
            type="monotone"
            dataKey="count"
            stroke={theme.palette.charts.stroke}
            fill={theme.palette.charts.fill}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  );
};

const ChipContainer = styled('div')<{
  status: 'live' | 'terminated' | 'locked';
}>`
  background-color: ${({ theme, status }) => theme.palette.statusChip[status]};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  height: 24px;
  padding: 5px;
  border-radius: ${({ theme }) => theme.border.chipRadius};
  color: ${({ theme }) => theme.palette.primary.light};
  margin: ${({ theme }) => theme.margin.l} 0;
`;

const CustomText = styled(Text)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: bold;
  margin: 0 5px;
`;
