import { errorMessages } from 'api/resources/responseMessages/failure';
import { successMessages } from 'api/resources/responseMessages/success';
import { AxiosError } from 'axios';
import PrimaryToast from 'components/PrimaryToast';
import { ROUTES } from 'core/routes';
import { useCreateMaintenanceMessages } from 'pages/hooks';
import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { DetailsMaintainanceMessages } from './detailsMaintenanceMessages';
import { CustomMaintenanceMessages, convertToAPIData } from './form';

export function CreateMaintenanceMessages() {
  const navigate = useNavigate();
  const { createMaintenanceMessage, isCreating } =
    useCreateMaintenanceMessages();

  const [createdProfileId, setCreatedProfileId] = useState<number | null>(null);
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });
  return (
    <React.Fragment>
      <DetailsMaintainanceMessages onSubmit={onSubmit} isBusy={isCreating} />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => {
          setToastState({ ...toastState, isOpen: false });
          if (createdProfileId) {
            const route = generatePath(ROUTES.updateMaintenanceMessage, {
              messageId: createdProfileId,
            });
            navigate(route, { replace: true });
          }
        }}
        severity={toastState.severity}
      />
    </React.Fragment>
  );
  function onSubmit(data: CustomMaintenanceMessages) {
    createMaintenanceMessage?.(convertToAPIData({ ...data }))
      .then((response) => {
        setCreatedProfileId(response.id);
        setToastState({
          message: successMessages.createMaintenanceMessage,
          isOpen: true,
          severity: 'success',
        });
      })
      .catch((error: AxiosError) => handleError(error));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(error: AxiosError) {
    setToastState({
      message: errorMessages.createMaintenanceMessages,
      isOpen: true,
      severity: 'error',
    });
  }
}
