import styled from '@emotion/styled';
import { Container } from 'components/Layout';
import {
  Header,
  InputContainer,
  InputFirst,
  InputSecond,
  Label,
  RequiredLabel,
  SaveButton,
  SaveIcon,
} from 'components/PageLayout';
import { ErrorText, Text } from 'components/Typography';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { TextArea } from 'components/TextArea';
import { useTheme } from '@emotion/react';
import { GrayBackdrop, Loader } from 'components/Loader';
import { TextField } from 'components/TextField';
import { formErrorMessages } from 'api/resources/responseMessages/formErrors';
import {
  AdminRepairerExternalCode,
  RepairerLinkType,
} from 'api/resources/models/AutoGenerated';
import { useCompanies } from 'pages/hooks';
import { useMemo } from 'react';
import { Select, SelectOption } from 'components/Select';
import { BMS } from './constants';
import { CustomRepairerDetails, RepairerBasicDetails } from '../types';
import { getExternalCodeValue, handleInputKeyDown } from '../helpers';

const validation = Yup.object({
  name: Yup.string().required(formErrorMessages.required),
  repairerGroup: Yup.string().required(formErrorMessages.required),
  address: Yup.string().required(formErrorMessages.required),
  telephone: Yup.number().nullable(),
  email: Yup.string().email(),
  postCode: Yup.string().nullable().required(formErrorMessages.required),
  website: Yup.string(),
  companyId: Yup.number(),
  siteCode: Yup.string().required(formErrorMessages.required),
  systemType: Yup.object({
    id: Yup.number(),
    value: Yup.string(),
    text: Yup.string(),
  })
    .nullable()
    .required(formErrorMessages.required),
  vatRegistrationNo: Yup.string().nullable(),
});

interface Props {
  isBusy: boolean;
  hasPermission: boolean;
  repairerDetails?: RepairerBasicDetails;
  repairerExternalCodes?: AdminRepairerExternalCode[] | null;
  onSubmit: (data: CustomRepairerDetails) => void;
}

export const RepairDetailsForm = ({
  isBusy,
  onSubmit,
  hasPermission,
  repairerDetails,
  repairerExternalCodes,
}: Props) => {
  const theme = useTheme();
  const { companiesInfo, isCompaniesLoading } = useCompanies();

  const companyOptions = useMemo<SelectOption[]>(() => {
    const companies =
      companiesInfo?.map((e) => ({
        id: e.id,
        value: e.name ?? '',
        text: e.name ?? '',
      })) || [];
    return [
      {
        id: 0,
        value: 'N/A',
        text: 'N/A',
      },
      ...companies,
    ];
  }, [companiesInfo]);

  const selectBMSOptions = BMS?.map((item, index) => ({
    id: index,
    value: item,
    text: item,
  }));

  const {
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values: {
      address,
      email,
      companyId,
      siteCode,
      systemType,
      vatRegistrationNo,
      name,
      telephone,
      website,
      postCode,
      repairerGroup,
      capsCode,
      audatexCode,
    },
  }: FormikProps<CustomRepairerDetails> = useFormik<CustomRepairerDetails>({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      address: repairerDetails?.address ?? '',
      email: repairerDetails?.email ?? '',
      repairerGroup: repairerDetails?.repaierGroup ?? '',
      name: repairerDetails?.repairerName ?? '',
      companyId: repairerDetails?.companyId ?? 0,
      postCode: repairerDetails?.postcode ?? '',
      telephone: repairerDetails?.telephone ?? '',
      website: repairerDetails?.website ?? '',
      capsCode: getExternalCodeValue('CAPS', repairerExternalCodes) ?? '',
      audatexCode: getExternalCodeValue('AUDATEX', repairerExternalCodes) ?? '',
      systemType:
        selectBMSOptions.find((e) => e.value == repairerDetails?.systemType) ??
        null,
      siteCode: repairerDetails?.siteCode ?? '',
      vatRegistrationNo: repairerDetails?.vatRegistrationNo ?? '',
      accountLive: false,
      fax: '',
      linkType: RepairerLinkType.None,
    },
    validationSchema: validation,
    onSubmit: (values) =>
      onSubmit({ ...values, telephone: values.telephone?.toString() ?? null }),
  });

  const loading = isBusy || isCompaniesLoading;

  return (
    <form onSubmit={handleSubmit}>
      <GrayBackdrop open={loading}>
        <Loader />
      </GrayBackdrop>

      {hasPermission && (
        <Container md={12} sm={12} xs={12}>
          <StyledSaveButton type="submit">
            <SaveIcon />
            Save Changes
          </StyledSaveButton>
        </Container>
      )}

      <CustomHeader>Repairer Details</CustomHeader>

      <InputContainer direction="row" md={12} sm={12} xs={12}>
        <InputFirst md={6} sm={6} xs={12}>
          <RequiredLabel>Name</RequiredLabel>
          <TextField
            fullWidth
            name="name"
            value={name}
            disabled={!hasPermission}
            onChange={handleChange}
            error={!!errors?.name && touched?.name}
            helperText={touched?.name && errors?.name}
          />
        </InputFirst>
        <InputSecond md={6} sm={6} xs={12}>
          <RequiredLabel>Group</RequiredLabel>
          <TextField
            fullWidth
            value={repairerGroup}
            onChange={handleChange}
            name="repairerGroup"
            disabled={!hasPermission}
            error={!!errors?.repairerGroup && touched?.repairerGroup}
            helperText={touched?.repairerGroup && errors?.repairerGroup}
          />
        </InputSecond>
      </InputContainer>

      <InputContainer direction="row" md={12} sm={12} xs={12}>
        <InputFirst md={6} sm={6} xs={12}>
          <RequiredLabel>Address</RequiredLabel>
          <CustomTextArea
            name="address"
            value={address || ''}
            disabled={!hasPermission}
            onChange={handleChange}
          />
          {errors?.address && touched?.address && (
            <Text color={theme.palette.primary.error}>{errors.address}</Text>
          )}
          <RequiredLabel>Postal Code</RequiredLabel>
          <TextField
            fullWidth
            value={postCode}
            onChange={handleChange}
            name="postCode"
            disabled={!hasPermission}
            error={!!errors?.postCode && touched?.postCode}
            helperText={touched?.postCode && errors?.postCode}
          />
        </InputFirst>
        <InputSecond md={6} sm={6} xs={12}>
          <InputContainer>
            <Label>Telephone</Label>
            <CustomPhoneField
              fullWidth
              type="number"
              name="telephone"
              value={telephone}
              onChange={handleChange}
              disabled={!hasPermission}
              onKeyDown={handleInputKeyDown}
              error={!!errors?.telephone && touched?.telephone}
              helperText={touched?.telephone && errors?.telephone}
            />
          </InputContainer>

          <InputContainer>
            <Label>Email</Label>
            <TextField
              fullWidth
              value={email}
              onChange={handleChange}
              name="email"
              disabled={!hasPermission}
              error={!!errors?.email && touched?.email}
              helperText={touched?.email && errors?.email}
            />
          </InputContainer>
          <Label>Website</Label>
          <TextField
            fullWidth
            value={website}
            onChange={handleChange}
            name="website"
            disabled={!hasPermission}
            error={!!errors?.website && touched?.website}
            helperText={touched?.website && errors?.website}
          />
        </InputSecond>
      </InputContainer>

      <InputContainer direction="row" md={12} sm={12} xs={12}>
        <InputFirst md={6} sm={6} xs={12}>
          <Label>CAPS Code</Label>
          <TextField
            disabled={!hasPermission || !!repairerDetails}
            fullWidth
            name="capsCode"
            value={capsCode}
            onChange={handleChange}
            error={!!errors?.capsCode && touched?.capsCode}
            helperText={touched?.capsCode && errors?.capsCode}
          />
        </InputFirst>
        <InputSecond md={6} sm={6} xs={12}>
          <Label>Audatex Code</Label>
          <TextField
            disabled={!hasPermission || !!repairerDetails}
            fullWidth
            name="audatexCode"
            value={audatexCode}
            onChange={handleChange}
            error={!!errors?.audatexCode && touched?.audatexCode}
            helperText={touched?.audatexCode && errors?.audatexCode}
          />
        </InputSecond>
      </InputContainer>

      <Header>Repairer Company Details</Header>

      <InputContainer direction="row" md={12} sm={12} xs={12}>
        <InputFirst md={6} sm={6} xs={12}>
          <RequiredLabel>Company</RequiredLabel>
          <Select
            variant="outlined"
            options={companyOptions}
            selectedId={companyId ?? undefined}
            disabled={!hasPermission || !!repairerDetails}
            onChange={(e) => {
              setFieldTouched('companyId');
              setFieldValue('companyId', e.id);
            }}
          />
        </InputFirst>

        <InputSecond md={6} sm={6} xs={12}>
          <RequiredLabel>Bodyshop Management System</RequiredLabel>
          <Select
            options={selectBMSOptions}
            selectedId={systemType?.id}
            onChange={(value) => setFieldValue('systemType', value)}
            disabled={!hasPermission || !!repairerDetails}
            error={!!errors?.systemType && touched?.systemType}
          />
          {!!touched?.systemType && <ErrorText>{errors?.systemType}</ErrorText>}
        </InputSecond>
      </InputContainer>

      <InputContainer direction="row" md={12} sm={12} xs={12}>
        <InputFirst md={6} sm={6} xs={12}>
          <RequiredLabel>Sitecode</RequiredLabel>
          <TextField
            fullWidth
            value={siteCode}
            onChange={handleChange}
            name="siteCode"
            disabled={!hasPermission || !!repairerDetails}
            error={!!errors?.siteCode && touched?.siteCode}
            helperText={touched?.siteCode && errors?.siteCode}
          />
        </InputFirst>
        <InputSecond md={6} sm={6} xs={12}>
          <Label>VAT No</Label>
          <TextField
            fullWidth
            value={vatRegistrationNo}
            onChange={handleChange}
            name="vatRegistrationNo"
            disabled={!hasPermission}
            error={!!errors?.vatRegistrationNo && touched?.vatRegistrationNo}
            helperText={touched?.vatRegistrationNo && errors?.vatRegistrationNo}
          />
        </InputSecond>
      </InputContainer>
    </form>
  );
};

const StyledSaveButton = styled(SaveButton)`
  margin-top: ${({ theme }) => theme.margin.l};
  margin-bottom: 0;
`;

const CustomTextArea = styled(TextArea)`
  width: 100%;
  min-height: 125px;
  margin-bottom: 16px;
`;

const CustomHeader = styled(Header)`
  margin-top: 20px;
`;

const CustomPhoneField = styled(TextField)`
  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
`;
