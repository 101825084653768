import { api } from 'api';
import { config } from 'core/config';
import {
  AdminJobStatusUpdateModel,
  AdminJobStatusUpdateResponse,
  AdminWPNameChangeModel,
  AdminWPNameChangeResponse,
} from './models/AutoGenerated';

const repairsBaseUrl = '/api/adminrepair';

export const wpNameChange = (
  data: AdminWPNameChangeModel[]
): Promise<AdminWPNameChangeResponse[]> =>
  api
    .patch(
      `${config.connectionsAdminApiUrl}${repairsBaseUrl}/forceUpdateWorkProvider`,
      data
    )
    .then((response) => response.data);

export const jobStatusUpdate = (
  data: AdminJobStatusUpdateModel[]
): Promise<AdminJobStatusUpdateResponse[]> =>
  api
    .patch(
      `${config.connectionsAdminApiUrl}${repairsBaseUrl}/forceUpdateJobStatus`,
      data
    )
    .then((response) => response.data);
