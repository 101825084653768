import * as Yup from 'yup';
import { formErrorMessages } from 'api/resources/responseMessages/formErrors';
import { SelectOption } from 'components/Select';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  DLGCheckbox?: boolean;
  guidence: string | null;
  sysTypeDropDown?: boolean;
  onSubmit?: ({
    sysType,
    comment,
    ticketId,
    forceRemovalFromDLG,
  }: {
    sysType?: SelectOption | null;
    ticketId: string | null;
    comment: string | null;
    forceRemovalFromDLG?: boolean;
  }) => void;
}

export interface PopUpForm {
  ticketnumber: string | null;
  comments: string;
  sysType?: SelectOption | null;
  forceRemovalFromDLG?: boolean;
}

export const validation = Yup.object({
  ticketnumber: Yup.string().required(formErrorMessages.required),
  comments: Yup.string().required(formErrorMessages.required),
  forceRemovalFromDLG: Yup.boolean(),
});

export const selectOptionsSysType = [
  {
    id: 0,
    value: 'STD',
    text: 'STD',
  },
  {
    id: 1,
    value: 'CAPS',
    text: 'CAPS',
  },
  {
    id: 2,
    value: 'NARG',
    text: 'NARG',
  },
];
