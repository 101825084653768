import styled from '@emotion/styled';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Container, GridItem } from './Layout';
import { Text } from 'components/Typography';
import Skeleton from './Skeleton';
import { Permit, SortOrder } from 'api/resources/models/User';
import { SortObject } from './types';
import { ReactComponent as DescendingIcon } from 'assets/SortDescending.svg';
import { ReactComponent as AscendingIcon } from 'assets/SortAscending.svg';
import { DesktopContainer, MobileContainer } from 'core/Theming/Device';
import { WithWrapper } from './WithWrapper';
import Permits from './Permits';
import { TablePagination } from './Pagination/TablePagination';
import { useRowCountPerPage } from './Pagination/hooks';
import { logger } from 'core/logger';
import { formatDateTime } from 'utils/helpers';
import { SaveButton } from './PageLayout';
import { MouseEventHandler, ReactNode } from 'react';
import { css } from '@emotion/react';
import { appInsights } from 'core/logger/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

interface DataGridProps<T> {
  isLoading?: boolean;
  headers: { id: keyof T; label: string; permitCode?: Permit }[];
  data?: T[];
  totalCount?: number;
  onSort?: (id: keyof T, sortOrder: SortOrder) => void;
  disabledSortColumns?: (keyof T)[];
  sortObject?: SortObject;
  desktopRow?: JSX.Element;
  mobileRow?: JSX.Element;
  onRowClick?: (row: T) => void;
  onPageChange?: (page: number) => void;
  currentPage?: number;
  onItemsPerPageChange?: (count: number) => void;
  removeLeftPadding?: boolean;
  customBgColorRender?: ({
    row,
    baseRender,
  }: {
    row: T;
    baseRender: (color?: string) => JSX.Element;
  }) => JSX.Element;
  customCellRender?: ({
    baseRender,
    value,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    value: T[keyof T];
    headerId: keyof T;
    row: T;
  }) => JSX.Element;
}

export function DataGrid<T>({
  data,
  onSort,
  headers,
  isLoading,
  mobileRow,
  totalCount,
  sortObject,
  desktopRow,
  onRowClick,
  currentPage,
  onPageChange,
  customCellRender,
  removeLeftPadding,
  customBgColorRender,
  disabledSortColumns,
  onItemsPerPageChange,
}: DataGridProps<T>) {
  const items = [...Array(100)].map((i: number) => i + 1);
  const { itemsPerPage } = useRowCountPerPage();
  return (
    <>
      {isLoading ? (
        <LoaderContainer
          isTableDataLoading={isLoading}
          removeLeftPadding={removeLeftPadding}
        >
          {items.map((_e, index) => (
            <Loader
              key={index}
              variant="rectangular"
              width="100%"
              height={56}
            />
          ))}
        </LoaderContainer>
      ) : (
        data &&
        data.length > 0 && (
          <TableContainer>
            <DesktopTableContainer removeLeftPadding={removeLeftPadding}>
              <DesktopContainer>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <WithWrapper
                          key={header.id.toString()}
                          condition={!!header?.permitCode}
                          wrap={(children) => (
                            <Permits permit={header.permitCode!}>
                              {children}
                            </Permits>
                          )}
                        >
                          <StyledTableHeader
                            issortingdisabled={
                              disabledSortColumns
                                ? disabledSortColumns?.includes(header.id)
                                : false
                            }
                            onClick={() =>
                              disabledSortColumns?.includes(header.id)
                                ? undefined
                                : onSort?.(
                                    header.id,
                                    sortObject?.sortOrder ?? SortOrder.Ascending
                                  )
                            }
                          >
                            <Header>
                              <div>{header.label}</div>
                              <SortIconContainer>
                                {disabledSortColumns?.includes(header.id)
                                  ? null
                                  : showSortingIcon(header.id)}
                              </SortIconContainer>
                            </Header>
                          </StyledTableHeader>
                        </WithWrapper>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!desktopRow
                      ? data.map((row, i) => (
                          <CustomBGTableRow
                            row={row}
                            key={i}
                            onClick={() => onRowClick?.(row)}
                            bgColorRender={customBgColorRender}
                          >
                            {headers.map((header) => (
                              <StyledTableCell
                                key={header.id.toString()}
                                component="td"
                                scope="row"
                              >
                                <CellValue
                                  row={row}
                                  headerId={header.id}
                                  customCellRender={customCellRender}
                                />
                              </StyledTableCell>
                            ))}
                          </CustomBGTableRow>
                        ))
                      : desktopRow}
                  </TableBody>
                </Table>
              </DesktopContainer>
            </DesktopTableContainer>
            <MobileContainer>
              <Table aria-label="simple table">
                <TableBody>
                  {!mobileRow
                    ? data.map((row, i) => (
                        <MobileRow key={i}>
                          {headers.map((header) => (
                            <CustomBGTableRow
                              row={row}
                              isMobile
                              key={header.id.toString()}
                              onClick={() => onRowClick?.(row)}
                              bgColorRender={customBgColorRender}
                            >
                              <GridItem md={6} key={header.id.toString()}>
                                <MobileLabel fontSize="xs">
                                  {header.label}
                                </MobileLabel>
                                <MobileValue fontSize="m">
                                  <CellValue
                                    row={row}
                                    headerId={header.id}
                                    customCellRender={customCellRender}
                                  />
                                </MobileValue>
                              </GridItem>
                            </CustomBGTableRow>
                          ))}
                        </MobileRow>
                      ))
                    : mobileRow}
                  <LastRow />
                </TableBody>
              </Table>
            </MobileContainer>
            {onPageChange && currentPage !== undefined && itemsPerPage && (
              <TablePagination
                totalCount={totalCount ?? 0}
                onPageChange={onPageChange}
                currentPage={currentPage}
                onItemsPerPageChange={(count) => {
                  onItemsPerPageChange?.(count);
                }}
              />
            )}
          </TableContainer>
        )
      )}
    </>
  );

  function showSortingIcon(id: keyof T) {
    if (sortObject?.sortString === id) {
      return sortObject.sortOrder === SortOrder.Descending ? (
        <DescendingIcon />
      ) : (
        <AscendingIcon />
      );
    }
  }
}

export function getSortObject(sortValue: string, prevValue: SortObject) {
  return {
    sortString:
      prevValue.sortString !== sortValue ? sortValue : prevValue.sortString,
    sortOrder:
      prevValue.sortString !== sortValue
        ? SortOrder.Ascending
        : prevValue.sortOrder !== SortOrder.Ascending
        ? SortOrder.Ascending
        : SortOrder.Descending,
  };
}

interface RowProps<T> {
  row: T;
  isMobile?: boolean;
  onClick: () => void;
  children: React.ReactNode;
  bgColorRender?: ({
    row,
    baseRender,
  }: {
    row: T;
    baseRender: (color?: string) => JSX.Element;
  }) => JSX.Element;
}

function CustomBGTableRow<T>({
  row,
  children,
  isMobile,
  onClick,
  bgColorRender,
}: RowProps<T>) {
  if (bgColorRender)
    return bgColorRender({
      row: row,
      baseRender: (color) => renderCell(color),
    });
  return renderCell();

  function renderCell(bgColor?: string) {
    if (isMobile)
      return (
        <MobileTableCell onClick={onClick} bgcolor={bgColor}>
          {children}
        </MobileTableCell>
      );
    return (
      <StyledTableRow onClick={onClick} bgcolor={bgColor}>
        {children}
      </StyledTableRow>
    );
  }
}

interface CellProps<T> {
  row: T;
  headerId: keyof T;
  customCellRender?: ({
    baseRender,
    value,
    headerId,
  }: {
    baseRender: () => JSX.Element;
    value: T[keyof T];
    headerId: keyof T;
    row: T;
  }) => JSX.Element;
}

function CellValue<T>({ row, headerId, customCellRender }: CellProps<T>) {
  const value = row[headerId];
  const valueToRender =
    typeof value === 'bigint' ||
    typeof value === 'boolean' ||
    typeof value === 'number' ||
    typeof value === 'string'
      ? value.toString()
      : value === null
      ? ''
      : value instanceof Date
      ? formatDateTime(value)
      : undefined;
  if (valueToRender === undefined) {
    const infoMessage = `DataGrid doesn't support complex types. Please provide custom row component if you want to render such complex types as objects`
    logger?.warn(infoMessage);
    appInsights?.trackException({ error: new Error(infoMessage), severityLevel: SeverityLevel.Warning });
  }
  if (customCellRender)
    return customCellRender({
      baseRender: () => renderCell(valueToRender),
      value: value,
      headerId: headerId,
      row: row,
    });

  return renderCell(valueToRender);

  function renderCell(renderValue: string | undefined) {
    return <Cell value={renderValue}>{renderValue}</Cell>;
  }
}

const Cell = styled.span<{ value: string | undefined }>`
  text-transform: ${({ value }) =>
    value === 'true' || value === 'false' ? 'capitalize' : 'inherits'};
`;

const removeLeftPadding = (props: { removeLeftPadding?: boolean }) =>
  props.removeLeftPadding
    ? css`
        padding-left: 0;
      `
    : null;

const DesktopTableContainer = styled(Container)`
  width: 100%;
  padding: ${({ theme }) => `${theme.padding.l} 0 0 ${theme.padding.xl}`};
  ${removeLeftPadding}
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-top: ${({ theme }) => ` ${theme.padding.m}`};
  }
`;

const StyledTableHeader = styled(TableCell)<{ issortingdisabled: boolean }>`
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.s}`};
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  line-height: ${({ theme }) => theme.fontSize.s};
  cursor: ${({ issortingdisabled }) =>
    issortingdisabled ? 'not-allowed' : 'pointer'};
  background-color: #fafafa;
`;

const Header = styled.div`
  display: flex;

  & svg {
    margin-left: ${({ theme }) => theme.margin.s};
  }
`;

const SortIconContainer = styled.div`
  width: 15px;
  max-width: 100%;
`;

export const StyledTableRow = styled(TableRow)<{ bgcolor?: string }>`
  background: ${({ bgcolor, theme }) =>
    bgcolor || theme.palette.background.default};

  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.palette.row.hover};
    border-radius: ${({ theme }) => theme.border.radius};
  }
`;

export const StyledTableCell = styled(TableCell)`
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.s}`};
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.primary.main};
  white-space: pre-wrap;

  & a {
    color: ${({ theme }) => theme.palette.secondary.dark};
    text-decoration: none;
  }

  div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 16px;
  }
`;

const LoaderContainer = styled(DesktopTableContainer)<{
  isTableDataLoading: boolean;
}>`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: ${({ theme, isTableDataLoading }) =>
      `${theme.padding.m} ${isTableDataLoading ? '20px' : 0}`};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: ${({ theme, isTableDataLoading }) =>
      `${theme.padding.m} ${isTableDataLoading ? '15px' : 0}`};
  }
`;

const Loader = styled(Skeleton)`
  margin-bottom: ${({ theme }) => theme.margin.s};
`;

const MobileLabel = styled(Text)`
  margin-bottom: ${({ theme }) => theme.margin.xs};
  color: ${({ theme }) => theme.palette.secondary.main};
`;
const MobileValue = styled(Text)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const MobileTableCell = styled(TableCell)<{ bgcolor?: string }>`
  background: ${({ bgcolor }) => bgcolor};
  padding: ${({ theme }) => `0 15px ${theme.padding.m} 15px`};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-left: 20px;
  }
  vertical-align: top;
  width: 50%;
  font-size: ${({ theme }) => theme.fontSize.s};
  border-bottom: none;
`;

const MobileRow = styled(Container)`
  padding-top: ${({ theme }) => ` ${theme.padding.m}`};
  word-break: break-all;
  border-top: ${({ theme }) => `1px solid ${theme.palette.primary.border}`};
`;

const LastRow = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.palette.primary.border}`};
`;

export function CellButton({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return <GridButton onClick={onClick}>{children}</GridButton>;
}

const GridButton = styled(SaveButton)`
  width: unset;
  height: unset;
  margin-bottom: 0;
`;
