import styled from '@emotion/styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LockIcon from '@mui/icons-material/Lock';
import { Container } from './Layout';

interface Props {
  status: string;
  color: string;
  description: string;
}

export function StatusChip({ description, status, color }: Props) {
  return (
    <Container>
      <Chip color={color}>
        {status == 'live' ? (
          <CheckCircleIcon />
        ) : status == 'terminated' ? (
          <ErrorIcon />
        ) : status == 'locked' ? (
          <LockIcon />
        ) : null}
        <Description>{description}</Description>
      </Chip>
    </Container>
  );
}

const Chip = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  font-size: ${({ theme }) => theme.fontSize.xs};
  border-radius: ${({ theme }) => theme.border.chipRadius};
  font-weight: bold;
  height: 24px;
  padding: 5px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.light};
  white-space: nowrap;
`;

const Description = styled.span`
  margin: 0 5px;
`;
