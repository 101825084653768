import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {
  AutoSizer as _AutoSizer,
  List as _List,
  ListProps,
  AutoSizerProps,
} from 'react-virtualized';

const List = _List as unknown as React.FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

export interface SelectOption {
  value: string;
  text: string;
  optionElement?: JSX.Element;
  id: number;
}

interface SelectProps {
  options: SelectOption[];
  onChange?: (selected: SelectOption) => void;
  className?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  error?: boolean;
  disabled?: boolean;
  value: SelectOption[];
}

export const MultipleSelect = ({
  options,
  disabled,
  onChange,
  value,
  variant,
}: SelectProps) => {
  const totalLength = React.useMemo(
    () => (options.length > 0 ? options?.length + 1 : options?.length),
    [options]
  );

  return (
    <StyledMaterialSelect
      multiple
      value={value}
      variant={variant}
      disabled={disabled}
      MenuProps={MenuProps}
      id={'demo-multiple-checkbox-label'}
      labelId="demo-multiple-checkbox-label"
      renderValue={() =>
        options
          .filter((a) => value.some((v) => v.id === a.id))
          .map((a) => a.text)
          .join(', ')
      }
    >
      <PrimaryContainer>
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              isScrolling={false}
              rowHeight={50}
              height={height}
              rowCount={totalLength}
              rowRenderer={({ index, style }) => {
                if (index == 0)
                  return (
                    <StyledMenuItem
                      key="selectAll"
                      value={'Select All'}
                      onClick={() => {
                        onChange?.({ value: 'All', text: 'All', id: -1 });
                      }}
                    >
                      <Checkbox checked={value.length === options.length} />
                      <ListItemText primary="Select All" />
                    </StyledMenuItem>
                  );
                return (
                  <StyledMenuItem
                    style={style}
                    key={options[index - 1].id}
                    value={options[index - 1].value}
                    onClick={() => onChange?.(options[index - 1])}
                  >
                    <Checkbox
                      checked={value.some(
                        (option) => option.id === options[index - 1].id
                      )}
                    />
                    <ListItemText primary={options[index - 1].text} />
                  </StyledMenuItem>
                );
              }}
            />
          )}
        </AutoSizer>
      </PrimaryContainer>
    </StyledMaterialSelect>
  );
};

const PrimaryContainer = styled.div`
  width: 100%;
  height: 195px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: ${({ theme }) => theme.fontSize.m};
`;

const StyledMaterialSelect = styled(Select)`
  width: 100%;
  max-width: 330px !important;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.m};
  background-color: ${({ theme }) => theme.palette.background.default};

  & .MuiSelect-select {
    background-color: transparent;
    padding: 2px 14px;
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme, disabled }) =>
      disabled ? theme.palette.button.disabled : 'inherits'};
  }

  &.MuiPaper-root {
    width: 100%;
  }
  fieldset {
    border: ${({ disabled }) => (disabled ? '0px' : 'inherits')};
  }
`;
