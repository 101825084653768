import { SortOrder } from 'api/resources/models/User';
import { AdminNotificationHeader } from 'api/resources/models/AutoGenerated';
import { DataGrid, getSortObject } from 'components/DataGrid';
import InfoText from 'components/InfoText';
import {
  MainPageContainer,
  StyledGridItem,
  UpperInfoContainer,
} from 'components/PageLayout';
import { SortObject } from 'components/types';
import { Heading } from 'components/Typography';
import { createFnolQueueURL, ROUTES } from 'core/routes';
import { orderBy } from 'lodash';
import { useFnolQueues } from 'pages/hooks';
import { useEffect, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { formatDateTime } from 'utils/helpers';
import { fnolTableHeader, useFnolQueueParams } from './types';
import styled from '@emotion/styled';
import { TextField } from 'components/TextField';
import { useFormik } from 'formik';
import { PrimaryButton } from 'components/Button';
import { Search } from '@mui/icons-material';
import PrimaryToast from 'components/PrimaryToast';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { useRowCountPerPage } from 'components/Pagination/hooks';

export function FnolQueuePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = useFnolQueueParams(location.search);

  const { fnols, isFnolsLoading, refetch } = useFnolQueues(
    urlParams.searchValue ?? undefined,
    handleError
  );

  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sortString ?? '',
    sortOrder: urlParams.sortOrder ?? undefined,
  });
  const [sortedFnol, setSortedFnol] = useState(fnols);
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  useEffect(() => {
    setSortedFnol(sortFnol(sortObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fnols, currentPage, itemsPerPage]);

  useEffect(() => {
    navigateToFnolQueues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject]);

  const {
    values: { searchValue },
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      searchValue: urlParams.searchValue || '',
    },
    onSubmit: () => {
      navigateToFnolQueues(true);
    },
  });

  const isSearchEnabled = !!searchValue;
  const isBusy = isFnolsLoading;

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <form onSubmit={handleSubmit}>
        <UpperInfoContainer direction="column">
          <StyledGridItem>
            <Heading>FNOL</Heading>
          </StyledGridItem>
          <StyledGridItem>
            <TextInput
              name="searchValue"
              value={searchValue}
              onChange={handleChange}
              placeholder="Search notifications using Vehicle Reg."
            />
            <SearchButton type="submit" disabled={!isSearchEnabled}>
              <SearchIcon />
              Search
            </SearchButton>
          </StyledGridItem>
        </UpperInfoContainer>

        {!isBusy && fnols?.length === 0 && (
          <InfoText>{errorMessages.noFnolSearchesFound}</InfoText>
        )}
        <DataGrid
          headers={fnolTableHeader}
          data={sortedFnol}
          isLoading={isBusy}
          onSort={sort}
          onRowClick={onRowClickHandler}
          sortObject={sortObject}
          customCellRender={renderCustomCell}
          currentPage={currentPage}
          totalCount={fnols?.length}
          onPageChange={(page) => setCurrentPage(page)}
          onItemsPerPageChange={setItemsPerPage}
        />
      </form>
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
    </MainPageContainer>
  );

  function renderCustomCell({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof AdminNotificationHeader;
    row: AdminNotificationHeader;
  }) {
    if (headerId == 'notificationDate' && row.notificationDate)
      return <>{formatDateTime(row.notificationDate)}</>;
    return baseRender();
  }

  function onRowClickHandler(row: AdminNotificationHeader) {
    const path = generatePath(ROUTES.fnolDetails, {
      notificationId: row?.notificationId,
    });
    navigate(path, { state: { previousPath: location.pathname || '' } });
  }

  function sortFnol(sortObj: SortObject) {
    const currentListed = currentPage * itemsPerPage;
    const orderedList = orderBy(
      fnols,
      sortObj.sortString,
      sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
    );
    return orderedList?.slice(currentListed, currentListed + itemsPerPage);
  }

  function sort(sortValue: keyof AdminNotificationHeader) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedFnol(sortFnol(sortObj));
      return sortObj;
    });
  }

  function navigateToFnolQueues(isSearchButton?: boolean) {
    navigate(
      createFnolQueueURL(
        sortObject.sortString ?? undefined,
        sortObject.sortOrder,
        searchValue
      ),
      { replace: true }
    );
    if (isSearchButton && searchValue == urlParams.searchValue) refetch();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(error: AxiosError) {
    setToastState({
      message: errorMessages.fnol,
      isOpen: true,
      severity: 'error',
    });
  }
}

const TextInput = styled(TextField)`
  width: 260px;
  margin-right: 8px;

  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
    overflow: hidden;
  }
`;

const SearchButton = styled(PrimaryButton)`
  width: 99px;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  padding-right: 10px;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
  }
`;

const SearchIcon = styled(Search)`
  height: 17.49px;
  width: 17.49px;
  margin-right: 10px;
`;
