import { RenewTokenModal } from 'components/RenewToken';
import { ReactNode, useRef, useState } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { signout } from './auth/userService';
import { config } from './config';
import { ROUTES } from './routes';

export const IdleTimerWrapper = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { popupLogoutTime, totalIdleTime } = { ...config };
  const timeout = totalIdleTime - popupLogoutTime;

  const sessionTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const continueHandler = () => {
    sessionTimeoutRef.current && clearTimeout(sessionTimeoutRef.current);
    setShowModal(false);
  };

  const logoutHandler = async () => {
    sessionTimeoutRef.current && clearTimeout(sessionTimeoutRef.current);
    await signout();
    navigate(ROUTES.logout);
  };

  return (
    <>
      <IdleTimerProvider
        timeout={timeout * 60 * 1000}
        onIdle={() => {
          !showModal && setShowModal(true);
          sessionTimeoutRef.current = setTimeout(async () => {
            await logoutHandler();
          }, popupLogoutTime * 60 * 1000);
        }}
      >
        {children}
      </IdleTimerProvider>
      <RenewTokenModal
        isOpen={showModal}
        logoutHandler={logoutHandler}
        continueHandler={continueHandler}
      />
    </>
  );
};
