import { SortOrder } from 'api/resources/models/User';
import { DataGrid, getSortObject } from 'components/DataGrid';
import { SortObject } from 'components/types';
import { useEffect, useState } from 'react';
import { externalCodeTableHeader } from './types';
import { orderBy } from 'lodash';
import { formatDate } from 'utils/helpers';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import { AdminRepairerExternalCode } from 'api/resources/models/AutoGenerated';

interface Props {
  repairerExternalCodes: AdminRepairerExternalCode[];
}

export const ExternalCode = ({ repairerExternalCodes }: Props) => {
  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: 'externalSystem',
    sortOrder: SortOrder.Descending,
  });
  const [sortedRepairerExternalCode, setSortedRepairerExternalCode] = useState(
    repairerExternalCodes
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  useEffect(() => {
    setSortedRepairerExternalCode(sortExternalCodes(sortObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repairerExternalCodes, currentPage, itemsPerPage]);

  return repairerExternalCodes && repairerExternalCodes?.length > 0 ? (
    <DataGrid
      onSort={sort}
      removeLeftPadding
      sortObject={sortObject}
      currentPage={currentPage}
      data={sortedRepairerExternalCode}
      headers={externalCodeTableHeader}
      customCellRender={renderCustomCell}
      onItemsPerPageChange={setItemsPerPage}
      totalCount={repairerExternalCodes?.length}
      onPageChange={(page) => setCurrentPage(page)}
    />
  ) : null;

  function renderCustomCell({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof AdminRepairerExternalCode;
    row: AdminRepairerExternalCode;
  }) {
    if (headerId == 'dtgCreated' && row.dtgCreated)
      return <>{formatDate(row.dtgCreated)}</>;
    if (headerId == 'enabled' && row.enabled != null)
      return row.enabled ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    return baseRender();
  }

  function sortExternalCodes(sortObj: SortObject) {
    const currentListed = currentPage * itemsPerPage;
    const orderedList = orderBy(
      repairerExternalCodes,
      sortObj.sortString,
      sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
    );
    return orderedList?.slice(currentListed, currentListed + itemsPerPage);
  }

  function sort(sortValue: keyof AdminRepairerExternalCode) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedRepairerExternalCode(sortExternalCodes(sortObj));
      return sortObj;
    });
  }
};
