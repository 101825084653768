import { formatDate } from "utils/helpers";
import { HeaderIdType, RowType } from "./types";

export const renderCustomCell = ({
    baseRender,
    headerId,
    row,
}: {
    baseRender: () => JSX.Element;
    headerId: HeaderIdType;
    row: RowType;
}) => {
    if (headerId == 'serviceStartDate')
        return <>{formatDate(row.serviceStartDate)}</>;
    if (headerId == 'startDate')
        return <>{formatDate(row.startDate)}</>;
    if (headerId == 'deployByEmail' || headerId == 'deployElectronically')
        if (row[headerId]) return <>{'Yes'}</>;
        else return <>{'No'}</>;
    return baseRender();
}