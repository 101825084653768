import { getBadRequestErrorMessage } from 'api';
import {
  AdminRepairerExternalCode,
  AdminRepairerModel,
} from 'api/resources/models/AutoGenerated';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { guidenceTextDetails } from 'api/resources/responseMessages/infoMessages';
import { successMessages } from 'api/resources/responseMessages/success';
import { AxiosError } from 'axios';
import { CreateRepairerActionPopup } from 'components/CreateEditRepairerActionPopup';
import PrimaryToast from 'components/PrimaryToast';
import { useEditRepairer } from 'pages/hooks';
import React, { useState } from 'react';
import { RepairDetailsForm } from '../RepairerDetailsForm';
import { ActionPopupSubmit, RepairerBasicDetails } from '../types';

interface Props {
  repairerId: number;
  hasPermission: boolean;
  repairerDetails: RepairerBasicDetails;
  repairerExternalCodes: AdminRepairerExternalCode[] | null;
  isDisabled: boolean;
}

export const Details = ({
  repairerId,
  isDisabled,
  repairerDetails,
  repairerExternalCodes,
  hasPermission,
}: Props) => {
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  const [actionPopup, setActionPopup] = useState<{
    title: string;
    guidence: string | null;
    isOpen: boolean;
    onSubmit?: (data: ActionPopupSubmit) => void;
  }>({
    title: '',
    guidence: '',
    isOpen: false,
  });

  const { isUpdating, updateRepairer } = useEditRepairer();

  const isBusy = isUpdating;
  return (
    <React.Fragment>
      <RepairDetailsForm
        isBusy={isBusy}
        hasPermission={hasPermission && !isDisabled}
        repairerDetails={repairerDetails}
        repairerExternalCodes={repairerExternalCodes}
        onSubmit={(data) =>
          setActionPopup({
            isOpen: true,
            title: 'Details',
            guidence: guidenceTextDetails,
            onSubmit: (popupData) =>
              onSubmit({
                ...data,
                ...popupData,
                repairerId,
                coveaCode: '',
                systemType: data.systemType?.value ?? '',
              }),
          })
        }
      />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
      <CreateRepairerActionPopup
        title={actionPopup.title}
        isOpen={actionPopup.isOpen}
        guidence={actionPopup.guidence}
        onSubmit={actionPopup.onSubmit}
        onClose={() =>
          setActionPopup({
            title: '',
            guidence: '',
            isOpen: false,
          })
        }
      />
    </React.Fragment>
  );

  function onSubmit(data: AdminRepairerModel) {
    updateRepairer?.(data)
      .then(() => {
        setToastState({
          message: successMessages.updateRepairerDetail,
          isOpen: true,
          severity: 'success',
        });
      })
      .catch((error: AxiosError) => handleError(error));
  }

  interface ErrorResponseData {
    CallStack: string | null;
    ErrorCode: number;
    Message: string | null;
    OriginalMessage: string | null;
  }

  function handleError(error: AxiosError) {
    const errorData = getBadRequestErrorMessage(error);
    const errorMessage = (errorData as ErrorResponseData)?.Message;
    setToastState({
      message: errorMessage ?? errorMessages.updateRepairers,
      isOpen: true,
      severity: 'error',
    });
  }
};
