import { createContext, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import { ROUTES } from './routes';
import { useInMobile } from './Theming/Device';

interface Props {
  children: ReactElement;
}

export const ScrollContext = createContext<number | undefined>(undefined);

export default function ScrollContextProvider({
  children,
}: Props): ReactElement {
  const [translateY, setTranslateY] = useState<number | undefined>(undefined);
  const { pathname } = useLocation();
  const isMobile = useInMobile();

  useEffect(() => {
    // if (pathname !== ROUTES.vehiclesSearch || isMobile) return;

    const handleScroll = () =>
      window.scrollY >= 0 &&
      document.body.scrollWidth > document.body.clientWidth
        ? setTranslateY(window.scrollY)
        : setTranslateY(undefined);

    window.addEventListener('scroll', handleScroll);
    return () => {
      setTranslateY(undefined);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, pathname]);

  return (
    <ScrollContext.Provider value={translateY}>
      {children}
    </ScrollContext.Provider>
  );
}
