import { useParams } from 'react-router-dom';
import { RepairerDetailsParams } from 'core/routes';
import {
  MainPageContainer,
  pagePaddingLeft,
  UpperInfoContainer,
} from 'components/PageLayout';
import { Heading } from 'components/Typography';
import { Container, GridItem } from 'components/Layout';
import { BackButton } from 'components/Button';
import styled from '@emotion/styled';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { AddFNOLForm } from './AddFnol';
import { TitleInfo } from 'components/TitleInfo';
import { useAvailableWorkProviders } from 'pages/hooks';
import { useMemo } from 'react';

const useRepairerDetailsParams = () => {
  const {
    repairerId: rawUserId,
    siteCode,
    externalCodes,
  } = useParams<RepairerDetailsParams>();
  const repairerId = Number(rawUserId);
  return {
    siteCode,
    repairerId: isNaN(repairerId)
      ? errorMessages.invalidId('Repairer ID')
      : repairerId,
    externalCodes,
  };
};

export const AddFNOL = () => {
  const { repairerId, siteCode, externalCodes } = useRepairerDetailsParams();

  if (typeof repairerId === 'string')
    throw new Error(errorMessages.EditInvalidId('Repairer'));
  if (!siteCode) throw new Error(errorMessages.EditInvalidId('Sitecode'));

  const { availableWorkProviders, isAvailableWorkProviderLoading } =
    useAvailableWorkProviders(siteCode);

  const externalCodesExpanded = useMemo(() => {
    if (externalCodes == 'null') return null;
    return externalCodes?.split(',').map((item) => {
      const parts = item.split(':');
      return { [parts[0].trimStart().trim()]: parts[1] };
    });
  }, [externalCodes]);

  return (
    <CustomMainPageContainer direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <BackButton text="Back to Repairers Details" />
        <Heading>
          <div className="title">
            Add FNOL Configuration{' '}
            <TitleInfo
              message="This is required for generating Allianz, LV and DLG FNOL connections.
            These must only be created with authorisation from the Work Provider."
              title="Add FNOL Configuration"
            />
          </div>
        </Heading>
      </UpperInfoContainer>

      <CustomContainer direction="column">
        <Container direction="row">
          <GridItem md={12} sm={12} xs={12}>
            <AddFNOLForm
              siteCode={siteCode}
              repairerId={repairerId}
              isBusy={isAvailableWorkProviderLoading}
              externalCodesExpanded={externalCodesExpanded}
              availableWorkProviders={availableWorkProviders}
            />
          </GridItem>
        </Container>
      </CustomContainer>
    </CustomMainPageContainer>
  );
};

const CustomMainPageContainer = styled(MainPageContainer)`
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-right: 0;
  }
  & .title {
    display: flex;
  }
`;

const CustomContainer = styled(Container)`
  background: ${({ theme }) => theme.palette.background.default};
  padding-left: ${({ theme }) => pagePaddingLeft(theme)};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;
