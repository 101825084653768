import {
  MainPageContainer,
  StyledGridItem,
  UpperInfoContainer,
} from 'components/PageLayout';
import { Heading, Text } from 'components/Typography';
import styled from '@emotion/styled';
import { ListItem } from '@mui/material';
import { pagePaddingLeft } from 'components/PageLayout';
import { useChangelogDetails } from 'pages/hooks';
import { formatDateTime } from 'utils/helpers';
import { Loader } from 'components/Loader';
import { GrayBackdrop } from 'components/Loader';

export const About = () => {
  const { changelog_details, isChangeloglDetailsLoading } =
    useChangelogDetails();

  const isBusy = isChangeloglDetailsLoading;

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <StyledGridItem>
          <Heading className="subheading">About</Heading>
        </StyledGridItem>
        <StyledGridItem>
          <Text fontSize="m">
            {'Version: ' + (changelog_details?.[0]?.changeNumber || '')}
          </Text>
        </StyledGridItem>
      </UpperInfoContainer>

      <ContentInfoContainer direction="column" wrap="nowrap">
        <StyledGridItem>
          <Heading className="subheading">Change Log</Heading>
        </StyledGridItem>
        {changelog_details?.map((data, index) => (
          <CustomStyledGridItem key={index}>
            <Text fontSize="m">
              {formatDateTime(data.changeDate).split(' ', 1)}
            </Text>
            <Text fontSize="m">{'Version: ' + data.changeNumber}</Text>
            <Text fontSize="m">
              <CustomListItem>{data.description}</CustomListItem>
            </Text>
          </CustomStyledGridItem>
        ))}
      </ContentInfoContainer>
    </MainPageContainer>
  );
};

const ContentInfoContainer = styled(MainPageContainer)`
  background: ${({ theme }) => theme.palette.background.default};
  padding-left: ${({ theme }) => pagePaddingLeft(theme)};
  margin-top: 10px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;

const CustomListItem = styled(ListItem)`
  display: list-item;
  margin-bottom: 20px;
`;
const CustomStyledGridItem = styled(StyledGridItem)`
  margin-bottom: 10px;
`;
