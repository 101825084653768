export enum StartPage {
  Unknown = 'Unknown',
  Dashboard = 'Dashboard',
}

export interface UserProfile {
  userAccountProfileId: number;
  name: string | null;
}

export interface UserPermit {
  permitId: number;
  name: string | null;
  code: string | null;
}

export interface UserRecordFilterPermit {
  recordFilterId: number;
  code: string | null;
  name: string | null;
}

export interface UserReportPermit {
  file: string | null;
  title: string | null;
  description: string | null;
}

export interface User {
  userId: number;
  userName: string | null;
  fullName: string | null;
  locale: string | null;
  email: string | null;
  companyId: number;
  companyName: string | null;
  startPage: StartPage;
  defaultUserAccountProfileId: number | null;
  selectedUserAccountProfile: UserProfile;
  userPermits: UserPermit[];
  recordFilters: UserRecordFilterPermit[];
  reportPermits: UserReportPermit[];
  userAccountProfiles: UserProfile[];
  idleTimeoutInMinutes: string | null;
}

export interface HelpContext {
  message: string | null;
}

export enum Permit {
  CA_WP_VIEW = 'CA_WP_VIEW',
  CA_FNOL_VIEW = 'CA_FNOL_VIEW',
  CONNECTIONS_ADMIN = 'CONNECTIONS_ADMIN',
  CA_REPAIRERS_VIEW = 'CA_REPAIRERS_VIEW',
  CA_REPAIR_UPDATE_WP = 'CA_REPAIR_UPDATE_WP',
  CA_REPAIRERS_MANAGE = 'CA_REPAIRERS_MANAGE',
  CA_MESSAGE_QUEUES_VIEW = 'CA_MESSAGE_QUEUES_VIEW',
  CA_QUEUE_CONTENTS_VIEW = 'CA_QUEUE_CONTENTS_VIEW',
  CA_REPAIRER_CONNECTIONS = 'CA_REPAIRER_CONNECTIONS',
  CA_REPAIR_UPDATE_JOB_STATUS = 'CA_REPAIR_UPDATE_JOB_STATUS',
  CA_MAINTENANCE_MESSAGES_VIEW = 'CA_MAINTENANCE_MESSAGES_VIEW',
  CA_MAINTENANCE_MESSAGES_MANAGE = 'CA_MAINTENANCE_MESSAGES_MANAGE',
}

export enum SortOrder {
  Descending = 'Descending',
  Ascending = 'Ascending',
}

export interface StackErrorModal {
  ErrorCode: number;
  Message: string | null;
  OriginalMessage: string | null;
  CallStack: string | null;
}
export interface FNOLConfigErrorModal {
  error: string | null;
  isSuccess: boolean;
}

export interface Company {
  id: number;
  name: string | null;
  autoMainReference: boolean;
  lastMainReference: number;
  mainReferencePrefix: string | null;
  poP3Host: string | null;
  poP3Port: number | null;
  poP3UserName: string | null;
  poP3Password: string | null;
  poP3UseSSL: boolean | null;
  smtpHost: string | null;
  smtpPort: number | null;
  smtpReplyTo: string | null;
  smtpDisplayName: string | null;
  urlExtension: string | null;
  configPath: string | null;
  logTable: string | null;
  workStart: string | null;
  workEnd: string | null;
  locale: string | null;
  validSourceIP: string | null;
}
