import styled from '@emotion/styled';
import { BladeModal } from 'components/BladePopup';
import { GridItem } from 'components/Layout';
import { MainModalContainer, ModalTextSkeleton } from 'components/ModalLayout';
import { Text } from 'components/Typography';
import { useMessageQueuesXML } from 'pages/hooks';
import { useEffect } from 'react';
import { decode } from 'html-entities';
import XMLViewer from 'react-xml-viewer';
import { QueueType } from 'api/resources/models/AutoGenerated';

interface Props {
  id?: number;
  type?: QueueType;
  isOpen?: boolean;
  onClose: () => void;
}

export const XMLPopup = ({ isOpen = false, id, type, onClose }: Props) => {
  const { isMessageLoading, message, refetch } = useMessageQueuesXML(id, type);

  useEffect(() => {
    if (isOpen && !!id && !!type) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const decoded_xml = decode(
    decode(message?.messageContent, { level: 'xml' }),
    { level: 'xml' }
  );

  return (
    <BladeModal title={'Message Details'} isOpen={isOpen} onClose={onClose} disableScrollLock>
      <CustomMainModalContainer direction="column">
        <GridItem className="text">
          {isMessageLoading ? (
            <>
              {[...Array(20)].map((_i, idx) => (
                <CustomModalTextSkeleton
                  variant="rounded"
                  width={'100%'}
                  height={40}
                  key={idx}
                />
              ))}
            </>
          ) : (
            <CustomText>
              <XMLViewer
                collapsible
                xml={decoded_xml}
                invalidXml={decoded_xml}
                theme={{ overflowBreak: true }}
              />
            </CustomText>
          )}
        </GridItem>
      </CustomMainModalContainer>
    </BladeModal>
  );
};

const CustomModalTextSkeleton = styled(ModalTextSkeleton)`
  margin-bottom: 2px;
`;

const CustomText = styled(Text)`
  color: black;
  white-space: pre;
`;

const CustomMainModalContainer = styled(MainModalContainer)`
  width: 100%;
`;
