import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  LockOutlined,
  LockOpen,
  Close,
  AddCircleOutline,
  NotificationAddOutlined,
  DoneAll,
} from '@mui/icons-material';
import { UserPermit } from 'api/resources/models/User';
import { errorMessages } from 'api/resources/responseMessages/failure';
import {
  guidenceTextLock,
  guidenceTextReactivate,
  guidenceTextTerminate,
  guidenceTextUnlock,
} from 'api/resources/responseMessages/infoMessages';
import { successMessages } from 'api/resources/responseMessages/success';
import { AxiosError } from 'axios';
import { ActionPopup } from 'components/ActionPopup';
import { GridItem } from 'components/Layout';
import { GrayBackdrop, Loader } from 'components/Loader';
import {
  ActionButton,
  ActionText,
  InnerStatusContainer,
  Line,
  MainStatusContainer,
  StatusHeader,
} from 'components/PageStatusLayout';
import PrimaryToast from 'components/PrimaryToast';
import { ROUTES } from 'core/routes';
import {
  useLockRepairer,
  useReactivateRepairer,
  useTerminateRepairer,
  useUnlockRepairer,
} from 'pages/hooks';
import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ErrorTypes } from './types';
import {
  AdminRepairerConnections,
  AdminRepairerExternalCode,
} from 'api/resources/models/AutoGenerated';
import { enableConnectionList } from './constants';

export const Actions = ({
  siteCode,
  repairerId,
  externalCodes,
  repairerStatus,
  allConnections,
  accessInternalRepairer,
}: {
  repairerId: number;
  siteCode?: string | null;
  repairerStatus?: string | null;
  externalCodes?: AdminRepairerExternalCode[] | null;
  allConnections?: AdminRepairerConnections[] | null;
  accessInternalRepairer?: UserPermit;
}) => {
  const theme = useTheme();
  const navigation = useNavigate();
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });
  const [actionPopup, setActionPopup] = useState<{
    title: string;
    isOpen: boolean;
    guidence: string;
    dlgCheckbox: boolean;
    onSubmit?: ({
      ticketId,
      comment,
      forceRemovalFromDLG,
    }: {
      ticketId: string | null;
      comment: string | null;
      forceRemovalFromDLG?: boolean;
    }) => void;
  }>({
    title: '',
    guidence: '',
    isOpen: false,
    dlgCheckbox: false,
  });

  const { isLocking, lock } = useLockRepairer({
    onSuccess: () =>
      setToastState({
        message: successMessages.lockRepairer,
        isOpen: true,
        severity: 'success',
      }),
    onError: (error) => handleError(error, 'locked'),
  });
  const { unlock, isUnlocking } = useUnlockRepairer({
    onSuccess: () =>
      setToastState({
        message: successMessages.unlockRepairer,
        isOpen: true,
        severity: 'success',
      }),
    onError: (error) => handleError(error, 'unlocked'),
  });
  const { isTerminating, terminate } = useTerminateRepairer({
    onSuccess: () =>
      setToastState({
        message: successMessages.terminateRepairer,
        isOpen: true,
        severity: 'success',
      }),
    onError: (error) => handleError(error, 'terminated'),
  });
  const { isReactivating, reactivateRepairer } = useReactivateRepairer({
    onSuccess: () =>
      setToastState({
        message: successMessages.reactivateRepairer,
        isOpen: true,
        severity: 'success',
      }),
    onError: (error) => handleError(error, 'reactivate'),
  });

  const exisitngConnectionsList = (allConnections ?? []).filter((e) =>
    enableConnectionList.includes(e.wpMasterId)
  );

  const isBusy = isLocking || isUnlocking || isTerminating || isReactivating;

  return (
    <React.Fragment>
      <CustomMainStatusContainer md={8} xs={12} direction="column">
        <GrayBackdrop open={isBusy}>
          <Loader />
        </GrayBackdrop>

        <GridItem>
          <CustomInnerStatusContainer direction="column">
            <StatusHeader>Actions</StatusHeader>
            <GridItem>
              <ActionButton
                disabled={
                  repairerStatus == 'Locked' || repairerStatus == 'Terminated'
                }
                onClick={() =>
                  setActionPopup({
                    isOpen: true,
                    title: 'Lock Repairer',
                    guidence: guidenceTextLock,
                    dlgCheckbox: false,
                    onSubmit: (data) =>
                      lock({ ...data, repairerId: repairerId }),
                  })
                }
              >
                <ActionText>
                  <LockOutlined />
                  Lock
                </ActionText>
              </ActionButton>
            </GridItem>
            <GridItem>
              <Line color={theme.palette.contact.highlight} />
            </GridItem>
            <GridItem>
              <ActionButton
                disabled={
                  repairerStatus == 'Live' || repairerStatus == 'Terminated'
                }
                onClick={() =>
                  setActionPopup({
                    isOpen: true,
                    title: 'Unlock Repairer',
                    guidence: guidenceTextUnlock,
                    dlgCheckbox: false,
                    onSubmit: (data) =>
                      unlock({ ...data, repairerId: repairerId }),
                  })
                }
              >
                <ActionText>
                  <LockOpen />
                  Unlock
                </ActionText>
              </ActionButton>
            </GridItem>
            <GridItem>
              <Line color={theme.palette.contact.highlight} />
            </GridItem>
            {accessInternalRepairer && (
              <>
                <GridItem>
                  <ActionButton
                    disabled={repairerStatus == 'Terminated'}
                    onClick={() =>
                      setActionPopup({
                        isOpen: true,
                        title: 'Terminate Repairer',
                        dlgCheckbox: true,
                        guidence: guidenceTextTerminate,
                        onSubmit: (data) =>
                          terminate({
                            repairerId: repairerId,
                            comment: data.comment,
                            forceRemovalFromDLG:
                              data.forceRemovalFromDLG ?? false,
                            ticketId: data.ticketId,
                          }),
                      })
                    }
                  >
                    <ActionText>
                      <Close />
                      Terminate
                    </ActionText>
                  </ActionButton>
                </GridItem>
                <GridItem>
                  <Line color={theme.palette.contact.highlight} />
                </GridItem>
                <GridItem>
                  <ActionButton
                    disabled={repairerStatus != 'Terminated'}
                    onClick={() =>
                      setActionPopup({
                        isOpen: true,
                        title: 'Reactivate Repairer',
                        dlgCheckbox: false,
                        guidence: guidenceTextReactivate,
                        onSubmit: (data) =>
                          reactivateRepairer({
                            repairerId: repairerId,
                            comment: data.comment,
                            ticketId: data.ticketId,
                          }),
                      })
                    }
                  >
                    <ActionText>
                      <DoneAll />
                      Reactivate
                    </ActionText>
                  </ActionButton>
                </GridItem>
                <GridItem>
                  <Line color={theme.palette.contact.highlight} />
                </GridItem>
                <GridItem>
                  <ActionButton
                    disabled={
                      repairerStatus == 'Terminated' ||
                      repairerStatus == 'Locked'
                    }
                    onClick={navigateToConnection}
                  >
                    <ActionText>
                      <AddCircleOutline />
                      Add Connection
                    </ActionText>
                  </ActionButton>
                </GridItem>
                <GridItem>
                  <Line color={theme.palette.contact.highlight} />
                </GridItem>
                <GridItem>
                  <ActionButton
                    disabled={
                      repairerStatus == 'Terminated' ||
                      repairerStatus == 'Locked' ||
                      exisitngConnectionsList?.length <= 0
                    }
                    onClick={navigateToFnol}
                  >
                    <ActionText>
                      <NotificationAddOutlined />
                      Add FNOL Configuration
                    </ActionText>
                  </ActionButton>
                </GridItem>
              </>
            )}
          </CustomInnerStatusContainer>
        </GridItem>
      </CustomMainStatusContainer>
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
      <ActionPopup
        title={actionPopup.title}
        isOpen={actionPopup.isOpen}
        guidence={actionPopup.guidence}
        onSubmit={actionPopup.onSubmit}
        DLGCheckbox={actionPopup.dlgCheckbox}
        onClose={() =>
          setActionPopup({
            title: '',
            guidence: '',
            isOpen: false,
            dlgCheckbox: false,
          })
        }
      />
    </React.Fragment>
  );

  function navigateToConnection() {
    const path = generatePath(ROUTES.addConnection, {
      repairerId,
      siteCode,
    });
    navigation(path);
  }

  function navigateToFnol() {
    const path = generatePath(ROUTES.addFnol, {
      repairerId,
      siteCode,
      externalCodes:
        externalCodes && externalCodes?.length > 0
          ? externalCodes
              ?.map(
                ({ externalSystem, externalCode }) =>
                  `${externalSystem}:${externalCode}`
              )
              .join(', ')
          : 'null',
    });
    navigation(path);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(_error: AxiosError, type: ErrorTypes) {
    setToastState({
      message:
        type == 'locked'
          ? errorMessages.lockRepairers
          : type == 'terminated'
          ? errorMessages.terminatedRepairers
          : type == 'reactivate'
          ? errorMessages.reactivateRepairer
          : type == 'unlocked'
          ? errorMessages.unlockRepairers
          : errorMessages.addConnection,
      isOpen: true,
      severity: 'error',
    });
  }
};

const CustomMainStatusContainer = styled(MainStatusContainer)`
  display: flex;
  height: 100%;
`;
const CustomInnerStatusContainer = styled(InnerStatusContainer)`
  flex: 1;
`;
