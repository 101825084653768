import { Permit, SortOrder } from 'api/resources/models/User';
import { DataGrid, getSortObject } from 'components/DataGrid';
import InfoText from 'components/InfoText';
import {
  MainPageContainer,
  StyledGridItem,
  UpperInfoContainer,
} from 'components/PageLayout';
import { SortObject } from 'components/types';
import { Heading } from 'components/Typography';
import { createRepairersURL, ROUTES } from 'core/routes';
import { orderBy } from 'lodash';
import { useRepairers, useUser } from 'pages/hooks';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import MobileBottomButton, {
  CreateButton,
  PrimaryButton,
} from 'components/Button';
import { Search } from '@mui/icons-material';
import { Container, GridItem } from 'components/Layout';
import { DesktopContainer } from 'core/Theming/Device';
import { repairersHeaderTable, useRepairersParams } from './types';
import PrimaryToast from 'components/PrimaryToast';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { TextField } from 'components/TextField';
import { RepairerSearchModel } from 'api/resources/models/AutoGenerated';
import { StatusChip } from 'components/StatusChip';
import { useTheme } from '@emotion/react';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import { usePageData } from 'core/PageData';

const CREATE_REPAIRER_PERMISSION = Permit.CA_REPAIRERS_MANAGE;

export function Repairers() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = useRepairersParams(location.search);

  const { user, isLoading, isFetching } = useUser();
  const { isRepairersLoading, repairers, refetch } = useRepairers(
    urlParams.searchValue,
    handleError
  );

  const { setPageData, setPreviousUrl } = usePageData();
  useEffect(() => {
    setPageData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createRepairerPermission = useMemo(
    () =>
      user?.userPermits?.find(
        (permit) => permit.code == CREATE_REPAIRER_PERMISSION
      ),
    [user]
  );

  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });
  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sortString ?? 'repairerName',
    sortOrder: urlParams.sortOrder ?? SortOrder.Ascending,
  });
  const [sortedRepairers, setSortedRepairers] = useState(repairers);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  useEffect(() => {
    setSortedRepairers(sortMessagesQueue(sortObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repairers, currentPage, itemsPerPage]);

  useEffect(() => {
    navigateToRepairers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject]);

  const {
    values: { searchValue },
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      searchValue: urlParams.searchValue || '',
    },
    onSubmit: () => {
      navigateToRepairers();
    },
  });
  const isEnabled = !!searchValue;
  const isBusy = isLoading || isFetching || isRepairersLoading;

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <form onSubmit={handleSubmit}>
        <UpperInfoContainer direction="column">
          <StyledGridItem>
            <Heading className="subheading">Repairers</Heading>
          </StyledGridItem>

          <CustomContainer direction="row">
            <StyledGridItem md={4} sm={4} xs={12}>
              <TextInput
                name="searchValue"
                variant="outlined"
                className="searchbox"
                value={searchValue}
                onChange={handleChange}
                placeholder="Search repairers"
              />
              <SearchButton type="submit" disabled={!isEnabled}>
                <SearchIcon />
                Search
              </SearchButton>
            </StyledGridItem>
            <DesktopContainer>
              <GridItem md={8}>
                {createRepairerPermission && renderCreateButton()}
              </GridItem>
            </DesktopContainer>
          </CustomContainer>
        </UpperInfoContainer>
      </form>
      {!isBusy && repairers?.length === 0 && (
        <InfoText>{errorMessages.noRepairersFound(searchValue)}</InfoText>
      )}
      <DataGrid
        onSort={sort}
        isLoading={isBusy}
        data={sortedRepairers}
        sortObject={sortObject}
        onRowClick={handleRowClick}
        headers={repairersHeaderTable}
        customCellRender={renderCustomCell}
        currentPage={currentPage}
        totalCount={repairers?.length}
        onPageChange={(page) => setCurrentPage(page)}
        onItemsPerPageChange={setItemsPerPage}
      />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
      <MobileBottomButton>{renderCreateButton()}</MobileBottomButton>
    </MainPageContainer>
  );

  function handleRowClick(row: RepairerSearchModel) {
    if (searchValue) {
      setPreviousUrl(createRepairersURL(
        sortObject.sortString ?? undefined,
        sortObject.sortOrder,
        searchValue
      ))
    }
    
    const route = generatePath(ROUTES.repairerEdit, {
      repairerId: row.repairerId,
    });
    navigate(route);
  }

  function renderCustomCell({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof RepairerSearchModel;
    row: RepairerSearchModel;
  }) {
    if (headerId == 'status' && row.status) {
      if (row.status?.toLowerCase() == 'live')
        return (
          <StatusChip
            status="live"
            description="LIVE"
            color={theme.palette.statusChip.live}
          />
        );
      else if (row.status?.toLowerCase() == 'terminated')
        return (
          <StatusChip
            status="terminated"
            description="TERMINATED"
            color={theme.palette.statusChip.terminated}
          />
        );
      else if (row.status?.toLowerCase() == 'locked')
        return (
          <StatusChip
            status="locked"
            description="LOCKED"
            color={theme.palette.statusChip.locked}
          />
        );
      else return <>{row.status}</>;
    }
    return baseRender();
  }

  function renderCreateButton() {
    return (
      <CreateButton text="Create Repairer" route={ROUTES.repairerCreate} />
    );
  }

  function sortMessagesQueue(sortObj: SortObject) {
    const currentListed = currentPage * itemsPerPage;
    const orderedList = orderBy(
      repairers,
      sortObj.sortString,
      sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
    );
    return orderedList?.slice(currentListed, currentListed + itemsPerPage);
  }

  function sort(sortValue: keyof RepairerSearchModel) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedRepairers(sortMessagesQueue(sortObj));
      return sortObj;
    });
  }

  function navigateToRepairers() {
    if (searchValue)
      navigate(
        createRepairersURL(
          sortObject.sortString ?? undefined,
          sortObject.sortOrder,
          searchValue
        ),
        { replace: true }
      );
    if (urlParams.searchValue == searchValue) refetch();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(_error: AxiosError) {
    setToastState({
      message: errorMessages.repairers,
      isOpen: true,
      severity: 'error',
    });
  }
}

const TextInput = styled(TextField)`
  width: 260px;
  margin-right: 8px;

  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
    overflow: hidden;
  }
`;

const SearchButton = styled(PrimaryButton)`
  width: 99px;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  padding-right: 10px;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
  }
`;

const CustomContainer = styled(Container)`
  display: flex;
  align-items: flex-end;
`;

const SearchIcon = styled(Search)`
  height: 17.49px;
  width: 17.49px;
  margin-right: 10px;
`;
