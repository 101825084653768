import { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface ActiveClass {
  activeClassName: string;
}

export const CustomNavLink = forwardRef<
  HTMLAnchorElement,
  NavLinkProps & ActiveClass
>((props, ref) => (
  <NavLink
    ref={ref}
    to={props.to}
    className={({ isActive }) =>
      `${props.className} ${isActive ? props.activeClassName : ''}`
    }
  >
    {props.children}
  </NavLink>
));

CustomNavLink.displayName = 'CustomNavLink';
