import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from './routes';
import { useUser } from 'pages/hooks';
import { Permit } from 'api/resources/models/User';

interface Props {
  anyOfPermits?: Permit[];
}

export default function RouteWithPermit({ anyOfPermits }: Props) {
  const { user, isFetching, isLoading } = useUser();

  const isBusy = isFetching || isLoading;

  const permitAllowed = user?.userPermits.some((userPermit) =>
    anyOfPermits?.some((permit) => permit === userPermit.code)
  );

  if (isBusy) return null;

  return permitAllowed ? <Outlet /> : <Navigate to={ROUTES.dashboard} />;
}
