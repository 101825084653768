import { api } from 'api';
import { config } from 'core/config';
import {
  AdminAddExternalConfigurationModel,
  AdminAddRepairerConnectionModel,
  AdminLockRepairerModel,
  AdminReactivateRepairerModel,
  AdminRemoveRepairerConnectionModel,
  AdminRepairerConnections,
  AdminRepairerConnectionSettingModel,
  AdminRepairerDetails,
  AdminRepairerModel,
  AdminTerminateRepairerModel,
  RepairerSearchModel,
} from './models/AutoGenerated';
import { repairerInfo, WorkProviders } from './models/mock/data';
import { FnolConfigs, FnolConfigsProps } from './types';

const showMock = config.showMock;
const repairerBaseUrl = '/api/AdminRepairer';
const timeout = 60000;

export const createRepairer = (data: AdminRepairerModel) =>
  api
    .post(`${config.connectionsAdminApiUrl}${repairerBaseUrl}/create`, data)
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));

export const editRepairer = (data: AdminRepairerModel) =>
  api
    .patch(`${config.connectionsAdminApiUrl}${repairerBaseUrl}/update`, data)
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));

export const getRepairers = (
  searchValue?: string | null
): Promise<RepairerSearchModel[]> =>
  api
    .get(`${config.connectionsAdminApiUrl}${repairerBaseUrl}/search`, {
      params: { searchValue },
    })
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));

interface RepairerProps {
  repairerId?: number | null;
}

export const getRepairer = ({
  repairerId,
}: RepairerProps): Promise<AdminRepairerDetails> =>
  api
    .get(`${config.connectionsAdminApiUrl}${repairerBaseUrl}/details`, {
      params: { repairerId },
    })
    .then((result) => result.data)
    .catch((error) => {
      if (showMock) return repairerInfo;
      else Promise.reject(error);
    });

interface ConnectionSettingsProps {
  workProviderId?: number | null;
  repairerId?: number | null;
  category?: string | null;
}

export const getConnectionSettings = ({
  workProviderId,
  repairerId,
  category,
}: ConnectionSettingsProps): Promise<AdminRepairerConnectionSettingModel> =>
  api
    .get(`${config.connectionsAdminApiUrl}${repairerBaseUrl}/connectionSettings`, {
      params: { workProviderId, repairerId, category },
    })
    .then((result) => result.data)
    .catch((error) => { Promise.reject(error)});

export const lockRepairer = (data: AdminLockRepairerModel) =>
  api
    .patch(`${config.connectionsAdminApiUrl}${repairerBaseUrl}/lock`, data)
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));

export const unlockRepairer = (data: AdminLockRepairerModel) =>
  api
    .patch(`${config.connectionsAdminApiUrl}${repairerBaseUrl}/unlock`, data)
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));

export const terminateRepairer = (data: AdminTerminateRepairerModel) =>
  api
    .post(`${config.connectionsAdminApiUrl}${repairerBaseUrl}/terminate`, data)
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));

export const removeConnection = ({
  workProviderID,
  repairerId,
  ticketId,
  comment,
}: AdminRemoveRepairerConnectionModel) =>
  api
    .post(
      `${config.connectionsAdminApiUrl}${repairerBaseUrl}/removeConnection`,
      { ticketId, comment, repairerId, workProviderID }
    )
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));

export const getFnolConfigs = ({
  workProviderID,
  repairerId,
}: FnolConfigsProps): Promise<FnolConfigs[]> =>
  api
    .get(
      `${config.connectionsAdminApiUrl}${repairerBaseUrl}/fnolconfigs`,
      {
        params: { workProviderID, repairerId }
      }
    )
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));

export const getRepairerWorkProviders = (): Promise<
  AdminRepairerConnectionSettingModel[]
> =>
  api
    .get(`${config.connectionsAdminApiUrl}${repairerBaseUrl}/workProviders`)
    .then((result) => result.data)
    .catch((error) => {
      if (showMock) return WorkProviders;
      Promise.reject(error);
    });
export const getAvailableWorkProviders = (
  siteCode: string
): Promise<AdminRepairerConnections[]> =>
  api
    .get(
      `${config.connectionsAdminApiUrl}${repairerBaseUrl}/availableconnections`,
      { params: { siteCode } }
    )
    .then((result) => result.data)
    .catch((error) => {
      if (showMock) return WorkProviders;
      Promise.reject(error);
    });

export const addConnection = (data: AdminAddRepairerConnectionModel) =>
  api
    .post(
      `${config.connectionsAdminApiUrl}${repairerBaseUrl}/addConnection`,
      data,
      { timeout }
    )
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));

export const addFnol = (data: AdminAddExternalConfigurationModel) =>
  api
    .post(
      `${config.connectionsAdminApiUrl}${repairerBaseUrl}/addFNOLConfiguration`,
      data
    )
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));

export const reactivateRepairer = (data: AdminReactivateRepairerModel) =>
  api
    .post(
      `${config.connectionsAdminApiUrl}${repairerBaseUrl}/ReactivateRepairer`,
      data
    )
    .then((result) => result.data)
    .catch((error) => Promise.reject(error));
