import { logger } from 'core/logger';
import store from 'core/store';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'core/logger/ApplicationInsightsService';

const apiConfig: AxiosRequestConfig = {
  baseURL: '/',
  timeout: 45000,
  validateStatus: (statusCode) => statusCode >= 200 && statusCode < 300,
};

const api = axios.create(apiConfig);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    logger?.error(error);
    appInsights?.trackException({ error: new Error(`Error: ${error}`), severityLevel: SeverityLevel.Error });
    return Promise.reject(error);
  }
);

api.interceptors.request.use(async function (config) {
  const accessToken = store.getState().auth.user?.accessToken;
  const activeUserProfileId = store.getState().auth.user?.activeUserProfileId;

  if (!accessToken || !config.headers) {
    return config;
  }

  if (config.headers.Authorization) {
    const infoMessage = `Authorization header was already set for request '${config.url}'. This is most likely an error.`
    logger?.warn(infoMessage);
    appInsights?.trackException({ error: new Error(infoMessage), severityLevel: SeverityLevel.Warning });
  } else {
    config.headers.Authorization = `Bearer ${accessToken}`;
    if (activeUserProfileId) {
      config.headers['Active-User-Profile'] = activeUserProfileId;
    }
  }

  return config;
});

export { api };

export function getBadRequestErrorMessage(error: AxiosError) {
  if (error.code == 'ECONNABORTED')
    return 'Oops... Something went wrong. Please try again in a couple of minutes';
  return error.isAxiosError &&
    error.response?.status &&
    error.response?.status >= 400 &&
    error.response?.status <= 500
    ? error.response?.data
    : 'Oops... Something went wrong. Please try again in a couple of minutes';
}
