import { LogLevelDesc, LogLevelNumbers } from 'loglevel';

type AllowedLogLevel = Exclude<LogLevelDesc, LogLevelNumbers>;
type LogLevelObjectType = { [key in AllowedLogLevel]: string };

const logLevelObject: LogLevelObjectType = {
  trace: '',
  debug: '',
  info: '',
  warn: '',
  error: '',
  silent: '',
  TRACE: '',
  DEBUG: '',
  INFO: '',
  WARN: '',
  ERROR: '',
  SILENT: '',
};

const isAssignable = (type: string): type is AllowedLogLevel => {
  return type in logLevelObject;
};

const logLevelValue =
  process.env.NODE_ENV === 'test'
    ? 'SILENT'
    : process.env.REACT_APP_DATADOG_LOG_LEVEL;

if (!logLevelValue || !isAssignable(logLevelValue)) {
  throw new Error(
    `Provided logLevel: ${logLevelValue} is not supported. Allowed values are: ${Object.keys(
      logLevelObject
    ).join(', ')}`
  );
}

export const config = {
  clientApiUrl: process.env.REACT_APP_ACTIVEWEB_API_URL,
  connectionsAdminApiUrl: process.env.REACT_APP_CA_API_URL,
  authority: process.env.REACT_APP_AUTH_AUTHORITY,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  silentSigninUri: process.env.REACT_APP_AUTH_SILENT_SIGNIN_URI,
  responseType: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
  scope: process.env.REACT_APP_AUTH_SCOPE,
  postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  loggingEnvironment: process.env.REACT_APP_LOGGING_ENVIRONMENT,
  datadogApplicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  datadogSite: process.env.REACT_APP_DATADOG_SITE,
  datadogService: process.env.REACT_APP_DATADOG_SERVICE,
  datadogLogLevel: logLevelValue,
  useDataDogLogs: process.env.REACT_APP_USE_DATADOG_LOGS?.toString(),
  appInsightsConnectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
  useAppInsightsConnectionLogs: process.env.REACT_APP_USE_INSIGHTS_CONNECTION_LOGS?.toString(),
  appInsightsDDService: process.env.REACT_APP_INSIGHTS_DD_SERVICE,
  allRepairsFilterValue: 12,
  totalIdleTime: process.env.REACT_APP_IDLE_TIME || 15,
  popupLogoutTime: process.env.REACT_APP_POPUP_LOGOUT_TIME || 2,
  showMock:
    process.env.REACT_APP_LOGGING_ENVIRONMENT == 'dev' ||
    process.env.REACT_APP_LOGGING_ENVIRONMENT == 'stg'
      ? process.env.REACT_APP_SHOW_MOCK
      : false,
};
