import { api } from 'api';
import { config } from 'core/config';
import { AdminMaintenanceMessageModel } from './models/AutoGenerated';
import { MaintenanceMessagesMock } from './models/mock/data';
import { Company } from './models/User';

const showMock = config.showMock;
const companiesUrl = '/api/companies';
const maintenanceMessagesBaseUrl = '/api/adminmaintenancemessage';

export const getMaintenanceMessages = async (
  startDate: string | null,
  endDate: string | null,
  includeDeleted: string | null
): Promise<AdminMaintenanceMessageModel[]> =>
  api
    .get(
      `${config.connectionsAdminApiUrl}${maintenanceMessagesBaseUrl}/messages`,
      { params: { startDate, endDate, includeDeleted } }
    )
    .then((result) => result.data)
    .catch((error) => {
      if (showMock) return MaintenanceMessagesMock;
      else Promise.reject(error);
    });

export const getMaintenanceMessage = (
  messageId?: number | null
): Promise<AdminMaintenanceMessageModel> =>
  api
    .get(
      `${config.connectionsAdminApiUrl}${maintenanceMessagesBaseUrl}/${messageId}`
    )
    .then((result) => result.data)
    .catch((error) => {
      if (showMock) return MaintenanceMessagesMock[messageId || 0];
      else Promise.reject(error);
    });

export const createMaintenanceMessages = (
  message: AdminMaintenanceMessageModel
) =>
  api
    .post(
      `${config.connectionsAdminApiUrl}${maintenanceMessagesBaseUrl}`,
      message
    )
    .then((response) => response.data);

export const updateMaintenanceMessages = (
  message: AdminMaintenanceMessageModel
) =>
  api
    .patch(
      `${config.connectionsAdminApiUrl}${maintenanceMessagesBaseUrl}`,
      message
    )
    .then((response) => response.data);

export const removeMaintenanceMessage = (messageId: number) =>
  api.patch(
    `${config.connectionsAdminApiUrl}${maintenanceMessagesBaseUrl}/delete/${messageId}`
  );

export const getCompanies = (): Promise<Company[]> =>
  api
    .get(`${config.clientApiUrl}${companiesUrl}`)
    .then((response) => response.data);
