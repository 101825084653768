import { Fragment } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

export interface ToastProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  duration?: number;
  severity: 'error' | 'warning' | 'success' | 'info';
  position?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
}

export function Toast({
  isOpen,
  onClose,
  message,
  duration,
  severity,
  position = {
    vertical: 'bottom',
    horizontal: 'center',
  },
}: ToastProps) {
  return (
    <Snackbar
      open={isOpen}
      onClose={() => severity != 'error' && onClose()}
      message={message}
      anchorOrigin={position}
      autoHideDuration={duration}
      action={
        <Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Fragment>
      }
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
