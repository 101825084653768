import styled from '@emotion/styled';
import { getBadRequestErrorMessage } from 'api';
import {
  AdminRepairerModel,
  CreateRepairerResponse,
} from 'api/resources/models/AutoGenerated';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { guidenceTextDetails } from 'api/resources/responseMessages/infoMessages';
import { successMessages } from 'api/resources/responseMessages/success';
import { AxiosError } from 'axios';
import { CreateRepairerActionPopup } from 'components/CreateEditRepairerActionPopup';
import { GridItem } from 'components/Layout';
import { pagePaddingLeft } from 'components/PageLayout';
import PrimaryToast from 'components/PrimaryToast';
import { ROUTES } from 'core/routes';
import { useCreateRepairer } from 'pages/hooks';
import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { RepairDetailsForm } from '../RepairerDetailsForm';
import { ActionPopupSubmit } from '../types';

export function CreateRepairerDetails() {
  const navigate = useNavigate();
  const { createRepairer, isCreating } = useCreateRepairer();

  const [createdProfileId, setCreatedProfileId] = useState<number | null>(null);
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });
  const [actionPopup, setActionPopup] = useState<{
    title: string;
    guidence: string | null;
    isOpen: boolean;
    onSubmit?: (data: ActionPopupSubmit) => void;
  }>({
    title: '',
    guidence: '',
    isOpen: false,
  });

  return (
    <React.Fragment>
      <CustomGridItem md={6} sm={6} xs={12}>
        <RepairDetailsForm
          onSubmit={(data) =>
            setActionPopup({
              isOpen: true,
              title: 'Details',
              guidence: guidenceTextDetails,
              onSubmit: (popupData) =>
                onSubmit({
                  ...data,
                  ...popupData,
                  repairerId: 0,
                  coveaCode: '',
                  systemType: data.systemType?.value ?? '',
                }),
            })
          }
          isBusy={isCreating}
          hasPermission={true}
        />
      </CustomGridItem>
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => {
          setToastState({ ...toastState, isOpen: false });
          if (createdProfileId) {
            const route = generatePath(ROUTES.repairerEdit, {
              repairerId: createdProfileId,
            });
            navigate(route, { replace: true });
          }
        }}
        severity={toastState.severity}
      />
      <CreateRepairerActionPopup
        title={actionPopup.title}
        isOpen={actionPopup.isOpen}
        guidence={actionPopup.guidence}
        onSubmit={actionPopup.onSubmit}
        onClose={() =>
          setActionPopup({
            title: '',
            guidence: '',
            isOpen: false,
          })
        }
      />
    </React.Fragment>
  );

  function onSubmit(data: AdminRepairerModel) {
    createRepairer?.(data)
      .then((e) => {
        setCreatedProfileId(e.id);
        setToastState({
          message: successMessages.createRepairerDetail,
          isOpen: true,
          severity: 'success',
        });
      })
      .catch((error: AxiosError) => handleError(error));
  }

  function handleError(error: AxiosError) {
    const errorData = getBadRequestErrorMessage(error);
    const errorMessage = (errorData as CreateRepairerResponse)?.message;
    setToastState({
      message: errorMessage || errorMessages.createRepairers,
      isOpen: true,
      severity: 'error',
    });
  }
}

export const CustomGridItem = styled(GridItem)`
  padding-left: ${({ theme }) => pagePaddingLeft(theme)};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;
