import { Permit, SortOrder } from 'api/resources/models/User';
import {
  NotificationDetailQueue,
  QueueType,
} from 'api/resources/models/AutoGenerated';
import { DataGrid, getSortObject } from 'components/DataGrid';
import InfoText from 'components/InfoText';
import {
  MainPageContainer,
  StyledGridItem,
  UpperInfoContainer,
} from 'components/PageLayout';
import { SortObject } from 'components/types';
import { Heading } from 'components/Typography';
import { createMessageQueueURL } from 'core/routes';
import { orderBy } from 'lodash';
import { useMessageArchives, useMessageQueues, useUser } from 'pages/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDateTime } from 'utils/helpers';
import { messageTableHeader, useMessageQueueParams } from './types';
import styled from '@emotion/styled';
import { TextField } from 'components/TextField';
import { useFormik } from 'formik';
import { PrimaryButton } from 'components/Button';
import { Search } from '@mui/icons-material';
import { XMLPopup } from './XMLPopup';
import PrimaryToast from 'components/PrimaryToast';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { useRowCountPerPage } from 'components/Pagination/hooks';

const XML_VIEW_PERMISSION = Permit.CA_QUEUE_CONTENTS_VIEW;

export function MessagesQueuePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = useMessageQueueParams(location.search);

  const { user, isLoading, isFetching } = useUser();
  const { messages, isMessagesLoading, refetch } = useMessageQueues(
    urlParams.searchValue,
    handleError
  );
  const XMLVIEWPERMISSION = useMemo(
    () =>
      user?.userPermits?.find((permit) => permit.code == XML_VIEW_PERMISSION),
    [user]
  );

  const [totalCount, setTotalCount] = useState<number>(0);

  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sortString ?? '',
    sortOrder: urlParams.sortOrder ?? undefined,
  });
  const [sortedMessagesQueue, setSortedMessagesQueue] = useState(messages);
  const [showBladePopup, setShowBladePopup] = useState<{
    show: boolean;
    id: number;
    type: QueueType;
  } | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  const [archiveFetchDisabled, setArchiveFetchDisabled] = useState<boolean>(false);

  const {
    values: { searchValue },
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      searchValue: urlParams.searchValue || '',
    },
    onSubmit: () => {
      if ( searchValue !== urlParams.searchValue) {
        setArchiveFetchDisabled(false)
      }
      setCurrentPage(0);
      navigateToMessageQueues();
    },
  });

  const { messageArchives, refetchMessageArchives } = useMessageArchives(
    urlParams.searchValue ?? '',
    handleMessageArchivesError
  );

  const fetchFromArchive = () => {
    setArchiveFetchDisabled(true);
    refetchMessageArchives();
  };

  useEffect(() => {
    setSortedMessagesQueue(sortMessagesQueue(sortObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, currentPage, itemsPerPage, messageArchives]);

  useEffect(() => {
    navigateToMessageQueues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject]);

  const isSearchEnabled = !!searchValue;
  const isBusy = isLoading || isFetching || isMessagesLoading;

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <form onSubmit={handleSubmit}>
        <UpperInfoContainer direction="column">
          <StyledGridItem>
            <Heading className="subheading">Message Queues</Heading>
          </StyledGridItem>
          <StyledGridItem>
            <TextInput
              name="searchValue"
              value={searchValue}
              onChange={handleChange}
              placeholder="Search for Repair Traffic"
            />
            <SearchButton type="submit" disabled={!isSearchEnabled}>
              <SearchIcon />
              Search
            </SearchButton>
            {sortedMessagesQueue?.length !== 0 ? (
              <FetchArchiveButton
                onClick={fetchFromArchive}
                disabled={archiveFetchDisabled || urlParams.searchValue !== searchValue}
              >
                <SearchIcon />
                Fetch from archive
              </FetchArchiveButton>
            ) : null}
          </StyledGridItem>
        </UpperInfoContainer>
        {!isBusy && messages?.length === 0 && (
          <InfoText>{errorMessages.noMessagesFound}</InfoText>
        )}
        <DataGrid
          headers={messageTableHeader}
          data={sortedMessagesQueue}
          isLoading={isBusy}
          onRowClick={handleMessageClick}
          onSort={sort}
          sortObject={sortObject}
          customCellRender={renderCustomCell}
          currentPage={currentPage}
          totalCount={totalCount}
          onPageChange={(page) => setCurrentPage(page)}
          onItemsPerPageChange={setItemsPerPage}
        />
      </form>
      <XMLPopup
        id={showBladePopup?.id}
        type={showBladePopup?.type}
        isOpen={showBladePopup?.show}
        onClose={() => setShowBladePopup(null)}
      />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
    </MainPageContainer>
  );

  function renderCustomCell({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof NotificationDetailQueue;
    row: NotificationDetailQueue;
  }) {
    if (headerId == 'readDate' && row.readDate)
      return <>{formatDateTime(row.readDate)}</>;
    if (headerId == 'receivedDate' && row.receivedDate)
      return <>{formatDateTime(row.receivedDate)}</>;
    return baseRender();
  }

  function handleMessageClick(row: NotificationDetailQueue) {
    if (XMLVIEWPERMISSION && row.id && row.queue && row.messageType)
      setShowBladePopup({
        show: true,
        id: row.id,
        type: row.queue,
      });
  }

  function sortMessagesQueue(sortObj: SortObject) {
    const currentListed = currentPage * itemsPerPage;
    const archiveMessages = messages && messages?.length > 0 ? messageArchives : []
    const messagesData = [...messages ?? [], ...archiveMessages ?? []]
    const orderedList = orderBy(
      messagesData,
      sortObj.sortString,
      sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
    );
    setTotalCount(orderedList?.length ?? 0)

    return orderedList?.slice(currentListed, currentListed + itemsPerPage);
  }

  function sort(sortValue: keyof NotificationDetailQueue) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedMessagesQueue(sortMessagesQueue(sortObj));
      return sortObj;
    });
  }

  function navigateToMessageQueues() {
    navigate(
      createMessageQueueURL(
        sortObject.sortString ?? undefined,
        sortObject.sortOrder,
        searchValue
      ),
      { replace: true }
    );
    if (urlParams.searchValue && urlParams.searchValue == searchValue)
      refetch();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(error: AxiosError) {
    setToastState({
      message: errorMessages.messageQueues,
      isOpen: true,
      severity: 'error',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleMessageArchivesError(error: AxiosError) {
    setToastState({
      message: errorMessages.archives,
      isOpen: true,
      severity: 'error',
    });
  }
}

const TextInput = styled(TextField)`
  width: 260px;
  margin-right: 8px;

  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
    overflow: hidden;
  }
`;

const SearchButton = styled(PrimaryButton)`
  width: 99px;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  padding-right: 10px;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
  }
`;

const SearchIcon = styled(Search)`
  height: 17.49px;
  width: 17.49px;
  margin-right: 10px;
`;

const FetchArchiveButton = styled(PrimaryButton)`
  width: 200px;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  padding-right: 10px;
  margin-left: 10px;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
  }
`;