import { RepairerSearchModel } from 'api/resources/models/AutoGenerated';
import { SortOrder } from 'api/resources/models/User';

export interface RepairersHeader {
  id: keyof RepairerSearchModel;
  label: string;
}

export const repairersHeaderTable: RepairersHeader[] = [
  { id: 'repairerId', label: 'Repairer ID' },
  { id: 'repairerName', label: 'Repairer Name' },
  { id: 'siteCode', label: 'Sitecode' },
  { id: 'status', label: 'Status' },
];

export const useRepairersParams = (url: string) => {
  const urlParams = new URLSearchParams(url);

  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  const searchValue = urlParams.get('searchValue');
  return {
    sortString: sort as keyof RepairerSearchModel,
    sortOrder: Object.values(SortOrder).includes(sortOrder) ? sortOrder : null,
    searchValue,
  };
};
