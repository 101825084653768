import darkLogo from 'assets/darkLogo.png';
import logo from 'assets/Logofull.png';
import styled from '@emotion/styled/macro';
import { UserMenuContainer } from 'components/UserMenu';
import { useAppSelector } from 'core/store';
import { ReactComponent as BurgerIcon } from 'assets/BurgerIcon.svg';
import { GridItem } from 'components/Layout';
import { MobileMenu } from 'components/Menu/MobileMenu';
import { UserPermit } from 'api/resources/models/User';
import { useContext, useEffect } from 'react';
import { ScrollContext } from 'core/ScrollContext';
import { useInMobile } from 'core/Theming/Device';

interface Props {
  userPermits: UserPermit[];
  isOpen: boolean;
  setOpen: () => void;
}

export function MainHeader({ isOpen, setOpen, userPermits }: Props) {
  const isMobile = useInMobile();
  const scrollPosition = useContext(ScrollContext);
  const isHeaderFixed = scrollPosition !== undefined;
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (isOpen && isMobile) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [isOpen, isMobile]);

  return (
    <>
      {isHeaderFixed ? (
        <MainContainer>
          <FixedHeader
            style={{ transform: `translateY(${-scrollPosition!}px)` }}
            isOpen={isOpen}
          >
            {renderHeaderMenu()}
          </FixedHeader>
        </MainContainer>
      ) : (
        <RelativeHeader isOpen={isOpen}>{renderHeaderMenu()}</RelativeHeader>
      )}
    </>
  );

  function renderHeaderMenu() {
    return (
      <>
        <MenuItem isOpen={isOpen} onClick={setOpen}>
          <BurgerIcon />
          Menu
        </MenuItem>
        <div className="logo">
          <img className="img" src={isOpen && isMobile ? logo : darkLogo} />
        </div>
        <TitleEnvironment>
          {process.env.REACT_APP_LOGGING_ENVIRONMENT === 'dev' && (<text> - DEVELOPMENT </text>)}
          {process.env.REACT_APP_LOGGING_ENVIRONMENT === 'stg' && (<text> - STAGING </text>)}
        </TitleEnvironment>
        {user ? <UserMenuContainer /> : null}
        {isOpen ? (
          <MobileMenu userPermits={userPermits} setOpen={setOpen} />
        ) : null}
      </>
    );
  }
}

const MenuItem = styled(GridItem)<{ isOpen: boolean }>`
  display: none;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xs};

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    display: flex;
    & svg {
      margin-right: 5px;
      path {
        fill: ${({ theme, isOpen }) =>
          isOpen
            ? theme.palette.primary.activeText
            : theme.palette.primary.main};
      }
    }

    color: ${({ theme, isOpen }) =>
      isOpen ? theme.palette.primary.activeText : theme.palette.primary.main};
  }
`;

const Header = styled('header')<{ isOpen: boolean }>`
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  padding-right: ${({ theme }) => theme.margin.l};
  padding-left: ${({ theme }) => `${88 - theme.palette.drawer.width}px`};
  & .logo {
    margin-left: 20px;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      margin-left: 6px;
    }
    margin-top: 15px;
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: ${({ theme }) => `0 ${theme.margin.m}`};

    color: ${({ theme, isOpen }) =>
      isOpen
        ? theme.palette.background.default
        : theme.palette.background.dark};

    background-color: ${({ theme, isOpen }) =>
      isOpen
        ? theme.palette.background.dark
        : theme.palette.background.default};
  }
`;

const FixedHeader = styled(Header)`
  position: fixed;
  width: 100%;
  right: 0;
  padding-left: 88px;
`;

const RelativeHeader = styled(Header)`
  position: relative;
`;

const MainContainer = styled('div')`
  margin-bottom: 56px;
`;

const TitleEnvironment = styled('div')`
  color: red; 
  font-size: x-large; 
  position: absolute; 
  margin-left: 170px;
`
