/* eslint-disable @typescript-eslint/no-explicit-any */
import { AdminRepairerConnectionSettingModel } from 'api/resources/models/AutoGenerated';
import {
  b2bSettingsTableHeader,
  fnolSettingsTableHeader,
  smsSettingsTableHeader,
  userPermitsSettingsTableHeader,
} from './constants';

export const tableHeaderGetter = (category: string | null) => {
  switch (category) {
    case 'UserPermit':
      return userPermitsSettingsTableHeader;
    case 'FNOLConfig':
      return fnolSettingsTableHeader;
    case 'B2BSetting':
      return b2bSettingsTableHeader;
    case 'SMSSubscription':
      return smsSettingsTableHeader;
    default:
      return [];
  }
};

const filterData = (data: any[], filter: string) => {
  const lowerCaseFilter = filter.toLowerCase();
  return data?.filter((item: any) =>
    Object.values(item).some((property) =>
      (property as any)?.toString().toLowerCase().includes(lowerCaseFilter)
    )
  );
};

export const getInitialConnectionSetting = (
  connectionSettings: AdminRepairerConnectionSettingModel | undefined,
  selectedCategory: string | null,
  filterValue?: string,
) => {
  if (!selectedCategory) return [];
  const categoryMapping = {
    B2BSetting: connectionSettings?.b2BSetting,
    FNOLConfig: connectionSettings?.fnolConfiguration,
    SMSSubscription: connectionSettings?.smsSubscription,
    UserPermit: connectionSettings?.userPermit,
  };

  const data = (categoryMapping as any)[selectedCategory] || [];

  if (!filterValue || filterValue === '' || data.length === 0) return data;

  return filterData(data, filterValue);
};
