import {
  MainPageContainer,
  StyledGridItem,
  UpperInfoContainer,
} from 'components/PageLayout';
import { Heading, Link, Text } from 'components/Typography';
import styled from '@emotion/styled';
import { pagePaddingLeft } from 'components/PageLayout';
import { Permit } from 'api/resources/models/User';
import { useMemo } from 'react';
import { useUser } from 'pages/hooks';
import { ROUTES } from 'core/routes';

const UPDATE_JOB_STATUS = Permit.CA_REPAIR_UPDATE_JOB_STATUS;
const UPDATE_WP_NAME = Permit.CA_REPAIR_UPDATE_WP;

export const RepairerUpdates = () => {
  const { user } = useUser();
  const UPDATEJOBSTATUS = useMemo(
    () => user?.userPermits?.find((permit) => permit.code == UPDATE_JOB_STATUS),
    [user]
  );
  const UPDATEWPNAME = useMemo(
    () => user?.userPermits?.find((permit) => permit.code == UPDATE_WP_NAME),
    [user]
  );

  return (
    <MainPageContainer isLoading={false} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <StyledGridItem>
          <Heading className="subheading">Force Update Claims</Heading>
        </StyledGridItem>
      </UpperInfoContainer>
      <ContentInfoContainer direction="column">
        {UPDATEJOBSTATUS && (
          <Link variant="button" to={ROUTES.jobStatus}>
            <CustomText fontSize={'l'}>Force Update Job Status</CustomText>
          </Link>
        )}
        {UPDATEWPNAME && (
          <Link variant="button" to={ROUTES.workProviderName}>
            <CustomText fontSize={'l'}>
              Force Update Work Provider Name
            </CustomText>
          </Link>
        )}
      </ContentInfoContainer>
    </MainPageContainer>
  );
};

const ContentInfoContainer = styled(MainPageContainer)`
  margin-top: ${({ theme }) => theme.margin.s};
  padding-left: ${({ theme }) => pagePaddingLeft(theme)};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;

const CustomText = styled(Text)`
  text-decoration: underline;
  text-transform: capitalize;
`;
