import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.extend(UTC);

export function getActivity(date: Date | null | undefined) {
  const activityDate =
    date && dayjs(date).isAfter(Date.now()) ? Date.now() : date;

  const activityDateDiff = date ? dayjs().diff(dayjs(date), 'd') : 0;

  return {
    activity: activityDate
      ? dayjs(activityDate).isSame(new Date('1999-01-01T00:00:00'))
        ? 'when dinosaurs were around'
        : `${dayjs(activityDate).fromNow(true)} ago`
      : null,
    isOlder: activityDateDiff > 3,
  };
}

export const formatDateTime = (date?: Date | null | string) => {
  return date ? dayjs(new Date(date)).format('DD/MM/YYYY HH:mm:ss') : '';
};
export const formatDate = (date?: Date | null | string) => {
  return date ? dayjs(new Date(date)).format('DD/MM/YYYY') : '';
};
export const formatDateTimeCustomFormat = (
  date?: Date | null | string,
  format = 'DD/MM/YYYY HH:mm:ss'
) => {
  return date ? dayjs(new Date(date)).format(format) : '';
};

export const hasOnlySpaces = (value: string) => value.trim() === '';

export const dateToString = (date: Date | null) =>
  dayjs(date).format('YYYY-MM-DD');

export const dateTimeToString = (date: Date | null) =>
  dayjs(date).format('YYYY-MM-DDTHH:mm:ss');

export const stringToDate = (date: string | null) =>
  date ? new Date(date) : null;

/**
 *
 * setRecursiveTimeout should be used instead of standard setTimeout
 * if delay number is too big (higher than 2,147,483,647 ms (about 24.8 days)).
 * https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
 * Function takes delay parameter compares it with maximum 32-bit integer value-1 (maxDelay)
 * and if higher then runs standard setTimeout function with callback
 * which runs setRecursiveTimeout function with delay value minus maxDelay value
 * this will be repeated untill delay value becomes less than maxDelay
 * after that standard setTimeout will be called with original functionHandler to execute
 *
 */
export function setRecursiveTimeout(
  functionHandler: () => void,
  delay: number
) {
  //Here we calculate max 32-bit integer value with -1
  //https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
  const maxDelay = Math.pow(2, 31) - 1;

  if (delay > maxDelay) {
    const _delay = delay - maxDelay;
    return window.setTimeout(function () {
      setRecursiveTimeout.apply(undefined, [functionHandler, _delay]);
    }, maxDelay);
  }

  return window.setTimeout(functionHandler, delay);
}

export const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
