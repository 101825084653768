import { AdminMaintenanceMessageModel } from 'api/resources/models/AutoGenerated';
import { SortOrder } from 'api/resources/models/User';

export interface MaintenanceMessagesWithDelete
  extends Omit<AdminMaintenanceMessageModel, 'companies'> {
  companies: string;
  delete: boolean;
}

export interface MaintenanceMessageHeader {
  id: keyof MaintenanceMessagesWithDelete;
  label: string;
}

export const maintenanceMessageTableHeader: MaintenanceMessageHeader[] = [
  { id: 'maintenanceMessageId', label: 'Message ID' },
  { id: 'messageType', label: 'Type' },
  { id: 'ticketNumber', label: 'Ticket Number' },
  { id: 'message', label: 'Message' },
  { id: 'companies', label: 'Companies' },
  { id: 'startDTG', label: 'Valid From' },
  { id: 'endDTG', label: 'Valid To' },
  { id: 'delete', label: '' },
];

export const convertToDeleteButton = (
  data: AdminMaintenanceMessageModel
): MaintenanceMessagesWithDelete => ({
  ...data,
  companies: data.companies
    ? data.companies.map((company) => company.companyName)?.join(', ')
    : '',
  delete: true,
});

export const useMaintenanceMessagesParams = (url: string) => {
  const urlParams = new URLSearchParams(url);

  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  const startDate = urlParams.get('startDate');
  const endDate = urlParams.get('endDate');
  const includeDeleted = urlParams.get('includeDeleted');
  return {
    sortString: sort as keyof AdminMaintenanceMessageModel,
    sortOrder: Object.values(SortOrder).includes(sortOrder) ? sortOrder : null,
    startDate: startDate,
    endDate: endDate,
    includeDeleted,
  };
};
