import { ROUTES } from 'core/routes';
import { SvgIconComponent } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import DirectionsBusFilledRoundedIcon from '@mui/icons-material/DirectionsBusFilledRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Permit } from 'api/resources/models/User';

export interface MenuItem {
  name: string;
  icon:
    | SvgIconComponent
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  route: string;
  permitCode?: Permit[];
}

export const topMenuItems: MenuItem[] = [
  {
    name: 'Dashboard',
    route: ROUTES.dashboard,
    icon: DashboardIcon,
  },
  {
    name: 'FNOL',
    route: ROUTES.fnol,
    icon: NotificationsRoundedIcon,
    permitCode: [Permit.CA_FNOL_VIEW],
  },
  {
    name: 'Message Queues',
    route: ROUTES.messageQueues,
    icon: EmailRoundedIcon,
    permitCode: [Permit.CA_MESSAGE_QUEUES_VIEW],
  },
  {
    name: 'Repair Updates',
    route: ROUTES.repairerUpdates,
    icon: FileCopyRoundedIcon,
    permitCode: [
      Permit.CA_REPAIR_UPDATE_JOB_STATUS,
      Permit.CA_REPAIR_UPDATE_WP,
    ],
  },
  {
    name: 'Repairers',
    route: ROUTES.repairer,
    icon: BuildRoundedIcon,
    permitCode: [Permit.CA_REPAIRERS_VIEW, Permit.CA_REPAIRERS_MANAGE],
  },
  {
    name: 'Maintainance Messages',
    route: ROUTES.maintainanceMessages,
    icon: WarningRoundedIcon,
    permitCode: [
      Permit.CA_MAINTENANCE_MESSAGES_VIEW,
      Permit.CA_MAINTENANCE_MESSAGES_MANAGE,
    ],
  },
  {
    name: 'Work Providers',
    route: ROUTES.workProvider,
    icon: DirectionsBusFilledRoundedIcon,
    permitCode: [Permit.CA_WP_VIEW],
  },
];

export type Mode = 'About' | 'Help' | null;

export interface BottomMenuItem {
  name: Mode;
  icon: SvgIconComponent;
  permitCode?: Permit;
  route?: string;
}

export const bottomMenuItems: BottomMenuItem[] = [
  {
    name: 'About',
    icon: InfoIcon,
    permitCode: Permit.CONNECTIONS_ADMIN,
    route: ROUTES.about,
  },
  { name: 'Help', icon: HelpIcon, permitCode: Permit.CONNECTIONS_ADMIN },
];

// export interface AccordionItem {
//   id: AccordionTypes;
//   summary: string;
//   details: {  description: string }[];
// }

// export const accordionItems: AccordionItem[] = [
//   {
//     id: 'panel1',
//     summary: 'Repairs List - Last Updated - Only when repair is in progress',
//     details: [
//       {
//         status: 'green',
//         description: 'Ongoing repair was last updated less than 3 days ago',
//       },
//       {
//         status: 'amber',
//         description: 'Ongoing repair was last updated between 4 and 7 days ago',
//       },
//       {
//         status: 'red',
//         description: 'Ongoing repair was last updated more than 7 days ago',
//       },
//     ],
//   },
//   {
//     id: 'panel2',
//     summary: 'Repairs List - Milestone Dates',
//     details: [
//       {
//         status: 'green',
//         description: 'On time',
//       },
//       {
//         status: 'amber',
//         description: 'Estimation date is missing or milestone is overdue',
//       },
//       {
//         status: 'red',
//         description:
//           'Milestone is either late or early compared to predicted date',
//       },
//     ],
//   },
//   {
//     id: 'panel3',
//     summary: 'Repair details - Milestone Dates',
//     details: [
//       {
//         status: 'green',
//         description: 'Milestone is on time',
//       },
//       {
//         status: 'amber',
//         description: 'Estimation date is missing or milestone is overdue',
//       },
//       {
//         status: 'red',
//         description:
//           'Milestone is either late or early compared to predicted date',
//       },
//     ],
//   },
//   {
//     id: 'panel4',
//     summary: 'Repair details - SLA',
//     details: [
//       {
//         status: 'green',
//         description: 'SLA was finished in expected timeframe',
//       },
//       {
//         status: 'amber',
//         description: 'SLA was slightly overdue',
//       },
//       {
//         status: 'red',
//         description: 'SLA was late',
//       },
//     ],
//   },
// ];
