import { api } from 'api';
import { config } from 'core/config';
import { NotificationDetailQueue, QueueType } from './models/AutoGenerated';

const adminBaseUrl = '/api/adminnotification';

export const getMessageQueues = (
  searchValue: string
): Promise<NotificationDetailQueue[]> =>
  api
    .get(`${config.connectionsAdminApiUrl}${adminBaseUrl}/queueEntries`, {
      params: { searchValue },
    })
    .then((result) => result.data);

export const getMessageQueuesXML = (
  queueId?: number,
  queueType?: QueueType
): Promise<NotificationDetailQueue> =>
  api
    .get(`${config.connectionsAdminApiUrl}${adminBaseUrl}/queueEntry`, {
      params: { queueId, queueType },
    })
    .then((result) => result.data);
