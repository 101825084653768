import { SortOrder, UserPermit } from 'api/resources/models/User';
import { DataGrid, getSortObject } from 'components/DataGrid';
import { SortObject } from 'components/types';
import { useEffect, useState } from 'react';
import { connectionsTableHeader } from './types';
import { orderBy } from 'lodash';
import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRemoveConnections } from 'pages/hooks';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { successMessages } from 'api/resources/responseMessages/success';
import { ActionPopup } from 'components/ActionPopup';
import { guidanceTextConnections } from 'api/resources/responseMessages/infoMessages';
import PrimaryToast from 'components/PrimaryToast';
import { GrayBackdrop, Loader } from 'components/Loader';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import { AdminRepairerConnections } from 'api/resources/models/AutoGenerated';
import { SelectOption } from 'components/Select';
import { SettingsModal } from './ConnectionsSettings/SettingsModal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const categoryMappings: any = {
  b2bSettings: 'B2BSetting',
  fnol: 'FNOLConfig',
  sms: 'SMSSubscription',
  userPermits: 'UserPermit',
};

interface Props {
  repairerId: number;
  isDisabled: boolean;
  hasPermission: boolean;
  accessInternalRepairer?: UserPermit;
  repairerConnections?: AdminRepairerConnections[];
}

export const Connections = ({
  accessInternalRepairer,
  repairerConnections,
  hasPermission,
  isDisabled,
  repairerId,
}: Props) => {
  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: 'workProvider',
    sortOrder: SortOrder.Descending,
  });
  const [sortedRepairerConnections, setSortedRepairerConnections] =
    useState(repairerConnections);
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  const [actionPopup, setActionPopup] = useState<{
    title: string;
    guidence: string | null;
    isOpen: boolean;
    sysTypeDropDown?: boolean;
    onSubmit?: ({
      ticketId,
      comment,
      sysType,
    }: {
      ticketId: string | null;
      comment: string | null;
      sysType?: SelectOption | null;
    }) => void;
  }>({
    title: '',
    guidence: '',
    sysTypeDropDown: false,
    isOpen: false,
  });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  const [workProviderId, setWorkProviderId] = useState<number | null>(null);
  const [category, setCategory] = useState<string | null>(null);
  const [displaySettings, setDisplaySettings] = useState<boolean>(false);
  const [settingsTitle, setSettingsTitle] = useState<string>('');

  const { isRemoving, removeConnection } = useRemoveConnections();

  useEffect(() => {
    setSortedRepairerConnections(sortConnections(sortObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repairerConnections, itemsPerPage, currentPage]);

  const isBusy = isRemoving;

  return repairerConnections && repairerConnections?.length > 0 ? (
    <div>
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <DataGrid
        onSort={sort}
        removeLeftPadding
        sortObject={sortObject}
        currentPage={currentPage}
        data={sortedRepairerConnections}
        headers={connectionsTableHeader}
        customCellRender={renderCustomCell}
        onItemsPerPageChange={setItemsPerPage}
        onPageChange={(page) => setCurrentPage(page)}
        totalCount={repairerConnections?.length}
      />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
      <SettingsModal 
        isOpen={displaySettings}
        onClose={() => setDisplaySettings(false)}
        workProviderId={workProviderId}
        repairerId={repairerId}
        category={category}
        settingsTitle={settingsTitle}
        />
      <ActionPopup
        title={actionPopup.title}
        isOpen={actionPopup.isOpen}
        guidence={actionPopup.guidence}
        onSubmit={actionPopup.onSubmit}
        sysTypeDropDown={actionPopup.sysTypeDropDown}
        onClose={() =>
          setActionPopup({
            title: '',
            guidence: '',
            isOpen: false,
            sysTypeDropDown: false,
          })
        }
      />
    </div>
  ) : null;

  function renderCustomCell({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof AdminRepairerConnections;
    row: AdminRepairerConnections;
  }) {
    if (categoryMappings[headerId] && row[headerId] !== 0) {
      return (
        <ConnectionSettings
          onClick={() => {
            setDisplaySettings(true);
            setCategory(categoryMappings[headerId]);
            setWorkProviderId(row.wpMasterId);
            const workProvider = row.workProvider;
            const categoryTitle =
              connectionsTableHeader.find(({ id }) => id == headerId)?.label ??
              '';
            setSettingsTitle(`${categoryTitle} - ${workProvider}`);
          }}
        >
          {row[headerId]}
        </ConnectionSettings>
      );
    } 
    if (headerId == 'canRemove') {
      if (!row.workProvider?.includes('Custom'))
        return accessInternalRepairer && hasPermission && !isDisabled ? (
          <CustomRemoveButton
            type="button"
            color="primary"
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              setActionPopup({
                isOpen: true,
                title: 'Details',
                guidence: guidanceTextConnections,
                onSubmit: (data) =>
                  removeConnection({
                    repairerId,
                    cwCode: null,
                    schemeCode: null,
                    comment: data.comment,
                    ticketId: data.ticketId,
                    workProviderID: row.wpMasterId,
                  })
                    .then(() => {
                      setToastState({
                        message: successMessages.deleteConnection,
                        isOpen: true,
                        severity: 'success',
                      });
                    })
                    .catch((error: AxiosError) => handleError(error)),
              });
            }}
          >
            <DeleteIcon />
          </CustomRemoveButton>
        ) : (
          <></>
        );
      else return <></>;
    }
    return baseRender();
  }

  function sortConnections(sortObj: SortObject) {
    const currentListed = currentPage * itemsPerPage;
    const orderedList = orderBy(
      repairerConnections,
      sortObj.sortString,
      sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
    );
    return orderedList?.slice(currentListed, currentListed + itemsPerPage);
  }

  function sort(sortValue: keyof AdminRepairerConnections) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedRepairerConnections(sortConnections(sortObj));
      return sortObj;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(_error: AxiosError) {
    setToastState({
      message: errorMessages.removeConnection,
      isOpen: true,
      severity: 'error',
    });
  }
};

const CustomRemoveButton = styled(PrimaryButton)`
  padding: 0;
  height: 40px;
  min-width: 40px;
`;

const ConnectionSettings = styled.div`
  :hover {
    text-decoration: underline;
    font-weight: bold;
  }
`;