import { logger } from 'core/logger';
import store from 'core/store';
import { queryClient } from 'api/client';
import {
  userLoadingStarted,
  userLoggedin,
  userLoginFailed,
  userLoggedout,
  userSwitchedProfile,
} from 'core/store/auth';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { config } from '../config';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'core/logger/ApplicationInsightsService';

const userManager = new UserManager({
  authority: config.authority,
  client_id: config.clientId,
  redirect_uri: config.redirectUri,
  response_type: config.responseType,
  scope: config.scope,
  post_logout_redirect_uri: config.postLogoutRedirectUri,
  stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  automaticSilentRenew: false,
  silent_redirect_uri: config.silentSigninUri,
  prompt: 'login',
});

export const sessionStorageSelectedProfileKey = 'selectedProfileId';

export async function loadUserFromStorage() {
  try {
    store.dispatch(userLoadingStarted());
    const user = await userManager.getUser();
    if (!user) {
      return store.dispatch(userLoginFailed());
    }
    store.dispatch(
      userLoggedin({
        accessToken: user.access_token,
        name: user.profile.name,
        email: user.profile.email,
      })
    );
    const profileId = sessionStorage.getItem(sessionStorageSelectedProfileKey);
    if (profileId) {
      switchUserProfile(Number(profileId));
    }
  } catch (e) {
    logger?.error(`UserService -> User not found: ${e}`);
    appInsights?.trackException({ error: new Error(`UserService -> User not found: ${e}`), severityLevel: SeverityLevel.Error });
    store.dispatch(userLoginFailed());
  }
}

export function clearUserManagerUserState() {
  userManager.clearStaleState();
  userManager.removeUser();
  sessionStorage.removeItem(sessionStorageSelectedProfileKey);
}

export function signinRedirect() {
  store.dispatch(userLoadingStarted());
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function silentSigninCallback() {
  return userManager.signinSilentCallback();
}

export function signoutRedirectCallback() {
  clearUserManagerUserState();
  return userManager.signoutRedirectCallback();
}
 
export async function signout() {
  await store.dispatch(userLoggedout());
  queryClient.clear();
  clearLocalStorage();
  return userManager.signoutRedirect();
}

export function switchUserProfile(profileId: number | undefined) {
  store.dispatch(userSwitchedProfile(Number(profileId)));
  if (profileId)
    sessionStorage.setItem(
      sessionStorageSelectedProfileKey,
      profileId?.toString()
    );
}
export function clearLocalStorage() {
  sessionStorage.removeItem(sessionStorageSelectedProfileKey);
}

export default userManager;
