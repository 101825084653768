import { formErrorMessages } from 'api/resources/responseMessages/formErrors';
import { SelectOption } from 'components/Select';
import * as Yup from 'yup';

export interface CustomRepairerFNOLForm {
  workProviderID?: number;
  sourceRepairerCode: string | null;
  deployToSiteCode: string | null;
  externalSiteCode: string | null;
  streamCodeToUse: string | null;
  deployToEmailAddress: string | null;
  connectionType?: SelectOption | null;
}

export const validation = Yup.object({
  workProviderID: Yup.number().required(formErrorMessages.required),
  sourceRepairerCode: Yup.string().required(formErrorMessages.required),
  deployToSiteCode: Yup.string(),
  connectionType: Yup.object({
    id: Yup.number().required(formErrorMessages.required),
    text: Yup.string().required(formErrorMessages.required),
    value: Yup.string().required(formErrorMessages.required),
  })
    .nullable()
    .required(formErrorMessages.required),
  externalSiteCode: Yup.string(),
  // This is for making externalSiteCode required on selection of CAPS. If the requirement changes back uncomment to make this field required.
  // .when('connectionType', {
  //   is: (WP: SelectOption) => WP?.text == 'CAPS',
  //   then: Yup.string().required(formErrorMessages.required),
  // }),
  streamCodeToUse: Yup.string().required(formErrorMessages.required),
});
