import styled from '@emotion/styled';
import { Tab as MaterialTab, Tabs, Typography } from '@mui/material';

export const Tab = styled(Tabs)`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.palette.secondary.active}`};

  .MuiTabs-indicator {
    height: 4px;
    background-color: ${({ theme }) => theme.palette.primary.activeBorder};
  }
`;

export const TabItem = styled(MaterialTab) <{ disabled?: boolean }>`
  text-transform: none;

  &.MuiTab-root {
    font-size: ${({ theme }) => theme.fontSize.s};
    opacity: unset;
    min-width: unset;
  }

  color: ${({ disabled, theme }) =>
    disabled
      ? `${theme.palette.primary.disabled}`
      : `${theme.palette.primary.dark}`};
`;

export const PageTabItem = styled(TabItem)`
  &.MuiTab-root {
    min-height: 0;
    padding-top: 0;
  }
`;

export const PageTab = styled(Tab)`
  &.MuiTabs-root {
    min-height: 0;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography component={'div'}>{children}</Typography>}
    </div>
  );
}
