import styled from '@emotion/styled';
import { FormikProps, useFormik } from 'formik';
import { Container, GridItem } from '../Layout';
import { Modal } from '../Modal';
import { ModalButton } from '../ModalLayout';
import { RequiredLabel } from '../PageLayout';
import { TextArea } from '../TextArea';
import { TextField } from '../TextField';
import { Text } from '../Typography';
import { useTheme } from '@emotion/react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { Checkbox } from '../Checkbox';
import { Select } from '../Select';
import { PopUpForm, Props, selectOptionsSysType, validation } from './types';

export const ActionPopup = ({
  title,
  isOpen,
  onClose,
  guidence,
  DLGCheckbox,
  sysTypeDropDown,
  onSubmit,
}: Props) => {
  const theme = useTheme();
  const {
    errors,
    touched,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values: { ticketnumber, comments, sysType, forceRemovalFromDLG },
  }: FormikProps<PopUpForm> = useFormik<PopUpForm>({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      comments: '',
      ticketnumber: '',
      sysType: undefined,
      forceRemovalFromDLG: false,
    },

    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit?.({
        comment: values.comments,
        ticketId: values.ticketnumber,
        ...(DLGCheckbox
          ? { forceRemovalFromDLG: values.forceRemovalFromDLG }
          : null),
        ...(sysTypeDropDown ? { sysType: values.sysType } : null),
      });
      onClose();
    },
  });

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <MainModalContainer direction="column">
          <GridItem>
            {guidence && (
              <Text fontSize="m" fontWeight="normal" className="text">
                {guidence}
              </Text>
            )}
          </GridItem>
          {DLGCheckbox && (
            <GridItem className="input-container">
              <Checkbox
                checked={forceRemovalFromDLG}
                onChange={(e) =>
                  setFieldValue(
                    'forceRemovalFromDLG',
                    Boolean(e.target.checked)
                  )
                }
                label="Force Removal From DLG"
                color="primary"
              />
            </GridItem>
          )}
          <Container direction="row" className="input-container">
            {sysTypeDropDown && (
              <CustomGridItem md={6} sm={6} xs={12}>
                <RequiredLabel>SysType</RequiredLabel>
                <Select
                  options={selectOptionsSysType}
                  selectedId={sysType?.id}
                  onChange={(value) => {
                    setFieldTouched('sysType');
                    setFieldValue('sysType', value);
                  }}
                />
                {touched.sysType && errors.sysType && (
                  <Text color={theme.palette.primary.error}>Required</Text>
                )}
              </CustomGridItem>
            )}
            <GridItem md={6} sm={6} xs={12}>
              <RequiredLabel>Ticket Number</RequiredLabel>
              <TextInput
                fullWidth
                name="ticketnumber"
                value={ticketnumber}
                onChange={handleChange}
                error={!!errors?.ticketnumber && touched?.ticketnumber}
                helperText={touched?.ticketnumber && errors?.ticketnumber}
              />
            </GridItem>
          </Container>
          <GridItem className="input-container">
            <RequiredLabel>Comments</RequiredLabel>
            <CustomTextArea
              name="comments"
              value={comments}
              onChange={handleChange}
            />
            {errors?.comments && touched?.comments && (
              <Text color={theme.palette.primary.error}>{errors.comments}</Text>
            )}
          </GridItem>
          <Container direction="row" justify="space-around">
            <GridItem>
              <CustomModalButton onClick={onClose}>
                <CloseIcon className="icon" /> Cancel
              </CustomModalButton>
            </GridItem>
            <GridItem>
              <CustomModalButton type="submit">
                <CheckIcon className="icon" /> Save Changes
              </CustomModalButton>
            </GridItem>
          </Container>
        </MainModalContainer>
      </form>
    </Modal>
  );
};

export const MainModalContainer = styled(Container)`
  width: 100%;
  height: 100%;
  & .secondary-text {
    margin-bottom: ${({ theme }) => theme.margin.xs};
  }
  & .text {
    margin-bottom: ${({ theme }) => theme.margin.m};
  }
  & .input-title {
    margin-bottom: ${({ theme }) => theme.margin.s};
  }
  & .input-container {
    margin-bottom: ${({ theme }) => theme.margin.l};
  }
  & .icon {
    margin-right: 10px;
  }
`;

const TextInput = styled(TextField)`
  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
    overflow: hidden;
  }
`;

const CustomTextArea = styled(TextArea)`
  width: 100%;
  min-height: 120px;
`;

const CustomModalButton = styled(ModalButton)`
  min-width: 200px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
    margin-top: 10px;
  }
`;

const CustomGridItem = styled(GridItem)`
      padding-right: ${({ theme }) => theme.margin.s};
      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        padding-right: 0;
        margin - bottom: 6px;
      }
      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        margin-bottom: 10px;
      }
      & svg {
        width: 20px;
        height: 20px;
        color: ${({ theme }) => theme.palette.secondary.main};
      }
  `;
