import { NotificationDetailQueue } from 'api/resources/models/AutoGenerated';
import { SortOrder } from 'api/resources/models/User';

export interface MessageQueueHeader {
  id: keyof NotificationDetailQueue;
  label: string;
}

export const messageTableHeader: MessageQueueHeader[] = [
  { id: 'queue', label: 'Queue' },
  { id: 'messageType', label: 'Message type' },
  { id: 'receivedDate', label: 'Received Date' },
  { id: 'readDate', label: 'Read Date' },
  { id: 'sender', label: 'Sender' },
  { id: 'recipient', label: 'Recipient' },
  // { id: 'messageContent', label: '' },
];

type MessageQueueParams = {
  sortString: keyof NotificationDetailQueue | null;
  sortOrder: SortOrder | null;
  searchValue: string;
};

export function useMessageQueueParams(url: string): MessageQueueParams {
  const urlParams = new URLSearchParams(url);

  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  const searchValue = urlParams.get('searchValue');
  return {
    sortString: sort as keyof NotificationDetailQueue,
    sortOrder: Object.values(SortOrder).includes(sortOrder) ? sortOrder : null,
    searchValue: searchValue || '',
  };
}
