import { UserPermit } from 'api/resources/models/User';
import { MenuItem } from './types';

export function mapMenuItems(menuItems: MenuItem[], userPermits: UserPermit[]) {
  const codes = userPermits.map((permit) => permit.code);

  return menuItems.filter((item) =>
    item?.permitCode
      ? codes.some((code) => item.permitCode?.some((permit) => permit == code))
      : item
  );
}
