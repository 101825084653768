import { useParams } from 'react-router-dom';
import { MaintenanceMessagesParams } from 'core/routes';
import { GrayBackdrop, Loader } from 'components/Loader';
import { useMaintenanceMessage, useUser } from 'pages/hooks';
import { convertToFormData, CustomMaintenanceMessages } from './form';
import styled from '@emotion/styled';
import {
  pagePaddingLeft,
  MainPageContainer,
  UpperInfoContainer,
} from 'components/PageLayout';
import { BackButton } from 'components/Button';
import { Heading } from 'components/Typography';
import { Container, GridItem } from 'components/Layout';
import EditMaintenanceMessages from './editMaintenanceMessages';
import { CreateMaintenanceMessages } from './createMaintenanceMessages';
import { useMemo } from 'react';
import { Permit } from 'api/resources/models/User';
import { errorMessages } from 'api/resources/responseMessages/failure';

const CREATE_MAINTENANCE_MESSAGE_PERMISSION =
  Permit.CA_MAINTENANCE_MESSAGES_MANAGE;

const useMaintenanceMessagesParams = () => {
  const { messageId: rawUserId } = useParams<MaintenanceMessagesParams>();
  const messageId = Number(rawUserId);
  return isNaN(messageId) ? errorMessages.invalidId('MessageId') : messageId;
};

export function MaintenanceMessagesCreate() {
  return <MaintainanceMessagesDetails />;
}

export function MaintenanceMessagesEdit() {
  const messageId = useMaintenanceMessagesParams();
  if (typeof messageId === 'string')
    throw new Error(errorMessages.EditInvalidId('Maintenance Messages'));

  const { user, isLoading, isFetching } = useUser();
  const { isMaintenanceMessageLoading, maintenanceMessage } =
    useMaintenanceMessage(messageId);

  const CREATEMAINTENANCEMESSAGEPERMISSION = useMemo(
    () =>
      user?.userPermits?.find(
        (permit) => permit.code == CREATE_MAINTENANCE_MESSAGE_PERMISSION
      ),
    [user]
  );

  const isBusy = isMaintenanceMessageLoading || isLoading || isFetching;

  return (
    <MaintainanceMessagesDetails
      maintenanceMessage={
        maintenanceMessage && convertToFormData(maintenanceMessage)
      }
      isManagePermit={!!CREATEMAINTENANCEMESSAGEPERMISSION}
      isBusy={isBusy}
    />
  );
}

interface Props {
  maintenanceMessage?: CustomMaintenanceMessages;
  isManagePermit?: boolean;
  isBusy?: boolean;
}

export function MaintainanceMessagesDetails({
  maintenanceMessage,
  isManagePermit,
  isBusy = false,
}: Props) {
  return (
    <MainPageContainer direction="column" wrap="nowrap" isLoading={isBusy}>
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <BackButton text="Back to Maintenance Messages" />
        <Heading>
          {maintenanceMessage
            ? isManagePermit
              ? 'Edit Maintenance Message'
              : 'View Maintenance Message'
            : 'Create Maintenance Message'}
        </Heading>
      </UpperInfoContainer>

      <CustomContainer direction="column">
        <Container direction="row">
          <GridItem md={12} sm={12} xs={12}>
            {maintenanceMessage ? (
              <EditMaintenanceMessages
                maintenanceMessage={maintenanceMessage}
              />
            ) : (
              <CreateMaintenanceMessages />
            )}
          </GridItem>
        </Container>
      </CustomContainer>
    </MainPageContainer>
  );
}

const CustomContainer = styled(Container)`
  background: ${({ theme }) => theme.palette.background.default};
  padding-left: ${({ theme }) => pagePaddingLeft(theme)};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;
