import {
  B2BSetting,
  FNOLConfigurationHeader,
  SMSSubscriptionHeader,
  UserPermitsHeader,
} from './types';

export const b2bSettingsTableHeader: B2BSetting[] = [
  { id: 'b2BFormat', label: 'B2B Format' },
  { id: 'wpId', label: 'Work Provider Id' },
  { id: 'b2BCustomSetting', label: 'B2B Settings' },
  { id: 'userProfile', label: 'User Profile' },
  { id: 'serviceStartDate', label: 'Service Start Date' },
];

export const fnolSettingsTableHeader: FNOLConfigurationHeader[] = [
  { id: 'workProvider', label: 'Work Provider' },
  { id: 'externalSourceID', label: 'External Source ID' },
  { id: 'externalSourceStream', label: 'External Stream' },
  { id: 'externalSourceRepairerID', label: 'External Source Repairer ID' },
  { id: 'sourceRepairer', label: 'Repairer' },
  { id: 'sourceRepairerCode', label: 'Repairer Code' },
  { id: 'deployToRepairer', label: 'Deploy To Repairer' },
  { id: 'deployToSiteCode', label: 'Deploy To SiteCode' },
  { id: 'streamCodeToUse', label: 'Stream Code' },
  { id: 'deployElectronically', label: 'Deploy Electronically' },
  { id: 'deploymentMethod', label: 'Deploy Method' },
  { id: 'deployByEmail', label: 'Deploy By Email' },
  { id: 'deployToEmail', label: 'Deploy To Email' },
  { id: 'customSettings', label: 'Settings' },
];

export const smsSettingsTableHeader: SMSSubscriptionHeader[] = [
  { id: 'startDate', label: 'Start Date' },
  { id: 'processor', label: 'Processor' },
  { id: 'wpId', label: 'Work Provider Id' },
];

export const userPermitsSettingsTableHeader: UserPermitsHeader[] = [
  { id: 'dataAccessProfile', label: 'Access Profile' },
  { id: 'wpId', label: 'Work Provider Id' },
];
