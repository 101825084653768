import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { AdminRepairerConnectionHistory } from 'api/resources/models/AutoGenerated';
import { Container, GridItem } from 'components/Layout';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import { TablePagination } from 'components/Pagination/TablePagination';
import { Text } from 'components/Typography';
import { useState } from 'react';
import { formatDateTime } from 'utils/helpers';

interface Props {
  repairerHistory?: AdminRepairerConnectionHistory[];
}
export const History = ({ repairerHistory }: Props) => {
  const theme = useTheme();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  const currentListed = currentPage * itemsPerPage;
  const history = repairerHistory?.slice(
    currentListed,
    currentListed + itemsPerPage
  );

  return (
    <Container direction="column" md={12} sm={12} xs={12}>
      {history?.map((data, index) => (
        <CustomGridItem key={index}>
          <Container direction="row">
            <GridItem md={4} sm={4} xs={12}>
              <Text fontWeight="bold">
                {data.wpName == 'Undefined' ? '' : data.wpName}
              </Text>
              <Text>{data.username}</Text>
              <Text>{data.description}</Text>
            </GridItem>
            <GridItem md={4} sm={4} xs={12}>
              <StatusColorHandler status={data.action} />
            </GridItem>
            <GridItem md={4} sm={4} xs={12}>
              <Text>{formatDateTime(data.dtgAction)}</Text>
              <Text>
                Ticket:{' '}
                <span className="ticket-number">{data.ticketNumber}</span>
              </Text>
            </GridItem>
          </Container>
        </CustomGridItem>
      ))}
      {currentPage !== undefined && itemsPerPage && (
        <TablePagination
          currentPage={currentPage}
          totalCount={repairerHistory?.length ?? 0}
          onPageChange={(pages) => setCurrentPage(pages)}
          onItemsPerPageChange={(count) => setItemsPerPage?.(count)}
        />
      )}
    </Container>
  );
  function StatusColorHandler({ status }: { status: string | null }) {
    let color = null;
    if (
      status == 'Connection Added' ||
      status == 'Fnol Configuration' ||
      status == 'Repairer Unlocked' ||
      status == 'Repairer Reactivated' ||
      status == 'Repairer Creation' ||
      status == 'Permits Added' ||
      status == 'Fix Auto Connection' ||
      status == 'LEX Connection Added' ||
      status == 'ITAS Connection Adde' ||
      status == 'Circle Leasing Conne' ||
      status == 'DLG NARG SMS Added' ||
      status == 'Added connection' ||
      status == 'Repairer Creation' ||
      status == 'DLG SMS Added'
    )
      color = theme.palette.status.green;
    else if (
      status == 'Connection Removed' ||
      status == 'Repairer Locked' ||
      status == 'Repairer Suspended' ||
      status == 'Repairer Terminated' ||
      status == 'Disconnection' ||
      status == 'Full Disconnection' ||
      status == 'Permits Removed' ||
      status == 'ITAS Disconnection' ||
      status == 'Disconnection' ||
      status == 'ITAS Connection Dele'
    )
      color = theme.palette.status.red;
    else if (
      status == 'Repairer Updated' ||
      status == 'ITAS Connection Upda' ||
      status == 'System Change' ||
      status == 'Updated connection'
    )
      color = theme.palette.status.blue;
    else color = theme.palette.status.default;
    return (
      <StatusText fontSize="s" color={color}>
        {status}
      </StatusText>
    );
  }
};

const CustomGridItem = styled(GridItem)`
  border-bottom: 1px black solid;
  margin-top: ${({ theme }) => theme.margin.l};
  & .ticket-number {
    color: ${({ theme }) => theme.palette.button.danger};
  }
`;

const StatusText = styled(Text)<{ color: string }>`
  display: inline;
  padding: 5px;
  border-radius: ${({ theme }) => theme.border.radius};
  border: 1px ${({ color }) => color} solid;
  color: ${({ color }) => color};
`;
