/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { useState } from 'react';
import { GridItem } from 'components/Layout';
import { SelectOption } from 'components/InputSelect';
import { TextField } from 'components/TextField';
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { Box, ClickAwayListener } from '@mui/material';
import { Text } from './Typography';
import InputSelectWithSearch from './InputSelectWithSearch';

interface Props {
  options?: SelectOption<string | number>[];
  selectedId?: number;
  disabled?: boolean;
  onChange: (selectedId: number | undefined) => void;
  existingOptionsList?: number[];
  existingOptionColor?: string;
}

const RenderInput = (params: AutocompleteRenderInputParams) => (
  <TextField
    {...params}
    autoFocus={true}
    onFocus={(e) => e.target.select()}
    variant="outlined"
    placeholder="Search..."
    fullWidth
    style={{ width: 360 }}
  />
);

const RenderGroup = (props: AutocompleteRenderGroupParams) => {
  return (
    <Box component="li" {...props}>
      <Text>{props.children}</Text>
    </Box>
  );
};

export function SelectWithSearch({
  options,
  selectedId,
  onChange,
  disabled,
  existingOptionColor,
  existingOptionsList,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const selectedOption = options?.find(
    (r: { id: number | undefined }) => r.id === selectedId
  );

  const repairersFilterOptions = createFilterOptions({
    stringify: (option: SelectOption<string | number>) =>
      `${option.text} ${option.value}`,
  });

  return (
    <ClickAwayListener onClickAway={clickAwayListener}>
      <MainFiltersContainer>
        <FilterItem spacing={1}>
          <FilterButton
            type="button"
            fullWidth
            disabled={disabled}
            onClick={filterOnClick}
          >
            <Text>{selectedOption?.text ?? 'Select Work Provider'}</Text>
          </FilterButton>
          {isOpen && (
            <FilterContainer>
              <InputSelectWithSearch
                open={true}
                value={selectedOption}
                options={options ?? []}
                inputValue={inputValue}
                renderInput={RenderInput}
                renderGroup={RenderGroup}
                onChange={onChangeHandler}
                filterOptions={repairersFilterOptions}
                existingOptionColor={existingOptionColor}
                existingOptionsList={existingOptionsList}
                groupBy={(option) => String(option.value)}
                onInputChange={(_event, value) => setInputValue(value)}
              />
            </FilterContainer>
          )}
        </FilterItem>
      </MainFiltersContainer>
    </ClickAwayListener>
  );

  function clickAwayListener() {
    setIsOpen(false);
    setInputValue('');
  }
  function filterOnClick() {
    setIsOpen((prev) => !prev);
    setInputValue('');
  }
  function onChangeHandler(value: SelectOption | null) {
    onChange(value?.id);
    setIsOpen(false);
  }
}

export const FilterButton = styled.button<{
  fullWidth?: boolean;
  disabled?: boolean;
}>`
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.button.disabled : theme.palette.primary.light};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-size: ${({ theme }) => theme.fontSize.s};
  border: ${({ disabled }) => (disabled ? 0 : '1.5px')} solid #ccc;
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.primary.disabled : theme.palette.background.dark};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.border.radius};
  height: 40px;
  text-align: left;

  :hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.background.dark};
  }

  :active {
    border-color: ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.background.dark};
    path {
      fill: ${({ theme }) => theme.palette.primary.activeText};
    }
  }
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.xs}px`}) {
    & p {
      width: 15vw;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
  }

  .MuiOutlinedInput-adornedEnd {
    padding: 1px;
  }

  ${({ fullWidth, theme }) =>
    fullWidth &&
    `@media (max-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
    }`};
`;

const FilterItem = styled(GridItem)`
  position: relative;
  width: 100%;
`;
const FilterContainer = styled.div`
  position: absolute;
  top: 44px;
  width: 100%;
  background: #f1f8fc;
  z-index: 999;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const MainFiltersContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
`;
