import { Permit } from 'api/resources/models/User';
import { useUser } from 'pages/hooks';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from './routes';
import RouteWithPermit from './RouteWithPermit';
import { useAppSelector } from './store';

interface Props {
  anyOfPermits?: Permit[];
}

export const PrivateRoute = ({ anyOfPermits }: Props) => {
  const authUser = useAppSelector((state) => state.auth.user);
  const { user } = useUser();
  if (!authUser) return <Navigate to={ROUTES.login} />;
  if (anyOfPermits) return <RouteWithPermit anyOfPermits={anyOfPermits} />;
  if (user) return <Outlet />;
  return <></>;
};
