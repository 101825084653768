import { SortOrder } from 'api/resources/models/User';

export type FNOLDetailsParams = {
  notificationId: string;
};
export type MaintenanceMessagesParams = {
  messageId: string;
};
export type RepairerDetailsParams = {
  siteCode: string;
  repairerId: string;
  externalCodes: string;
};

export const ROUTES = {
  fnol: '/fnol',
  notFound: '/*',
  login: '/login',
  redirectHandler: '/',
  repairer: '/repairer',
  dashboard: '/dashboard',
  singinOidc: '/signin-oidc',
  signoutOidc: '/signout-oidc',
  workProvider: '/workprovider',
  messageQueues: '/messagequeues',
  repairerCreate: '/repairer/create',
  repairerUpdates: '/repairerupdates',
  fnolDetails: '/fnol/:notificationId',
  repairerEdit: '/repairer/:repairerId',
  silentSigninOidc: '/silent-signin-oidc',
  jobStatus: '/repairerupdates/jobstatus',
  maintainanceMessages: '/maintainancemessages',
  workProviderName: '/repairerupdates/workprovidername',
  addConnection: '/addConnection/:repairerId/:siteCode',
  addFnol: '/addFnol/:repairerId/:siteCode/:externalCodes',
  createMaintainanceMessages: '/maintainancemessages/create',
  updateMaintenanceMessage: '/maintainancemessages/:messageId',
  about: '/about',
  logout: '/logout',
};

export function createMessageQueueURL(
  sortingString = '',
  sortOrder: SortOrder = SortOrder.Ascending,
  searchValue = ''
) {
  const url = new URLSearchParams();
  url.set('sort', sortingString);
  url.set('sortDirection', sortOrder);
  url.set('searchValue', searchValue);
  return `${ROUTES.messageQueues}?${url.toString()}`;
}

export function createFnolQueueURL(
  sortingString = '',
  sortOrder: SortOrder = SortOrder.Ascending,
  searchValue = ''
) {
  const url = new URLSearchParams();
  url.set('sort', sortingString);
  url.set('sortDirection', sortOrder);
  url.set('searchValue', searchValue);
  return `${ROUTES.fnol}?${url.toString()}`;
}

export function createFnolDetailsURL(
  sortingString = '',
  sortOrder: SortOrder = SortOrder.Ascending,
  notificationId: number
) {
  const url = new URLSearchParams();
  url.set('sort', sortingString);
  url.set('sortDirection', sortOrder);
  return `${ROUTES.fnol}/${notificationId}?${url.toString()}`;
}

export function createMaintenanceMessagesURL(
  sortingString = '',
  sortOrder: SortOrder = SortOrder.Ascending,
  startDate: string,
  endDate: string,
  includeDeleted: string
) {
  const url = new URLSearchParams();
  url.set('sort', sortingString);
  url.set('sortDirection', sortOrder);
  url.set('startDate', startDate);
  url.set('endDate', endDate);
  url.set('includeDeleted', includeDeleted);
  return `${ROUTES.maintainanceMessages}?${url.toString()}`;
}

export function createRepairersURL(
  sortingString = '',
  sortOrder: SortOrder = SortOrder.Ascending,
  searchValue = ''
) {
  const url = new URLSearchParams();
  url.set('sort', sortingString);
  url.set('sortDirection', sortOrder);
  url.set('searchValue', searchValue);
  return `${ROUTES.repairer}?${url.toString()}`;
}
