import {
  AdminRepairerConnections,
  AdminRepairerExternalCode,
  AdminRepairerUpdateCounts,
} from 'api/resources/models/AutoGenerated';
import { formatDateTimeCustomFormat } from 'utils/helpers';

//Actions
export type ErrorTypes =
  | 'locked'
  | 'unlocked'
  | 'terminated'
  | 'connection'
  | 'reactivate';

export interface Action {
  title: string;
  dlgCheckbox: boolean;
  guidence: string;
  onSubmit: (data: {
    ticketId: string | null;
    comment: string | null;
    forceRemovalFromDLG?: boolean;
  }) => void;
}

//External Code
export interface ExternalCodeHeaders {
  id: keyof AdminRepairerExternalCode;
  label: string;
}
export const externalCodeTableHeader: ExternalCodeHeaders[] = [
  { id: 'externalSystem', label: 'External System' },
  { id: 'externalCode', label: 'External Code' },
  { id: 'dtgCreated', label: 'Date Added' },
  { id: 'enabled', label: 'Enabled' },
];

//Status
export const formatDateMonth = (data?: AdminRepairerUpdateCounts[] | null) =>
  data?.map((item) => ({
    ...item,
    dtgUpdate: formatDateTimeCustomFormat(item.dtgUpdate, 'MMM D'),
  }));

export const formatXAxis = (date: string) => {
  return formatDateTimeCustomFormat(date, 'MMM');
};

//connections
export interface ConnectionsHeader {
  id: keyof AdminRepairerConnections;
  label: string;
}
export const connectionsTableHeader: ConnectionsHeader[] = [
  { id: 'workProvider', label: 'Work Provider' },
  { id: 'userPermits', label: 'User Permits' },
  { id: 'fnol', label: 'FNOL' },
  { id: 'b2bSettings', label: 'B2B Settings' },
  { id: 'sms', label: 'SMS' },
  { id: 'canRemove', label: '' },
];
