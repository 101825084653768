import { Actions } from './Actions';
import { Details } from './Details';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { QuickDetails } from './QuickDetails';
import { Container, GridItem } from 'components/Layout';
import { Permit, User } from 'api/resources/models/User';
import { UpperInfoContainer } from 'components/PageLayout';
import { PageTab, PageTabItem, TabPanel } from 'components/Tab';
import { Status } from './Status';
import { ExternalCode } from './ExternalCodes';
import { History } from './History';
import { Connections } from './Connections';
import { AdminRepairerDetails } from 'api/resources/models/AutoGenerated';
import { usePageData } from 'core/PageData';

const CREATE_REPAIRER_PERMISSION = Permit.CA_REPAIRERS_MANAGE;
const ACCESS_INTERNAL_REPAIRER = Permit.CA_REPAIRER_CONNECTIONS;

export const EditRepairerDetails = ({
  user,
  repairer,
  repairerId,
}: {
  user?: User;
  repairerId: number;
  repairer?: AdminRepairerDetails;
}) => {
  const { pageData, setPageData } = usePageData();
  const defaultTabValue = 'status';

  const createRepairerPermission = useMemo(
    () =>
      user?.userPermits?.find(
        (permit) => permit.code == CREATE_REPAIRER_PERMISSION
      ),
    [user]
  );
  const accessInternalRepairer = useMemo(
    () =>
      user?.userPermits?.find(
        (permit) => permit.code == ACCESS_INTERNAL_REPAIRER
      ),
    [user]
  );

  const selectedTab = pageData ?? defaultTabValue;

  return (
    <UpperInfoContainer direction="column">
      <MainContainer direction="column">
        <GridItem className="quick-details">
          <QuickDetails
            repairerId={repairerId}
            lastUpdated={repairer?.lastUpdated}
            repairerDetails={repairer?.repairerDetails}
          />
        </GridItem>
        <Container className="bottom-info">
          <GridItem md={6} sm={12} xs={12}>
            <PageTab
              value={selectedTab}
              onChange={(_e, value) => setPageData(value)}
            >
              <PageTabItem label="Status" value="status" />
              <PageTabItem label="Details" value="details" />
              <PageTabItem label="External Codes" value="externalCodes" />
              <PageTabItem label="Connections" value="connections" />
              <PageTabItem label="History" value="history" />
            </PageTab>
          </GridItem>
        </Container>
        <Container direction="row">
          <GridItem md={6} sm={12} xs={12}>
            {repairer?.updateCounts && (
              <TabPanel value={selectedTab} index="status">
                <Status
                  updateCounts={repairer?.updateCounts}
                  repairerStatus={repairer.repairerDetails?.status}
                />
              </TabPanel>
            )}

            {repairer?.repairerDetails && (
              <TabPanel value={selectedTab} index="details">
                <Details
                  repairerId={repairerId}
                  repairerDetails={repairer?.repairerDetails}
                  repairerExternalCodes={repairer?.externalCodes}
                  hasPermission={!!createRepairerPermission}
                  isDisabled={repairer?.repairerDetails?.status == 'Terminated'}
                />
              </TabPanel>
            )}

            {repairer?.externalCodes && (
              <TabPanel value={selectedTab} index="externalCodes">
                <ExternalCode repairerExternalCodes={repairer?.externalCodes} />
              </TabPanel>
            )}

            {repairer?.connections && (
              <TabPanel value={selectedTab} index="connections">
                <Connections
                  repairerId={repairerId}
                  hasPermission={!!createRepairerPermission}
                  repairerConnections={repairer?.connections}
                  accessInternalRepairer={accessInternalRepairer}
                  isDisabled={repairer?.repairerDetails?.status == 'Terminated'}
                />
              </TabPanel>
            )}

            {repairer?.history && (
              <TabPanel value={selectedTab} index="history">
                <History repairerHistory={repairer?.history} />
              </TabPanel>
            )}
          </GridItem>
          {createRepairerPermission && (
            <GridItem md={6} sm={12} xs={12}>
              <Actions
                repairerId={repairerId}
                allConnections={repairer?.connections}
                externalCodes={repairer?.externalCodes}
                siteCode={repairer?.repairerDetails?.siteCode}
                accessInternalRepairer={accessInternalRepairer}
                repairerStatus={repairer?.repairerDetails?.status}
              />
            </GridItem>
          )}
        </Container>
      </MainContainer>
    </UpperInfoContainer>
  );
};

const MainContainer = styled(Container)`
  & .quick-details {
    margin-top: ${({ theme }) => theme.margin.l};
  }
  & .bottom-info {
    padding-top: ${({ theme }) => theme.padding.l};
  }
`;
