import { createTheme } from '@mui/material/styles';

export type Theme = typeof theme;

export const theme = {
  palette: {
    background: {
      default: '#ffffff',
      dark: '#00363a',
    },
    primary: {
      light: '#ffffff',
      main: '#00363a',
      dark: '#000',
      selected: '#FCB500',
      hover: '#DFEDF5',
      disabled: '#979EAE',
      border: '#DADADA',
      active: '#FFFCF3',
      activeBorder: '#FCB500',
      activeText: '#FCB500',
      skeleton: '#F1F8FC',
      error: '#F84444',
    },
    secondary: {
      light: '#fff',
      main: '#979EAE',
      dark: '#000,',
      hover: '#F1F8FC',
      active: '#DDECF4',
    },
    messages: {
      success: 'rgb(44, 168, 0)',
      error: '#F84444',
    },
    button: {
      light: '#F1F8FC',
      dark: '#004d40',
      hover: '#00363a',
      active: '#FCB500',
      activeText: '#FFFFFF',
      disabled: '#DADADA',
      danger: '#B54423',
    },
    row: {
      hover: '#F1F8FC',
    },
    statusChip: {
      live: '#4BB543',
      terminated: '#ccc',
      locked: '#dc3545',
    },
    drawer: {
      width: 72,
      height: 64,
      hoverColor: '#004d40',
    },
    icon: {
      green: '#2CA800',
      red: '#F84444',
      warn: '#FCB500',
    },
    contact: {
      primary: '#F1FEF5',
      button: '#004d40',
      highlight: '#D7F5E9',
    },
    menuIcons: {
      default: '#fff',
      active: '#FCB500',
    },
    fnolDetails: {
      blue: '#A5F0F5',
      green: '#A5F5AF',
      red: '#F5A5B1',
      orange: '#F5CBA5',
      gray: '#e6e6e6',
    },
    charts: {
      stroke: '#e57373',
      fill: '#ffa4a2',
    },
    status: {
      red: '#B54423',
      blue: '#4040ff',
      green: '#2CA800',
      default: 'inherit',
    },
    repairer: {
      existingConnection: '#afd7c9',
    },
  },
  fontSize: {
    xxs: '10px',
    xs: '11px',
    s: '13px',
    m: '14px',
    l: '16px',
    xl: '18px',
    xxl: '24px',
  },
  padding: {
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
  },
  margin: {
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
  },
  border: {
    radius: '5px',
    chipRadius: '15px',
  },
  shadow: {
    s: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    m: '0px 4px 8px rgba(0, 0, 0, 0.25)',
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 350,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

export const muiTheme = createTheme(theme, {
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          '& #root': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          },
        },
      },
    },
  },
});
