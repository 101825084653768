import { AdminNotificationHeader } from 'api/resources/models/AutoGenerated';
import { SortOrder } from 'api/resources/models/User';

export interface FnolQueueHeader {
  id: keyof AdminNotificationHeader;
  label: string;
}

export const fnolTableHeader: FnolQueueHeader[] = [
  { id: 'notificationDate', label: 'Date' },
  { id: 'workProvider', label: 'Work Provider' },
  { id: 'registration', label: 'Vehicle Reg.' },
  { id: 'mainReference', label: 'Reference' },
  { id: 'repairerName', label: 'Repairer' },
  { id: 'currentRepair', label: 'Current Repair' },
  { id: 'notificationId', label: 'ID' },
];

type FnolQueueParams = {
  sortString: keyof AdminNotificationHeader | null;
  sortOrder: SortOrder | null;
  searchValue: string;
};

export function useFnolQueueParams(url: string): FnolQueueParams {
  const urlParams = new URLSearchParams(url);

  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  const searchValue = urlParams.get('searchValue');
  return {
    sortString: sort as keyof AdminNotificationHeader,
    sortOrder: Object.values(SortOrder).includes(sortOrder) ? sortOrder : null,
    searchValue: searchValue || '',
  };
}
