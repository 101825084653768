import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Container, GridItem } from 'components/Layout';
import {
  Label,
  RequiredLabel,
  SaveButton,
  SaveIcon,
} from 'components/PageLayout';
import { TextField } from 'components/TextField';
import { Select } from 'components/Select';
import { Text } from 'components/Typography';
import { FormikProps, useFormik } from 'formik';
import { GrayBackdrop, Loader } from 'components/Loader';
import { validation, CustomRepairerFNOLForm } from './types';
import { useEffect, useMemo, useState } from 'react';
import { useAddFNOL, useGetFnolConfigs } from 'pages/hooks';
import PrimaryToast from 'components/PrimaryToast';
import { successMessages } from 'api/resources/responseMessages/success';
import { AxiosError } from 'axios';
import { getBadRequestErrorMessage } from 'api';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { FNOLConfigErrorModal } from 'api/resources/models/User';
import { AdminRepairerConnections } from 'api/resources/models/AutoGenerated';
import { useNavigate } from 'react-router-dom';
import { ALLIANZ_ID, DLG_ID, LV_ID, NARG_ID, VIZION_ID } from '../constants';
import { selectOptionsConnectionType } from './constats';

interface Props {
  isBusy: boolean;
  siteCode: string;
  repairerId: number;
  availableWorkProviders: AdminRepairerConnections[];
  externalCodesExpanded?: { [key: string]: string }[] | null;
}

export const AddFNOLForm = ({
  isBusy,
  siteCode,
  repairerId,
  externalCodesExpanded,
  availableWorkProviders,
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentWorkProviderId, setCurrentWorkProviderId] = useState<
    number | undefined
  >();
  const [isFNOLAdded, setIsFNOLAdded] = useState(false);
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  const { FNOLAdd, isAdding } = useAddFNOL(repairerId);
  const { fnolConfigs } = useGetFnolConfigs(currentWorkProviderId, repairerId);

  const existingDeploymentMethod = useMemo(
    () => fnolConfigs?.map(el => el.deploymentMethod),
    [fnolConfigs]
  );
  const optionsConnectionType = useMemo(
    () => selectOptionsConnectionType.map(el => { return { ...el, disabled: existingDeploymentMethod.includes(el.id) } }),
    [existingDeploymentMethod]
  );
  
  const isLoading = isAdding || isBusy;

  const initialValues = {
    workProviderID: currentWorkProviderId,
    sourceRepairerCode: '',
    deployToSiteCode: siteCode || '',
    connectionType: undefined,
    externalSiteCode: '',
    streamCodeToUse: '',
    deployToEmailAddress: null,
  };

  const {
    errors,
    touched,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values: {
      workProviderID,
      connectionType,
      deployToSiteCode,
      externalSiteCode,
      sourceRepairerCode,
      streamCodeToUse,
    },
  }: FormikProps<CustomRepairerFNOLForm> = useFormik<CustomRepairerFNOLForm>({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: validation,
    onSubmit,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetForm(), [workProviderID]);

  const workProvidersChecker = (id: number) => {
    return availableWorkProviders?.find((e) => e.wpMasterId == id)
  }
  const allianz = workProvidersChecker(ALLIANZ_ID);
  const dlg = workProvidersChecker(DLG_ID);
  const lv = workProvidersChecker(LV_ID);
  const vizion = workProvidersChecker(VIZION_ID);
  const narg = workProvidersChecker(NARG_ID);

  const existingCapsCode = externalCodesExpanded?.find((item) =>
    Object.prototype.hasOwnProperty.call(item, 'CAPS')
  )?.CAPS;

  const existingVizionCode = externalCodesExpanded?.find((item) => Object.hasOwn(item, 'TPFNOL-VIZION'));

  const selectOptionsWorkProviders = useMemo(
    () => [
      {
        id: ALLIANZ_ID,
        value: 'Allianz',
        text: 'Allianz',
        disabled: !allianz || allianz?.fnol > 0,
      },
      {
        id: DLG_ID,
        value: 'DLG2',
        text: 'DLG2',
        disabled: !(dlg ?? vizion ?? narg),
      },
      {
        id: LV_ID,
        value: 'LV',
        text: 'LV',
        disabled: !lv || lv?.fnol > 0,
      },
    ],
    [allianz, dlg, lv, narg, vizion]
  );

  return (
    <form onSubmit={handleSubmit}>
      <GrayBackdrop open={isLoading}>
        <Loader />
      </GrayBackdrop>
      <Container md={12} sm={12} xs={12}>
        <StyledSaveButton type="submit">
          <SaveIcon />
          Save Changes
        </StyledSaveButton>
      </Container>

      <Container direction="row" wrap="wrap" md={6} sm={8} xs={12}>
        <CustomGridItem md={6} sm={6} xs={12}>
          <RequiredLabel>Select Work Provider</RequiredLabel>
          <Select
            options={selectOptionsWorkProviders}
            selectedId={workProviderID}
            onChange={(value) => {
              setFieldTouched('workProviderID');
              setFieldValue('workProviderID', value.id);
              setCurrentWorkProviderId(value.id);
            }}
          />
          {touched.workProviderID && errors.workProviderID && (
            <Text color={theme.palette.primary.error}>
              {errors.workProviderID}
            </Text>
          )}
        </CustomGridItem>
        <CustomGridItem md={6} sm={6} xs={12}>
          <Label>Deploy To Sitecode</Label>
          <TextField
            fullWidth
            name="deployToSiteCode"
            value={deployToSiteCode}
            onChange={handleChange}
            onBlur={() => setFieldTouched('deployToSiteCode', true)}
            error={!!errors?.deployToSiteCode && touched?.deployToSiteCode}
            helperText={touched?.deployToSiteCode && errors?.deployToSiteCode}
          />
        </CustomGridItem>
        <CustomGridItem md={6} sm={6} xs={12}>
          <RequiredLabel>Connection Type</RequiredLabel>
          <Select
            disableId
            options={optionsConnectionType}
            selectedId={connectionType?.id}
            onChange={(value) => {
              if (value.id === 9)
                setFieldValue('externalSiteCode', existingCapsCode ?? '');
              else if (existingVizionCode && value.id === 13) setFieldValue('externalSiteCode', existingVizionCode['TPFNOL-VIZION'] ?? '');
              else setFieldValue('externalSiteCode', '');
              setFieldTouched('connectionType');
              setFieldValue('connectionType', value);
            }}
          />
          {touched.connectionType && errors.connectionType && (
            <Text color={theme.palette.primary.error}>Required</Text>
          )}
        </CustomGridItem>
        <CustomGridItem md={6} sm={6} xs={12}>
          <Label>External Sitecode</Label>
          <TextField
            fullWidth
            name="externalSiteCode"
            value={externalSiteCode}
            onChange={handleChange}
            onBlur={() => setFieldTouched('externalSiteCode', true)}
            error={!!errors?.externalSiteCode && touched?.externalSiteCode}
            helperText={touched?.externalSiteCode && errors?.externalSiteCode}
            disabled={
              connectionType?.id !== 9 ||
              (connectionType?.id === 9 && !!existingCapsCode)
            }
          />
        </CustomGridItem>
        <CustomGridItem md={6} sm={6} xs={12}>
          <RequiredLabel>Source Repairer Code</RequiredLabel>
          <TextField
            fullWidth
            name="sourceRepairerCode"
            value={sourceRepairerCode}
            onChange={handleChange}
            onBlur={() => setFieldTouched('sourceRepairerCode', true)}
            error={!!errors?.sourceRepairerCode && touched?.sourceRepairerCode}
            helperText={
              touched?.sourceRepairerCode && errors?.sourceRepairerCode
            }
          />
        </CustomGridItem>
        <CustomGridItem md={6} sm={6} xs={12}>
          <RequiredLabel>Stream Code To Use</RequiredLabel>
          <TextField
            fullWidth
            name="streamCodeToUse"
            value={streamCodeToUse}
            onChange={handleChange}
            onBlur={() => setFieldTouched('streamCodeToUse', true)}
            error={!!errors?.streamCodeToUse && touched?.streamCodeToUse}
            helperText={touched?.streamCodeToUse && errors?.streamCodeToUse}
          />
        </CustomGridItem>
      </Container>
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => {
          setToastState({ ...toastState, isOpen: false });
          isFNOLAdded && navigate(-1);
        }}
        severity={toastState.severity}
      />
    </form>
  );

  function onSubmit(data: CustomRepairerFNOLForm) {
    if (
      repairerId &&
      data.workProviderID &&
      data.connectionType?.id != null &&
      !isFNOLAdded
    )
      FNOLAdd({
        ...data,
        repairerId,
        workProviderID: data.workProviderID,
        connectionType: data.connectionType.id,
        externalSiteCode:
          data.connectionType.text == 'CAPS' ? data.externalSiteCode : null,
      })
        .then(() => {
          setIsFNOLAdded(true);
          setToastState({
            message: successMessages.addFNOL,
            isOpen: true,
            severity: 'success',
          });
        })
        .catch((error: AxiosError) => handleError(error));
  }

  function handleError(error: AxiosError) {
    const errorData = getBadRequestErrorMessage(error);
    const errorMessage = (errorData as FNOLConfigErrorModal)?.error;
    setToastState({
      message: errorMessage ?? errorMessages.addFNOL,
      isOpen: true,
      severity: 'error',
    });
  }
};

const StyledSaveButton = styled(SaveButton)`
  margin-top: ${({ theme }) => theme.margin.l};
`;

const CustomGridItem = styled(GridItem)`
      margin-bottom: ${({ theme }) => theme.margin.l};
      padding-right: ${({ theme }) => theme.margin.s};
      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        padding-right: 0;
        margin - bottom: 6px;
      }
      & svg {
        width: 20px;
        height: 20px;
        color: ${({ theme }) => theme.palette.secondary.main};
      }
  `;
