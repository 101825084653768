import styled from '@emotion/styled';
import { Permit } from 'api/resources/models/User';
import { LoadingScreen } from 'components/LoadingScreen';
import { Menu } from 'components/Menu';
import { MainHeader } from 'containers/MainHeader';
import { loadUserFromStorage } from 'core/auth/userService';
import { PrivateRoute } from 'core/PrivateRoute';
import { ROUTES } from 'core/routes';
import { useAppSelector } from 'core/store';
import { Dashboard } from 'pages/Dashboard';
import { MessagesQueuePage } from 'pages/MessageQueues';
import { FnolQueuePage } from 'pages/Fnol';
import { useUser } from 'pages/hooks';
import { Login } from 'pages/Login';
import { RedirectToStartPageHandler } from 'pages/RedirectToStartPageHandler';
import { SigninOidc } from 'pages/SigninOidc';
import { SignoutOidc } from 'pages/SignoutOidc';
import { SilentSigninOidc } from 'pages/SilentSigninOidc';
import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { About } from 'pages/About';
import { FNOLDetails } from 'pages/FNOLDetails';
import { RepairerUpdates } from 'pages/RepairerUpdates';
import { MaintainanceMessages } from 'pages/MaintenanceMessages';
import {
  MaintenanceMessagesCreate,
  MaintenanceMessagesEdit,
} from 'pages/MaintenanceMessagesDetails';
import {
  ForceUpdateJobStatus,
  ForceUpdateWPChange,
} from 'pages/RepairUpdateDetails';
import {
  RepairerDetailsCreate,
  RepairerDetailsEdit,
} from 'pages/RepairersDetails';
import { Repairers } from 'pages/Repairers';
import { AddConnection } from 'pages/RepairersDetails/EditRepairerDetails/AddConnection';
import { AddFNOL } from 'pages/RepairersDetails/EditRepairerDetails/AddFNOL';

export const Main = () => {
  const { user, isLoading, isFetching } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading: reduxUserLoading } = useAppSelector((state) => state.auth);

  const isBusy = reduxUserLoading || isLoading || isFetching;

  useEffect(() => {
    loadUserFromStorage();
  }, []);

  if (isBusy) return <LoadingScreen />;
  return (
    <Container>
      <Menu userPermits={user?.userPermits} />
      {user?.userPermits && (
        <MainHeader
          isOpen={isOpen}
          userPermits={user.userPermits}
          setOpen={() => setIsOpen(!isOpen)}
        />
      )}
      <MainSection>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route
              path={ROUTES.redirectHandler}
              element={<RedirectToStartPageHandler />}
            />
            <Route path={ROUTES.dashboard} element={<Dashboard />} />
          </Route>
          <Route
            element={<PrivateRoute anyOfPermits={[Permit.CA_FNOL_VIEW]} />}
          >
            <Route path={ROUTES.fnol} element={<FnolQueuePage />} />
          </Route>
          <Route element={<PrivateRoute anyOfPermits={[Permit.CA_WP_VIEW]} />}>
            <Route path={ROUTES.workProvider} element={<Dashboard />} />
          </Route>

          <Route
            element={
              <PrivateRoute anyOfPermits={[Permit.CA_MESSAGE_QUEUES_VIEW]} />
            }
          >
            <Route
              path={ROUTES.messageQueues}
              element={<MessagesQueuePage />}
            />
          </Route>
          <Route
            element={
              <PrivateRoute
                anyOfPermits={[
                  Permit.CA_REPAIR_UPDATE_JOB_STATUS,
                  Permit.CA_REPAIR_UPDATE_WP,
                ]}
              />
            }
          >
            <Route
              path={ROUTES.repairerUpdates}
              element={<RepairerUpdates />}
            />
          </Route>
          <Route
            element={
              <PrivateRoute
                anyOfPermits={[Permit.CA_REPAIR_UPDATE_JOB_STATUS]}
              />
            }
          >
            <Route path={ROUTES.jobStatus} element={<ForceUpdateJobStatus />} />
          </Route>
          <Route
            element={
              <PrivateRoute anyOfPermits={[Permit.CA_REPAIR_UPDATE_WP]} />
            }
          >
            <Route
              path={ROUTES.workProviderName}
              element={<ForceUpdateWPChange />}
            />
          </Route>
          <Route
            element={
              <PrivateRoute
                anyOfPermits={[Permit.CA_MAINTENANCE_MESSAGES_VIEW]}
              />
            }
          >
            <Route
              path={ROUTES.maintainanceMessages}
              element={<MaintainanceMessages />}
            />
          </Route>
          <Route
            element={
              <PrivateRoute
                anyOfPermits={[Permit.CA_MAINTENANCE_MESSAGES_MANAGE]}
              />
            }
          >
            <Route
              path={ROUTES.createMaintainanceMessages}
              element={<MaintenanceMessagesCreate />}
            />
          </Route>
          <Route
            element={
              <PrivateRoute
                anyOfPermits={[Permit.CA_MAINTENANCE_MESSAGES_VIEW]}
              />
            }
          >
            <Route
              path={ROUTES.updateMaintenanceMessage}
              element={<MaintenanceMessagesEdit />}
            />
          </Route>
          <Route
            element={<PrivateRoute anyOfPermits={[Permit.CA_FNOL_VIEW]} />}
          >
            <Route path={ROUTES.fnolDetails} element={<FNOLDetails />} />
          </Route>
          <Route
            element={<PrivateRoute anyOfPermits={[Permit.CA_REPAIRERS_VIEW]} />}
          >
            <Route path={ROUTES.repairer} element={<Repairers />} />
          </Route>
          <Route
            element={
              <PrivateRoute anyOfPermits={[Permit.CA_REPAIRERS_MANAGE]} />
            }
          >
            <Route
              path={ROUTES.repairerCreate}
              element={<RepairerDetailsCreate />}
            />
          </Route>
          <Route
            element={<PrivateRoute anyOfPermits={[Permit.CA_REPAIRERS_VIEW]} />}
          >
            <Route
              path={ROUTES.repairerEdit}
              element={<RepairerDetailsEdit />}
            />
          </Route>
          <Route
            element={
              <PrivateRoute anyOfPermits={[Permit.CA_REPAIRERS_MANAGE]} />
            }
          >
            <Route path={ROUTES.addConnection} element={<AddConnection />} />
          </Route>
          <Route
            element={
              <PrivateRoute anyOfPermits={[Permit.CA_REPAIRERS_MANAGE]} />
            }
          >
            <Route path={ROUTES.addFnol} element={<AddFNOL />} />
          </Route>
          <Route path={ROUTES.login} element={<Login />} />
          <Route path={ROUTES.singinOidc} element={<SigninOidc />} />
          <Route path={ROUTES.signoutOidc} element={<SignoutOidc />} />
          <Route
            path={ROUTES.silentSigninOidc}
            element={<SilentSigninOidc />}
          />
          <Route path={ROUTES.about} element={<About />} />
          <Route path={ROUTES.notFound} element={<div>Not Found</div>} />
          <Route path={ROUTES.logout} element={<LoadingScreen />} />
        </Routes>
      </MainSection>
    </Container>
  );
};

const Container = styled('div')`
  width: 100%;
  height: 100%;
  padding-left: ${({ theme }) => `${theme.palette.drawer.width}px`};
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-left: 0;
  }
`;

const MainSection = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
`;
