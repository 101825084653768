import { FunctionComponent, ReactNode } from 'react';
import { useUser } from 'pages/hooks';
import { Permit } from 'api/resources/models/User';

interface Props {
  children: React.FC | ReactNode | FunctionComponent | Element;
  permit: Permit;
}

export default function Permits({ permit, children }: Props) {
  const { isLoading } = useUser();
  const { permitAllowed } = useUserPermit(permit);

  if (isLoading) return null;

  return <>{permitAllowed ? children : null}</>;
}

export const useUserPermit = (permit: Permit) => {
  const { user } = useUser();
  const permitAllowed = !!user?.userPermits?.find(
    (p) => p.code?.toUpperCase() === permit.toUpperCase()
  );
  return { permitAllowed };
};
