import styled from '@emotion/styled';
import { DateTimePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
  Label,
  RequiredLabel,
  SaveButton,
  SaveIcon,
} from 'components/PageLayout';
import { Text } from 'components/Typography';
import { FormikProps, useFormik } from 'formik';
import { Select } from 'components/Select';
import * as Yup from 'yup';
import { TextArea } from 'components/TextArea';
import { useTheme } from '@emotion/react';
import { selectOptions } from './types';
import { CustomMaintenanceMessages } from './form';
import { GrayBackdrop, Loader } from 'components/Loader';
import { Permit } from 'api/resources/models/User';
import { useMemo } from 'react';
import { useCompanies, useUser } from 'pages/hooks';
import dayjs from 'dayjs';
import { formErrorMessages } from 'api/resources/responseMessages/formErrors';
import { TextField } from 'components/TextField';
import { SelectOption, MultipleSelect } from 'components/Multiselect';

const validation = Yup.object({
  startDTG: Yup.date().nullable().required(formErrorMessages.required),
  endDTG: Yup.date()
    .when('startDTG', (startDTG, schema) =>
      schema.min(
        dayjs(startDTG || undefined).add(15, 'minute'),
        formErrorMessages.validToGreaterThanValidFrom
      )
    )
    .nullable()
    .required(formErrorMessages.required),
  messageType: Yup.object({
    id: Yup.number().required(formErrorMessages.required),
    text: Yup.string().required(formErrorMessages.required),
    value: Yup.string().required(formErrorMessages.required),
  }).required(formErrorMessages.required),
  message: Yup.string().required(),
  ticketNumber: Yup.string(),
  companies: Yup.array()
    .min(1, formErrorMessages.minCompanies)
    .of(
      Yup.object({
        id: Yup.number().required(formErrorMessages.required),
        text: Yup.string().required(formErrorMessages.required),
        value: Yup.string().required(formErrorMessages.required),
      })
    ),
});

interface Props {
  maintenanceMessage?: CustomMaintenanceMessages;
  onSubmit: (data: CustomMaintenanceMessages) => void;
  onRemove?: (messageId?: number | null) => void;
  isBusy: boolean;
}

const CREATE_MAINTENANCE_MESSAGE_PERMISSION =
  Permit.CA_MAINTENANCE_MESSAGES_MANAGE;

export const DetailsMaintainanceMessages = ({
  maintenanceMessage,
  onSubmit,
  isBusy,
}: Props) => {
  const theme = useTheme();
  const { user, isLoading, isFetching } = useUser();
  const { companiesInfo, isCompaniesLoading } = useCompanies();
  const CREATEMAINTENANCEMESSAGEPERMISSION = useMemo(
    () =>
      user?.userPermits?.find(
        (permit) => permit.code == CREATE_MAINTENANCE_MESSAGE_PERMISSION
      ),
    [user]
  );

  const {
    errors,
    isValid,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values: { startDTG, endDTG, ticketNumber, messageType, message, companies },
  }: FormikProps<CustomMaintenanceMessages> = useFormik<CustomMaintenanceMessages>(
    {
      validateOnMount: true,
      enableReinitialize: true,
      initialValues: {
        maintenanceMessageId: maintenanceMessage?.maintenanceMessageId || 0,
        startDTG: maintenanceMessage?.startDTG || null,
        endDTG: maintenanceMessage?.endDTG || null,
        messageType: maintenanceMessage?.messageType || selectOptions[0],
        message: maintenanceMessage?.message || '',
        ticketNumber: maintenanceMessage?.ticketNumber || '',
        companies: maintenanceMessage?.companies || null,
      },
      ...(maintenanceMessage
        ? {
            initialTouched: {
              startDTG: true,
              endDTG: true,
              message: true,
              messageType: true,
              ticketNumber: true,
            },
          }
        : {}),
      validationSchema: validation,
      onSubmit: (values) => {
        onSubmit(values);
      },
    }
  );

  const companyOptions = useMemo<SelectOption[]>(
    () =>
      companiesInfo?.map((company) => ({
        id: company.id,
        value: company.name ?? '',
        text: company.name ?? '',
      })) || [],
    [companiesInfo]
  );

  const loading = isBusy || isLoading || isFetching || isCompaniesLoading;

  return (
    <form onSubmit={handleSubmit}>
      <GrayBackdrop open={loading}>
        <Loader />
      </GrayBackdrop>
      {CREATEMAINTENANCEMESSAGEPERMISSION && (
        <Container md={12} sm={12} xs={12}>
          <StyledSaveButton type="submit" disabled={!isValid}>
            <SaveIcon />
            Save Changes
          </StyledSaveButton>
        </Container>
      )}

      <SectionContainer direction="row">
        <CustomGridItem md={3} sm={4} xs={12}>
          <Label>Message Type</Label>
          <Select
            options={selectOptions}
            disabled={!CREATEMAINTENANCEMESSAGEPERMISSION}
            selectedId={messageType?.id}
            onChange={(value) => setFieldValue('messageType', value)}
          />
        </CustomGridItem>
        <GridItem md={3} sm={4} xs={12}>
          <Label>Ticket Number</Label>
          <TextField
            fullWidth
            name="ticketNumber"
            value={ticketNumber}
            onChange={handleChange}
            onBlur={() => setFieldTouched('ticketNumber', true)}
            error={!!errors?.ticketNumber && touched?.ticketNumber}
            helperText={touched?.ticketNumber && errors?.ticketNumber}
          />
        </GridItem>
      </SectionContainer>
      <SectionContainer direction="row">
        <CustomGridItem md={3} sm={4} xs={12}>
          <RequiredLabel>Valid From</RequiredLabel>
          <DateTimePickerField
            name="startDTG"
            value={startDTG}
            maxDate={endDTG}
            isDisabled={!CREATEMAINTENANCEMESSAGEPERMISSION}
            onChange={(date) => {
              setFieldTouched('startDTG');
              setFieldValue('startDTG', date);
            }}
          />
          {errors.startDTG && touched.startDTG && (
            <Text color={theme.palette.primary.error}>{errors.startDTG}</Text>
          )}
        </CustomGridItem>
        <GridItem md={3} sm={4} xs={12}>
          <RequiredLabel>Valid To</RequiredLabel>
          <DateTimePickerField
            name="endDTG"
            value={endDTG}
            minDate={startDTG}
            isDisabled={!CREATEMAINTENANCEMESSAGEPERMISSION}
            onChange={(date) => {
              setFieldTouched('endDTG');
              setFieldValue('endDTG', date);
            }}
          />
          {errors.endDTG && touched.endDTG && (
            <Text color={theme.palette.primary.error}>{errors.endDTG}</Text>
          )}
        </GridItem>
      </SectionContainer>
      <SectionContainer direction="row">
        <CustomGridItem md={3} sm={4} xs={12}>
          <RequiredLabel>Companies</RequiredLabel>
          <MultipleSelect
            value={companies ?? []}
            options={companyOptions}
            onChange={(selected) => {
              touched.companies && setFieldTouched('companies', true);
              let newCompanies = [...(companies || [])];
              if (selected.id == -1) {
                const selectedAll = companies?.length == companyOptions.length;
                newCompanies = selectedAll ? [] : companyOptions;
              } else {
                const checked = companies?.find(
                  (company: SelectOption) => company.id == selected.id
                );
                if (companies)
                  newCompanies = checked
                    ? companies.filter(
                        (company: SelectOption) => company.id != selected.id
                      )
                    : [...companies, selected];
                else newCompanies = [selected];
              }
              setFieldValue('companies', newCompanies);
            }}
          />
          {errors.startDTG && touched.startDTG && (
            <Text color={theme.palette.primary.error}>{errors.startDTG}</Text>
          )}
        </CustomGridItem>
      </SectionContainer>
      <SectionContainer direction="column" md={6} sm={8} xs={12}>
        <RequiredLabel>Message</RequiredLabel>
        <TextArea
          rows={20}
          name="message"
          value={message || ''}
          onChange={handleChange}
          onBlur={() => setFieldTouched('message', true)}
          disabled={!CREATEMAINTENANCEMESSAGEPERMISSION}
        />
        {errors.message && touched.message && (
          <Text color={theme.palette.primary.error}>{errors.message}</Text>
        )}
      </SectionContainer>
    </form>
  );
};

const StyledSaveButton = styled(SaveButton)`
  margin-top: ${({ theme }) => theme.margin.l};
`;

const SectionContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const CustomGridItem = styled(GridItem)`
      padding-right: ${({ theme }) => theme.margin.s};
      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        padding-right: 0;
        margin - bottom: 6px;
      }
      & svg {
        width: 20px;
        height: 20px;
        color: ${({ theme }) => theme.palette.secondary.main};
      }
  `;
