import {
  NotificationDetailFNOL,
  NotificationDetailQueue,
  NotificationDetailRepairOverview,
} from 'api/resources/models/AutoGenerated';
import { SortOrder } from 'api/resources/models/User';

export interface NotificationFnolHeader {
  id: keyof NotificationDetailFNOL;
  label: string;
}
export interface NotificationDetailRepairOverviewHeader {
  id: keyof NotificationDetailRepairOverview;
  label: string;
}
export interface NotificationDetailQueueHeader {
  id: keyof NotificationDetailQueue;
  label: string;
}

export const notificationDetailTableHeader: NotificationFnolHeader[] = [
  { id: 'registration', label: 'Registration' },
  { id: 'mainReference', label: 'Main Reference' },
  { id: 'secondReference', label: 'Second Reference' },
  { id: 'receivedDate', label: 'Received Date' },
  { id: 'readDate', label: 'Read Date' },
  { id: 'sender', label: 'Sender' },
  { id: 'recipient', label: 'Recipient' },
];
export const notificationDetailRepairOverviewHeader: NotificationDetailRepairOverviewHeader[] =
  [
    { id: 'repairId', label: 'Repair ID' },
    { id: 'vehicleReg', label: 'Vehicle Registration' },
    { id: 'schemeCode', label: 'Scheme Code' },
    { id: 'claimReference', label: 'Claim Reference' },
    { id: 'repairer', label: 'Repairer' },
    { id: 'repairCode', label: 'Repair Code' },
    { id: 'lastUpdatedDTG', label: 'Last Updated DTG' },
  ];
export const notificationDetailQueueHeader: NotificationDetailQueueHeader[] = [
  { id: 'queue', label: 'Queue' },
  { id: 'messageType', label: 'Message Type' },
  { id: 'receivedDate', label: 'Received Date' },
  { id: 'readDate', label: 'Read Date' },
  { id: 'sender', label: 'Sender' },
  { id: 'recipient', label: 'Recipient' },
];

type FnolDetailsParams = {
  sortString: keyof NotificationDetailFNOL | null;
  sortOrder: SortOrder | null;
  notificationId: string;
};
export function useFnolDetailsParams(url: string): FnolDetailsParams {
  const urlParams = new URLSearchParams(url);

  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  const notificationId = urlParams.get('notificationId');
  return {
    sortString: sort as keyof NotificationDetailFNOL,
    sortOrder: Object.values(SortOrder).includes(sortOrder) ? sortOrder : null,
    notificationId: notificationId || '',
  };
}
