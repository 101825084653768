import { useParams } from 'react-router-dom';
import { ROUTES, RepairerDetailsParams } from 'core/routes';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import { Heading } from 'components/Typography';
import { Container, GridItem } from 'components/Layout';
import { EditRepairerDetails } from './EditRepairerDetails';
import { CreateRepairerDetails } from './CreateRepairerDetails';
import { BackButton } from 'components/Button';
import styled from '@emotion/styled';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { useRepairer, useUser } from 'pages/hooks';
import { GrayBackdrop, Loader } from 'components/Loader';
import { usePageData } from 'core/PageData';

const useRepairerDetailsParams = () => {
  const { repairerId: rawUserId } = useParams<RepairerDetailsParams>();
  const repairerId = Number(rawUserId);
  return {
    repairerId: isNaN(repairerId)
      ? errorMessages.invalidId('Repairer ID')
      : repairerId,
  };
};

export function RepairerDetailsCreate() {
  return <RepairDetailsForm />;
}

export function RepairerDetailsEdit() {
  const { repairerId } = useRepairerDetailsParams();
  if (typeof repairerId === 'string')
    throw new Error(errorMessages.EditInvalidId('Repairer'));

  return <RepairDetailsForm repairerId={repairerId} />;
}

interface Props {
  repairerId?: number;
}

export function RepairDetailsForm({ repairerId }: Props) {
  const { user, isLoading, isFetching } = useUser();
  const { isRepairerLoading, repairer } = useRepairer(repairerId);
  const isBusy = isLoading || isFetching || isRepairerLoading;
  const { previousUrl } = usePageData();

  return (
    <CustomMainPageContainer direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <GrayBackdrop open={isBusy}>
          <Loader />
        </GrayBackdrop>

        <BackButton text="Back to Repairers" backUrl={previousUrl ?? ROUTES.repairer} />
        <Heading>
          {repairerId
            ? `Edit Repairer ${repairer?.repairerDetails?.repairerName ?? ''}`
            : 'Create Repairer'}
        </Heading>
      </UpperInfoContainer>

      <Container direction="row">
        <GridItem md={12} sm={12} xs={12}>
          {repairerId ? (
            <EditRepairerDetails
              user={user}
              repairer={repairer}
              repairerId={repairerId}
            />
          ) : (
            <CreateRepairerDetails />
          )}
        </GridItem>
      </Container>
    </CustomMainPageContainer>
  );
}

const CustomMainPageContainer = styled(MainPageContainer)`
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-right: 0;
  }
`;
