import React, { createContext, useContext, useMemo, useState } from 'react';

type ContextType = {
  pageData: string | null;
  setPageData: React.Dispatch<React.SetStateAction<string | null>>;
  previousUrl: string | null;
  setPreviousUrl: React.Dispatch<React.SetStateAction<string | null>>;
};

interface PageContextProviderProps {
  children: React.ReactNode;
}

const PageDataContext = createContext<ContextType | undefined>(undefined);

export const PageContextProvider = ({
  children,
}: PageContextProviderProps) => {
  const [pageData, setPageData] = useState<string | null>(null);
  const [previousUrl, setPreviousUrl] = useState<string | null>(null);

  const pageObjectData = useMemo(() => ({ pageData, setPageData, previousUrl, setPreviousUrl }), [pageData, previousUrl]);

  return (
    <PageDataContext.Provider value={pageObjectData}>
      {children}
    </PageDataContext.Provider>
  );
};

export const usePageData = (): ContextType => {
  const context = useContext(PageDataContext);
  if (!context) {
    throw new Error('usePageData must be used within a PageDataProvider');
  }
  return context;
};
