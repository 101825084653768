import { orderBy } from 'lodash';
import { SortOrder } from 'api/resources/models/User';
import { DataGrid, getSortObject } from 'components/DataGrid';
import { SortObject } from 'components/types';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useConnectionSettings } from 'pages/hooks';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import { BladeModal } from 'components/BladePopup';
import { MainModalContainer } from 'components/ModalLayout';
import { getInitialConnectionSetting, tableHeaderGetter } from './helpers';
import { renderCustomCell } from './renderCustomCell';
import { HeaderIdType } from './types';
import { StyledGridItem } from 'components/PageLayout';
import { TextField } from 'components/TextField';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  workProviderId: number | null;
  repairerId: number | null;
  category: string | null;
  settingsTitle: string;
}

export const SettingsModal = ({
  isOpen,
  onClose,
  workProviderId,
  repairerId,
  category,
  settingsTitle,
}: Props) => {
  
  const { connectionSettings, isSettingLoading } = useConnectionSettings(workProviderId, repairerId, category);

  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: '',
    sortOrder: SortOrder.Descending,
  });

  const [sortedConnectionSettings, setSortedConnectionSettings] = useState(() =>
    getInitialConnectionSetting(connectionSettings, category)
  );
  const [filterValue, setFilterValue] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  useEffect(() => {
    setSortedConnectionSettings(sortConnections(sortObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionSettings, itemsPerPage, currentPage, filterValue]);

  useEffect(() => {
    if (currentPage !== 0) setCurrentPage(0);
  },[settingsTitle]);

  return (
    <BladeModal title={settingsTitle} disableScrollLock isOpen={isOpen} onClose={() => {
      setFilterValue('');
      onClose();
    }} minWidth={'50%'}>
      <CustomMainModalContainer direction="column">
        <StyledGridItem md={4} sm={4} xs={12}>
          <TextInput
            variant="outlined"
            className="searchbox"
            value={filterValue}
            onChange={(e) => {
              e.preventDefault();
              setFilterValue(e.target.value);
              if (currentPage !== 0) setCurrentPage(0);
            }}
            placeholder="Filter..."
          />
        </StyledGridItem>
        <DataGrid
          onSort={sort}
          removeLeftPadding
          sortObject={sortObject}
          currentPage={currentPage}
          data={sortedConnectionSettings || []}
          headers={tableHeaderGetter(category)}
          onItemsPerPageChange={setItemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          totalCount={getInitialConnectionSetting(connectionSettings, category, filterValue)?.length}
          isLoading={isSettingLoading}
          customCellRender={renderCustomCell}
        />
      </CustomMainModalContainer>
    </BladeModal>
  )

  function sortConnections(sortObj: SortObject) {
    const currentListed = currentPage * itemsPerPage;
    const data = getInitialConnectionSetting(connectionSettings, category, filterValue)
    const orderedList = orderBy(
      data,
      sortObj.sortString,
      sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
    );
    return orderedList?.slice(currentListed, currentListed + itemsPerPage);
  }

  function sort(sortValue: HeaderIdType) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedConnectionSettings(sortConnections(sortObj));
      return sortObj;
    });
  }
};

const CustomMainModalContainer = styled(MainModalContainer)`
  width: 100%;
`;

const TextInput = styled(TextField)`
  width: 260px;
  margin-right: 8px;

  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
    overflow: hidden;
  }
`;