/** @jsxImportSource @emotion/react */

import { css, useTheme } from '@emotion/react';
import { Container, GridItem } from 'components/Layout';
import { Subheading } from 'components/Typography';
import styled from '@emotion/styled';
import Permits from 'components/Permits';
import { WithWrapper } from 'components/WithWrapper';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Text } from 'components/Typography';
import { Permit } from 'api/resources/models/User';
import { pagePaddingRight } from 'components/PageLayout';
import { AdminRepairerBasicDetails } from 'api/resources/models/AutoGenerated';
import { getActivity } from 'utils/helpers';

export function QuickDetails({
  repairerId,
  lastUpdated,
  repairerDetails,
}: {
  repairerId: number;
  lastUpdated?: string | null;
  repairerDetails?: AdminRepairerBasicDetails | null;
}) {
  const theme = useTheme();

  const { activity, isOlder } = getActivity(
    lastUpdated ? new Date(lastUpdated) : null
  );

  return (
    <CustomContainer direction="column">
      <CustomSubHeading>Quick Details</CustomSubHeading>
      <Container direction="row">
        <WithWrapper
          condition={true}
          wrap={(children) => (
            <Permits permit={Permit.CA_REPAIRERS_VIEW}>{children}</Permits>
          )}
        >
          <Item direction="column">
            <GridItem>
              <SecondaryHeader>Repairer ID</SecondaryHeader>
            </GridItem>
            <GridItem>
              <ValueText>{repairerId}</ValueText>
            </GridItem>
          </Item>
        </WithWrapper>
        <WithWrapper
          condition={true}
          wrap={(children) => (
            <Permits permit={Permit.CA_REPAIRERS_VIEW}>{children}</Permits>
          )}
        >
          <Item direction="column">
            <GridItem>
              <SecondaryHeader>Site Code</SecondaryHeader>
            </GridItem>
            <GridItem>
              <ValueText>{repairerDetails?.siteCode}</ValueText>
            </GridItem>
          </Item>
        </WithWrapper>
        <WithWrapper
          condition={true}
          wrap={(children) => (
            <Permits permit={Permit.CA_REPAIRERS_VIEW}>{children}</Permits>
          )}
        >
          <Item direction="column">
            <GridItem>
              <SecondaryHeader>Group</SecondaryHeader>
            </GridItem>
            <GridItem>
              <ValueText>{repairerDetails?.repaierGroup}</ValueText>
            </GridItem>
          </Item>
        </WithWrapper>
        <WithWrapper
          condition={true}
          wrap={(children) => (
            <Permits permit={Permit.CA_REPAIRERS_VIEW}>{children}</Permits>
          )}
        >
          <Item direction="column">
            <GridItem>
              <SecondaryHeader>System</SecondaryHeader>
            </GridItem>
            <GridItem>
              <ValueText>{repairerDetails?.systemType}</ValueText>
            </GridItem>
          </Item>
        </WithWrapper>
        <TimeStampContainer>
          <TimeStampMain>
            <AccessTimeIcon
              className="activity-icon"
              htmlColor={
                isOlder
                  ? theme.palette.messages.error
                  : theme.palette.messages.success
              }
            />
            <Text
              css={css`
                margin-left: ${theme.margin.xs};
              `}
              color={
                isOlder
                  ? theme.palette.messages.error
                  : theme.palette.messages.success
              }
            >
              {activity ? `Last updated ${activity}` : ''}
            </Text>
          </TimeStampMain>
        </TimeStampContainer>
      </Container>
    </CustomContainer>
  );
}

const CustomContainer = styled(Container)`
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-right: ${({ theme }) => pagePaddingRight(theme)};
  }
`;

const ValueText = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.l};
  word-break: break-word;
  white-space: pre-wrap;
`;

const SecondaryHeader = styled.header`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

const TimeStampContainer = styled.div`
  margin-left: auto;
`;

const TimeStampMain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Item = styled(Container)`
  max-width: 136px;
  margin-right: ${({ theme }) => theme.margin.s};

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-right: ${({ theme }) => theme.margin.xl};
    margin-bottom: 18px;
  }
`;

const CustomSubHeading = styled(Subheading)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;
