import { AdminMaintenanceMessageModel } from 'api/resources/models/AutoGenerated';
import { SortOrder } from 'api/resources/models/User';

export const selectOptions = [
  { id: 1, text: 'Scheduled', value: 'Scheduled' },
  { id: 3, text: 'Completed', value: 'Completed' },
];

export function useMaintenanceMessagesDetailsParams(url: string) {
  const urlParams = new URLSearchParams(url);

  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  return {
    sortString: sort as keyof AdminMaintenanceMessageModel,
    sortOrder: Object.values(SortOrder).includes(sortOrder) ? sortOrder : null,
  };
}
