import { api } from 'api';
import { config } from 'core/config';
import {
  AdminNotificationHeader,
  NotificationDetail,
  NotificationDetailQueue,
} from './models/AutoGenerated';

const adminBaseUrl = '/api/adminnotification';

export const getFnolQueues = (
  searchValue: string
): Promise<AdminNotificationHeader[]> =>
  api
    .get(
      `${config.connectionsAdminApiUrl}${adminBaseUrl}/notificationHeaders`,
      {
        params: { searchValue },
      }
    )
    .then((result) => result.data);

export const getFNOLDetails = (
  notificationid: number
): Promise<NotificationDetail> =>
  api
    .get(`${config.connectionsAdminApiUrl}${adminBaseUrl}/notificationDetail`, {
      params: { notificationid },
    })
    .then((result) => result.data);

export const getFNOLArchives = (
  notificationId: number,
  registration: string,
  repairCode: string,
): Promise<NotificationDetailQueue[]> =>
  api
    .get(`${config.connectionsAdminApiUrl}${adminBaseUrl}/queueEntriesFromArchive`, {
      params: { notificationId, registration, repairCode, loadDataFromArchive: true },
    })
    .then((result) => result.data);

export const getMessageArchives = (
  searchValue: string,
): Promise<NotificationDetailQueue[]> =>
  api
    .get(`${config.connectionsAdminApiUrl}${adminBaseUrl}/queueEntries`, {
      params: { searchValue, loadDataFromArchive: true },
    })
    .then((result) => result.data);