import styled from '@emotion/styled';
import MatButton, { ButtonProps } from '@mui/material/Button';
import { forwardRef } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Text } from 'components/Typography';
import { Add } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Container } from './Layout';
import { MobileContainer } from 'core/Theming/Device';

export interface Props {
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: React.MouseEventHandler<HTMLButtonElement> | VoidFunction;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  children?: React.ReactNode;
  size?: 'small' | 'large' | 'medium';
}

const MaterialButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ ...props }, ref) => (
    <MatButton ref={ref} disableFocusRipple {...props}></MatButton>
  )
);

MaterialButton.displayName = 'MaterialButton';

export function Button({
  variant = 'contained',
  onClick,
  children,
  color,
  size,
}: Props) {
  return (
    <MaterialButton
      size={size}
      color={color}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </MaterialButton>
  );
}

export const PrimaryButton = styled(MaterialButton)`
  color: ${({ theme }) => theme.palette.primary.light};
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.contact.button};
  border-radius: ${({ theme }) => theme.border.radius};
  cursor: pointer;
  text-transform: capitalize;
  :hover {
    background-color: ${({ theme }) => theme.palette.button.hover};
  }
  :active {
    background-color: ${({ theme }) => theme.palette.button.active};
    color: ${({ theme }) => theme.palette.button.activeText};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.palette.button.disabled};
    color: ${({ theme }) => theme.palette.primary.disabled};
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const SecondaryButton = styled(MaterialButton)`
  background: ${({ theme }) => theme.palette.button.light};
  border: ${({ theme }) => `2px solid ${theme.palette.primary.light}`};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.border.radius};
  text-transform: capitalize;

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.active};
  }

  :active {
    background-color: ${({ theme }) => theme.palette.button.active};
    color: ${({ theme }) => theme.palette.button.activeText};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.button.disabled};
    color: ${({ theme }) => theme.palette.primary.disabled};
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const UserMenuButton = styled(MaterialButton)`
  text-transform: capitalize;
  background-color: transparent;
`;

const StyledDiv = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  & svg {
    margin-right: ${({ theme }) => theme.margin.xs};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-top: ${({ theme }) => theme.margin.m};
  }
`;

const StyledCreateButton = styled(PrimaryButton)`
  min-width: 0;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const MobileBottomContainer = styled(Container)`
  position: fixed;
  padding: 12px 15px 12px 87px;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-left: 15px;
  }
`;

export function BackButton({ text, backUrl }: { text: string, backUrl?: string }) {
  const theme = useTheme();
  const location = useLocation();
  const navigation = useNavigate();

  const navigationHandler = () => {
    try {
      if (backUrl) navigation(`${backUrl}`);
      else navigation(-1);
    } catch (error) {
      const fallbackURL = location.pathname.replace(/\/[^/]*$/, '');
      navigation(fallbackURL);
    }
  };

  return (
    <StyledDiv onClick={navigationHandler}>
      <KeyboardBackspaceIcon htmlColor={theme.palette.background.dark} />
      <Text fontSize="xs">{text}</Text>
    </StyledDiv>
  );
}

// export function ErrorBackButton({ text, errorHandler }: { text: string, errorHandler: () => void }) {
//   const theme = useTheme();
//   return (
//     <StyledDiv onClick={errorHandler}>
//       <KeyboardBackspaceIcon htmlColor={theme.palette.background.dark} />
//       <Text fontSize="xs">{text}</Text>
//     </StyledDiv>
//   );
// }

export default function MobileBottomButton({
  children,
}: {
  children: JSX.Element;
}) {
  return (
    <MobileContainer>
      <MobileBottomContainer>{children}</MobileBottomContainer>
    </MobileContainer>
  );
}

export function CreateButton({ text, route }: { text: string; route: string }) {
  const navigation = useNavigate();
  return (
    <Container justify="flex-end">
      <StyledCreateButton type="button" onClick={() => navigation(route)}>
        <Add />
        {text}
      </StyledCreateButton>
    </Container>
  );
}
