import styled from '@emotion/styled';
import { useFormik } from 'formik';
import { Container, GridItem } from './Layout';
import { Modal } from './Modal';
import { ModalButton } from './ModalLayout';
import {
  InputContainer,
  InputFirst,
  RequiredLabel,
} from './PageLayout';
import { TextArea } from './TextArea';
import { TextField } from './TextField';
import { Text } from './Typography';
import * as Yup from 'yup';
import { formErrorMessages } from 'api/resources/responseMessages/formErrors';
import { useTheme } from '@emotion/react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  guidence: string | null;
  onSubmit?: ({
    ticketId,
    comment,
  }: {
      ticketId: string | null;
      comment: string | null;
  }) => void;
}

const validation = Yup.object({
  ticketnumber: Yup.string().required(formErrorMessages.required),
  comments: Yup.string().required(formErrorMessages.required),
});

export const CreateRepairerActionPopup = ({
  title,
  isOpen,
  onClose,
  guidence,
  onSubmit,
}: Props) => {
  const theme = useTheme();
  const {
    errors,
    touched,
    resetForm,
    handleChange,
    handleSubmit,
    values: { ticketnumber, comments },
  } = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      ticketnumber: '',
      comments: '',
    },

    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit?.({
        ...values,
        comment: values.comments,
        ticketId: values.ticketnumber,
      });
      onClose();
    },
  });

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <MainModalContainer direction="column">
          <GridItem>
            {guidence && (
              <Text fontSize="m" fontWeight="normal" className="text">
                {guidence}
              </Text>
            )}
          </GridItem>
          <InputContainer direction="row" md={12} sm={12} xs={12}>
            <InputFirst md={6} sm={6} xs={12}>
              <RequiredLabel>Ticket Number</RequiredLabel>
              <TextInput
                name="ticketnumber"
                value={ticketnumber}
                onChange={handleChange}
                error={!!errors?.ticketnumber && touched?.ticketnumber}
                helperText={touched?.ticketnumber && errors?.ticketnumber}
              />
            </InputFirst>
          </InputContainer>
          <GridItem className="input-container">
            <RequiredLabel>Comments</RequiredLabel>
            <CustomTextArea
              name="comments"
              value={comments}
              onChange={handleChange}
            />
            {errors?.comments && touched?.comments && (
              <Text color={theme.palette.primary.error}>{errors.comments}</Text>
            )}
          </GridItem>
          <Container direction="row" justify="space-around">
            <GridItem>
              <CustomModalButton onClick={onClose}>
                <CloseIcon className="icon" /> Cancel
              </CustomModalButton>
            </GridItem>
            <GridItem>
              <CustomModalButton type="submit">
                <CheckIcon className="icon" /> Save Changes
              </CustomModalButton>
            </GridItem>
          </Container>
        </MainModalContainer>
      </form>
    </Modal>
  );
};

export const MainModalContainer = styled(Container)`
  width: 100%;
  height: 100%;
  & .secondary-text {
    margin-bottom: ${({ theme }) => theme.margin.xs};
  }
  & .text {
    margin-bottom: ${({ theme }) => theme.margin.m};
  }
  & .input-title {
    margin-bottom: ${({ theme }) => theme.margin.s};
  }
  & .input-container {
    margin-bottom: ${({ theme }) => theme.margin.l};
  }
  & .icon {
    margin-right: 10px;
  }
`;

const TextInput = styled(TextField)`
  width: 260px;

  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
    overflow: hidden;
  }
`;

const CustomTextArea = styled(TextArea)`
  width: 100%;
  min-height: 120px;
`;

const CustomModalButton = styled(ModalButton)`
  min-width: 200px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
    margin-top: 10px;
  }
`;
