import styled from '@emotion/styled';
import { Help } from '@mui/icons-material';
import { Permit } from 'api/resources/models/User';
import { SecondaryButton } from 'components/Button';
import { HelpInformationModal } from 'components/HelpInformationModal';
import { DescriptionText, Home } from 'components/Home';
import { Container, GridItem } from 'components/Layout';
import { Loader } from 'components/Loader';
import { Modal } from 'components/Modal';
import { MainModalContainer, ModalButton } from 'components/ModalLayout';
import { Text } from 'components/Typography';
import { signout } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './hooks';

const accessPermit = Permit.CONNECTIONS_ADMIN;

export const Dashboard = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const { user, isLoading, isFetching } = useUser();
  const isBusy = isLoading || isFetching;

  const permitAllowed = useMemo(() => {
    const permit_allowed = user?.userPermits.some(
      (userPermit) => userPermit.code == accessPermit
    );
    if (!permit_allowed) setIsOpen(true);
    return permit_allowed;
  }, [user]);

  if (isBusy)
    return (
      <CustomContainer alignItems="center" justify="center" direction="column">
        <Loader />
      </CustomContainer>
    );

  return (
    <>
      {permitAllowed && <Main />}
      <Modal isOpen={isOpen} title="Access Denied">
        <MainModalContainer direction="column">
          <GridItem className="text">
            <Text>You do not have access to this application.</Text>
          </GridItem>
          <GridItem>
            <ModalButton onClick={logoutHandler}>OK</ModalButton>
          </GridItem>
        </MainModalContainer>
      </Modal>
    </>
  );

  function logoutHandler() {
    navigate(ROUTES.logout);
    signout();
  }
};

const Main = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Home headerText="Welcome to Activeweb Connections Admin">
      <DescriptionText fontSize="l">
        This interface deals with visibility of data flow and the
        creation/disconnection of connections.
      </DescriptionText>
      <DescriptionText fontSize="l">
        Please be aware this is a permit driven area and contains GRPR and
        customer related information.
      </DescriptionText>
      <Buttons>
        <Button>
          <Text>User Guide</Text>
        </Button>
        <Button onClick={() => setIsOpen(true)}>
          <HelpIcon />
          <Text>Get Help</Text>
        </Button>
      </Buttons>

      {isOpen && (
        <HelpInformationModal
          isOpen={isOpen}
          onCloseClick={() => {
            setIsOpen(false);
          }}
        />
      )}
    </Home>
  );
};

const CustomContainer = styled(Container)`
  height: 100%;
`;

const Buttons = styled(Container)`
  margin-top: 32px;
`;

const Button = styled(SecondaryButton)`
  margin-right: 12px;
`;
const HelpIcon = styled(Help)`
  margin-right: 10px;
`;
