export const BMS = [
  'ADVANCE7',
  'ALPHACRM',
  'AUTOFLOW',
  'AUTOGLASS',
  'AUTOSOFT',
  'AUTOVIEW',
  'AVANT',
  'BMS',
  'BODYNET',
  'BSD',
  'EMACS',
  'FIXCLOUD',
  'iIMAGE',
  'JARVIS',
  'MANUAL',
  'PINNACLE',
  'PLANMANAGER',
  'UNITY',
  'UNKNOWN',
  'VOYAGER',
];
